import React from "react"
import { View, Text, Image } from "react-native"

export default function EmBreve({ title = "Aguarde", text = "Em breve esta página estará disponível" }) {
    return (
        <View style={{ flex: 1, height: "100%", justifyContent: "center", alignItems: "center", backgroundColor: "#02AB89" }}>
            <Image style={{
                height: 45 * 1.2,
                width: 93 * 1.2,
                marginBottom: 40
            }} source={require('../../../assets/logo.png')} />

            <Text style={{ textAlign: "center", fontSize: 24, textTransform: "uppercase", color: "#ffffff", fontWeight: "bold", marginBottom: 10 }}>{title}</Text>
            <Text style={{ textAlign: "center", fontSize: 16, color: "#e0e0e0" }}>{text}</Text>
        </View>
    )
}