import React, { useState, useEffect, useContext } from "react"
import { Text } from "react-native"

import { Form } from '@unform/mobile'
import Input from '../../../Input'

import { getAutocompleteCPF } from "../../../../services/pessoa.service"
import { CompraContext } from "../../../../contexts/compra.context"
import { verificarTitular } from "../../../../services/ingressos.service"
import moment from "moment/moment"

export default function FormTitular({ formRef, titular: tit, onSubmit, ingressosIndividuaiselecionada, disabled, doacao, evento_etapa_id }) {
    const { titulares } = useContext(CompraContext)

    const [cpfCarregado, setCpfCarregado] = useState(false)

    const [titular, setTitular] = useState({})

    const [erro, setErro] = useState(null)

    useEffect(() => {
        //console.log(ingressosIndividuaiselecionada, tit)
        console.log("ingressosIndividuaiselecionada", ingressosIndividuaiselecionada)
        console.log("tit", tit)
        if (tit)
            setTitular(tit)
    }, [ingressosIndividuaiselecionada])

    useEffect(() => {
        setErro(() => null)
    }, [titular])

    useEffect(() => {
        console.log("evento_etapa_id", evento_etapa_id)
    }, [evento_etapa_id])

    const cpfChanged = async (cpf) => {
        if (typeof cpf != "string")
            return

        console.log("cpfChanged", cpf, evento_etapa_id)
        try {
            if (cpf.length == 11) {
                await verificarTitular({
                    evento_etapa_id,
                    cpf
                }, "cpf")

                buscarPessoa(cpf)
            } else {
                formRef.current.setErrors({ cpf: null })
            }
        } catch (error) {
            if (typeof error == "string")
                formRef.current.setErrors({ cpf: error })
        }
    }

    const buscarPessoa = async (cpf) => {
        try {
            setErro(() => null)

            let busca = titulares.filter((t, i) => i != ingressosIndividuaiselecionada && t.cpf == cpf)
            if (busca.length > 0)
                throw { status: 401, message: "CPF já preenchido em outro ingreso" }

            let resultado = await getAutocompleteCPF(cpf)

            console.log(resultado)

            setTitular({
                ...titular,
                nome: resultado?.nome || "",
                celular: resultado?.telefone || "",
                data_nascimento: resultado?.data_nascimento.substring(0, 10).split("-").reverse().join("/"),
                email: resultado?.email || ""
            })
            console.log({ data_nascimento: resultado?.data_nascimento.substring(0, 10).split("-").reverse().join("/") })

            setCpfCarregado(() => true)
        } catch (error) {
            if (error?.status == 401)
                setErro(() => error?.message || "Ocorreu um erro")

            setCpfCarregado(() => true)
        }
    }

    return (
        <>
            <Form ref={formRef} onSubmit={onSubmit}>
                <Input mask="cpf" name="cpf" placeholder="CPF" disabled={disabled} defaultValue={titular?.cpf} onChange={val => cpfChanged(val)} />
                <Input name="nome" placeholder="Nome Completo" disabled={disabled || !cpfCarregado} defaultValue={titular?.nome} />
                <Input mask="cel-phone" name="celular" placeholder="WhatsApp (receberá o ingresso nele)" disabled={disabled || !cpfCarregado} defaultValue={titular?.celular} />
                <Input name="data_nascimento" mask="datetime" options={{ format: 'DD/MM/YYYY' }} placeholder="Data de nascimento" disabled={disabled || !cpfCarregado} defaultValue={titular?.data_nascimento ? moment(titular.data_nascimento, titular.data_nascimento.indexOf("-") >= 0 ? "YYYY-MM-DD" : "DD/MM/YYYY").format("DD/MM/YYYY") : undefined} />
                <Input name="email" placeholder="E-mail" disabled={disabled || !cpfCarregado} defaultValue={titular?.email} autoCapitalize={"none"} />
                {doacao && <Input type="money" name="valor_doacao" placeholder="Doação" disabled={disabled || !cpfCarregado} defaultValue={titular?.valor_doacao} />}
            </Form>


            {erro && (
                <Text style={{ fontSize: 16, color: "red", textAlign: "center", marginVertical: 20 }}>{erro}</Text>
            )}
        </>
    )
}