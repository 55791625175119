import React, { useState, createContext, useContext, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  login as loginService,
  load,
  loadUsuario,
} from '../services/auth.service';
import { postPushAcesso } from '../services/notifications.service';

import { NotificationsContext } from '../contexts/notifications.context';

export const AuthContext = createContext({});

export const AuthProvider = (props) => {
  const { expoPushToken, notificationMaisRecente } =
    useContext(NotificationsContext);

  const [usuario, setUsuario] = useState(null);

  const [pessoa, setPessoa] = useState(null);

  const [loadingPessoa, setLoadingPessoa] = useState(true);

  const [tokenAuth, setTokenAuthUse] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [acessoEnviado, setAcessoEnviado] = useState(false);

  useEffect(() => {
    console.log('expoPushToken', expoPushToken);
    if (!acessoEnviado && expoPushToken) {
      postPushAcesso(expoPushToken);
      setAcessoEnviado(true);
    }
  }, [expoPushToken]);

  useEffect(() => {
    console.log(notificationMaisRecente);
    if (notificationMaisRecente) {
      setPessoa((pessoa) => {
        if (!pessoa) return pessoa;
        pessoa.notificacoes_nao_lidas = pessoa.notificacoes_nao_lidas + 1;
        return { ...pessoa };
      });
    }
  }, [notificationMaisRecente]);

  const start = async () => {
    let token = await AsyncStorage.getItem('x-access-token');
    if (token) {
      setTokenAuth(token, false);
    } else {
      setLoadingPessoa(() => false);
    }
  };

  const setTokenAuth = async (token, save = true) => {
    try {
      //console.log("setTokenAuth", token)
      if (token) {
        setTokenAuthUse(token);
        if (save) await AsyncStorage.setItem('x-access-token', token);
        //await api.setHeader("x-access-token", token)

        await buscarUsuario();
        await buscarPessoa();

        if (expoPushToken) {
          postPushAcesso(expoPushToken);
          setAcessoEnviado(true);
        }
      } else {
        setTokenAuthUse(null);
        await AsyncStorage.removeItem('x-access-token');
      }
    } catch (error) {
      throw error;
    }
  };

  const login = async (login, senha) => {
    try {
      let resultado = await loginService(login, senha);

      let { token } = resultado;

      setTokenAuth(token);
    } catch (error) {
      throw error;
    }
  };

  const logout = async (navigation) => {
    try {
      await AsyncStorage.removeItem('x-access-token');
      navigation.navigate('Login');
      setPessoa(null);
      setUsuario(null);
      return true;
    } catch (error) {
      throw error;
    }
  };

  const buscarUsuario = async () => {
    try {
      //console.log("buscarUsuario")
      let resultado = await loadUsuario();
      //console.log("PESSOA", resultado)
      setUsuario(resultado);

      return resultado;
    } catch (error) {
      await AsyncStorage.removeItem('x-access-token');
      throw error;
    }
  };

  const buscarPessoa = async () => {
    try {
      setLoadingPessoa(() => true);

      let resultado = await load();
      //console.log("PESSOA", resultado)
      setPessoa(resultado);

      setLoadingPessoa(() => false);

      return resultado;
    } catch (error) {
      setLoadingPessoa(() => false);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        start,
        usuario,
        setUsuario,
        pessoa,
        setPessoa,
        loadingPessoa,
        isAuthenticated,
        setIsAuthenticated,
        tokenAuth,
        setTokenAuthUse,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
