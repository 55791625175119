import { StyleSheet } from "react-native";

export default StyleSheet.create({
    container: {
        width: "100%",
        backgroundColor: '#FFF',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: {
            width: 4,
            height: 4
        },
        shadowOpacity: 0.12,
        shadowRadius: 14.00,
    }
})