import React from 'react';
import { View, Text } from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import IconF from 'react-native-vector-icons/Feather';
import styles from './styles';

export default function StatusPayment({ status }) {
    if (status === 'success') {
        return (
            <View style={styles.view}>
                <Icon style={styles.icons} name="checkcircle" size={280} color='#000' />
            </View>
        )
    }
    else {
        if (status === 'error') {
            return (
                <View style={styles.viewError}>
                    <IconF style={styles.icons} name="x-circle" size={280} color='#000' />
                    <Text style={styles.txtError}>Pagamento não aprovado,</Text>
                    <Text style={styles.txtError}>tente novamente.</Text>
                </View>
            )
        }
        else if (status === 'warn') {
            return (
                <View style={styles.viewWarn}>
                    <Icon style={styles.icons} name="checkcircle" size={280} color='#000' />
                    <Text style={styles.txtWarn}>Aguardando o pagamento do cliente. Te avisaremos assim que ocorrer.</Text>
                </View>
            )
        }
    }
}