import React, { memo } from 'react';
import { SafeAreaView, ScrollView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';

const Page = ({ children, style, styleScroll, gradient }) => {
  return (
    <SafeAreaView
      style={{
        minHeight: '100%',
        flexDirection: 'column',
        flex: 1,
        marginTop: -30,
        ...style,
      }}
    >
      <StatusBar style="light" translucent={false} backgroundColor="#02ab89" />
      {gradient ? (
        <LinearGradient
          colors={[style?.backgroundColor || '#fff']}
          {...gradient}
          style={{ minHeight: '100%', width: '100%' }}
        >
          <ScrollView style={{ flex: 1, padding: 30, ...styleScroll }}>
            {children}
          </ScrollView>
        </LinearGradient>
      ) : (
        <ScrollView style={{ flex: 1, padding: 30, ...styleScroll }}>
          {children}
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default memo(Page);
