import React, { useContext, useState } from "react"

import { View, Text, TouchableOpacity, Share } from "react-native"
import { AppContext } from "../../contexts/app.context"

import { CompraContext } from '../../contexts/compra.context'
import { WebSocketContext } from "../../contexts/websocket.context"
import { formatReal } from "../../helpers/format"

import Button from "../Button"
import InputClipboard from "../InputClipboard"
import Loading from "../Loading"
import QRCode from "../QRCode"

import Icon from 'react-native-vector-icons/FontAwesome';
import { getCompra } from "../../services/compras.service"

export default function PIX({ evento, eventoAberto, concluir }) {
    const { onSocket } = useContext(WebSocketContext)
    const { reservarIngressos, efetuarCompra, resetCompra, valorTotal } = useContext(CompraContext)
    const { setModalBottom, setModalBottomSmall, setStatus } = useContext(AppContext)

    const [loading, setLoading] = useState(false)
    const [qrcode, setQrcode] = useState(null)
    const [erro, setErro] = useState(null)

    const [gerado, setGerado] = useState(false)

    const [zoom, setZoom] = useState(false)

    const gerarQRCode = async () => {
        try {
            setLoading(() => true)

            const { token } = await reservarIngressos(evento._id)
            let compra = await efetuarCompra({
                meio_pagamento: "pix",
                parcelas: 1
            }, token)

            console.log("COMPRA", compra)

            setQrcode(compra?.fatura?.pix?.brcode)
            setGerado(true)

            setTimeout(() => consultarCompra(compra._id), 5000)

            /*setGerado(() => null)

            onSocket(`pix-gerado:${compra.ordem_pagamento._id}`, data => {
                //console.log(data)
                setQrcode(data.brcode)
                setGerado(true)
            })

            onSocket(`compra-atualizada:${compra._id}`, data => { // Pagamento feito e compra atualizada
                //console.log("COMPRA ATUALIZADA", data)

                if (data.status == 1) {
                    setStatus("success")
                    setModalBottomSmall(null)
                    setModalBottom(null)
                    resetCompra()
                } else if (data.status == 3) {
                    setStatus({ type: "error", text: "Pagamento não aprovado, tente novamente." })
                } else if (data.status == 4) {
                    setStatus({ type: "error", text: "Compra cancelada." })
                }
            })*/

            setLoading(() => false)
        } catch (error) {
            console.error(error)
            setErro(erro?.message || "Ocorreu um erro")
            setLoading(() => false)
        }
    }

    const consultarCompra = async (compra_id) => {
        try {
            let compra = await getCompra(compra_id)

            if (compra.status == 1) {
                setStatus("success")
                setModalBottomSmall(null)
                setModalBottom(null)
                resetCompra()
            } else if (compra.status == 3) {
                setStatus({ type: "error", text: "Pagamento não aprovado, tente novamente." })
            } else if (compra.status == 4) {
                setStatus({ type: "error", text: "Compra cancelada." })
            } else
                setTimeout(() => consultarCompra(compra_id), 3000)
        } catch (error) {
            throw error
        }
    }

    const cancelar = async () => {
        setModalBottomSmall(null)
    }

    const compartilhar = async () => {
        try {
            const result = await Share.share({
                message: qrcode,
            });

            //console.log(result)
            if (result.action === Share.sharedAction) {
                try {
                    concluir()
                } catch (error) { }
            }
        } catch (error) {
            alert(error.message);
        }
    }

    return (
        <View>
            <Text style={{ fontSize: 24, color: "#02AB89", fontWeight: "500" }}>Pagamento PIX</Text>

            {loading ? (
                <View style={{ marginTop: 30 }}>
                    <Loading text="Gerando QRCode" />
                </View>
            ) : <>
                {gerado == false && (
                    <View>
                        <Text style={{ marginTop: 10, marginBottom: 20, color: "#02AB89", fontSize: 14 }}>Ao gerar o QR Code, uma reserva será feita e o pagamento deve ser feito em no máximo 15 minutos.</Text>

                        <Button title={`Gerar PIX - ${formatReal(valorTotal.pix.total)}`} backgroundColor="#53D769" styleText={{ fontSize: 16 }} onPress={gerarQRCode} />
                    </View>
                )}

                {gerado != false && (
                    <View>
                        <View style={{ flexDirection: zoom ? "column" : "row", marginVertical: 20 }}>
                            <View style={{ justifyContent: "center" }}>
                                <View style={{ padding: 10, backgroundColor: "#ffffff", borderRadius: 5, justifyContent: "center", alignItems: "center", alignSelf: "center" }}>
                                    <TouchableOpacity onPress={() => setZoom(!zoom)}>
                                        <QRCode value={qrcode} size={zoom ? 170 : 110} loading={gerado == null} />
                                    </TouchableOpacity>
                                </View>

                                {eventoAberto?.tipo == "comissario" && (
                                    <View style={{ marginTop: 10 }}>
                                        <InputClipboard text={qrcode} />
                                    </View>
                                )}
                            </View>

                            <View style={{ flex: 1, paddingLeft: 10, marginTop: zoom ? 20 : 0 }}>
                                <Text style={{ fontSize: 15, marginBottom: 10, fontWeight: "700" }}>Passo a passo PIX</Text>
                                <Text style={{ fontSize: 11 }}><Text style={{ fontWeight: "700" }}>1.</Text> Abra o app do seu banco ou instituição financeira e entre em <Text style={{ fontWeight: "700" }}>PIX</Text></Text>
                                <Text style={{ fontSize: 11 }}><Text style={{ fontWeight: "700" }}>2.</Text> Escolha a opção <Text style={{ fontWeight: "700" }}>Pagar com QR Code</Text></Text>
                                <Text style={{ fontSize: 11 }}><Text style={{ fontWeight: "700" }}>3.</Text> <Text style={{ fontWeight: "700" }}>Leia o QR Code</Text> ao lado ou Copie e Cole o código apresentado</Text>
                                <Text style={{ fontSize: 11 }}><Text style={{ fontWeight: "700" }}>4.</Text> Aguarde a confirmação nesta página por alguns segundos (até 2 minutos)</Text>
                            </View>
                        </View>
                        <Text style={{ textAlign: "center", fontSize: 12 }}>Após o pagamento, aguarde nessa página alguns segundos ou até 2 minutos para a efetivação.</Text>

                        <Button title={(
                            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <Text style={{ fontSize: 13, color: "#ffffff", marginRight: 10 }}>Compartilhar</Text>
                                <Icon name="share" color="#ffffff" size={12} />
                            </View>
                        )} style={{ marginTop: 20 }} styleText={{ fontSize: 12 }} backgroundColor="#53D769" onPress={compartilhar} />

                        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <Button title="Voltar" style={{ marginTop: 20, width: "49%" }} styleText={{ fontSize: 12 }} backgroundColor="tomato" onPress={cancelar} />

                            <Button title="Concluir" style={{ marginTop: 20, width: "49%" }} styleText={{ fontSize: 12 }} backgroundColor="#02ab89" onPress={concluir} />
                        </View>
                    </View>
                )}

                {erro && <Text style={{ fontSize: 14, textAlign: "center", marginTop: 20, color: "tomato" }}>{erro}</Text>}
            </>}
        </View>
    )
}