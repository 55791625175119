import { StyleSheet } from "react-native";

export default StyleSheet.create({
    main: {
        paddingHorizontal: 30,
    },
    sombra: {
        shadowColor: '#000',
        shadowOffset: {
            width: 4,
            height: 4
        },
        shadowOpacity: 0.2,
        shadowRadius: 1.00,
    },
    eventImage: {
        width: "100%",
        height: 200,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10
    },
    eventTitle: {
        textAlign: "center",
        fontSize: 22,
        color: "#152536",
        marginTop: 20,
        marginBottom: 10,
        fontWeight: "bold",
    },
    title: {
        fontSize: 18,
        fontWeight: "500",
        marginBottom: 10
    },
    text: {
        fontSize: 13,
        fontWeight: "200"
    },
    partText: {
        marginBottom: 20
    },
    sombra: {
        shadowColor: '#000',
        shadowOffset: {
            width: 4,
            height: 4
        },
        shadowOpacity: 0.2,
        shadowRadius: 1.00,
    },
    pricesBox: {
        borderRadius: 10,
        backgroundColor: "#ffffff",
        marginBottom: 20
    }
})