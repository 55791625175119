import { StyleSheet } from "react-native";

export default StyleSheet.create({
    imgCard:{
        marginTop: 10,
        alignSelf: "center",
    },
    viewRow:{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    buttonForms:{
        width: 161,
        margin: 15,
        marginTop: 25,
    },
    input:{
        fontSize: 14,
        lineHeight: 20,
        fontWeight: '200',
        borderRadius: 4,
        marginTop: 15,
        marginRight: 10,
        marginLeft: 10,
        height: 40,
        width: 300,
    },
    inputRow:{
        fontSize: 14,
        lineHeight: 20,
        fontWeight: '200',
        borderRadius: 4,
        marginTop: 15,
        marginRight: 10,
        marginLeft: 10,
        height: 40,
        width: 135,
    },
    txtTotalValue:{
        fontSize: 18,
        lineHeight: 21,
        fontWeight: "700",
        color: '#00535A', 
        margin: 68,
        marginTop: 40,
    },
    buttonConfirm:{
        width: '100%',
        height: 70,
        padding: 25,
        textAlign: "center",
        margin: 15,
        marginTop: -35,
    },
    safe: {
        flex: 1,
    }
})