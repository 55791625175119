import api from './api.service';

export async function getConteudo(conteudo) {
    try {
        let resultado = await api.get(`/config/conteudos/${conteudo}`)

        //console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}