import { StyleSheet } from "react-native";

export default StyleSheet.create({
    commisionView: {
        width: '100%',
        backgroundColor: '#017781',
        height: 60,
        alignItems: "center",
    },
    commisionTrueView: {
        width: '100%',
        backgroundColor: '#53D769',
        height: 60,
        marginTop: 20,
        alignItems: "center",
    },
    titleCommission: {
        fontSize: 18,
        fontWeight: '700',
        color: '#FFF',
        marginTop: 8,
    },
    txtCommission: {
        fontSize: 14,
        fontWeight: '300',
        color: '#FFF',
    }
})