import React, {useState} from 'react';
import {View, Text, SafeAreaView, ScrollView, TouchableOpacity} from 'react-native';
import MainlyModal from '../../components/MainlyModal';
import ModalCardPayment from '../../components/ModalCardPayment';
import ModalLinkPayment from '../../components/ModalLinkPayment';
import TicketCard from '../../components/TicketCard';
import ButtonsPayment from '../../components/ButtonsPayment';
import styles from './styles';
import { NavigationContainer } from '@react-navigation/native';

export default function Payment({navigation}){
    const [paymentCard, setPaymentCard] = useState(false);
    const [paymentLink, setPaymentLink] = useState(false);

    const paymentCardModal = () =>{
        if(paymentCard === false){
            setPaymentCard(true)
            setTimeout(() => {
              setPaymentCard(false);
            }, 3000)
        }
    }   
    const paymentLinkModal = () =>{
        if(paymentLink === false){
            setPaymentLink(true)
            setTimeout(() => {
              setPaymentLink(false);
            }, 3000)
        }
    }   
    
    return(
        <SafeAreaView>
            <ScrollView>
                <MainlyModal>
                    <Text style={styles.titlePayment}>Pagamento</Text>
                    <Text style={styles.titleInformPayment}>Confira atentamente os dados de todos os ingressos.</Text>
                    <View style={styles.ticketCard}>
                        <TicketCard imgLocCard={require('../../../assets/Card_Ticket.png')}
                                    imgLocEvent={require('../../../assets/Filtro_Event_STL.png')}
                        />
                        <TicketCard imgLocCard={require('../../../assets/Card_Ticket.png')}
                                    imgLocEvent={require('../../../assets/Filtro_Event_STL.png')}
                        />
                        <TicketCard imgLocCard={require('../../../assets/Card_Ticket.png')}
                                    imgLocEvent={require('../../../assets/Filtro_Event_STL.png')}
                        />
                    </View>
                    <View style={styles.viewRow}>
                        <ButtonsPayment  onPress={() => {paymentLinkModal()}}paymentType="PIX" tax="R$10,00 taxa" imgLoc={require('../../../assets/pix.png')}/>
                        <ButtonsPayment  onPress={() => {paymentCardModal()}} paymentType="Crédito" tax="R$10,00 + Taxa" imgLoc={require('../../../assets/mastercard.png')}/>
                        {paymentCard === false?null:
                            <ModalCardPayment/>
                        }
                        {paymentLink === false?null:
                            <ModalLinkPayment/>
                        }
                    </View>
                </MainlyModal>
            </ScrollView>
        </SafeAreaView>
    )
}