import React, { useEffect, useContext } from "react";
import { View, Text, TouchableOpacity, BackHandler } from "react-native";
import styles from "./styles";
import Icon from "react-native-vector-icons/FontAwesome";

import { CompraContext } from "../../../contexts/compra.context";

import { formatReal } from "./../../../helpers/format";
import { AppContext } from "../../../contexts/app.context";

export default function TSEtapas({ etapas }) {
  const { etapa, setEtapa } = useContext(CompraContext);
  const { setModalBottom } = useContext(AppContext);

  useEffect(() => {
    if (etapas.length == 1) setEtapa(etapas[0]);
  }, [etapas]);

  useEffect(() => {
    const backAction = () => {
      if (etapa) setEtapa(null);
      else setModalBottom(null);
      return true;
    };

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, [etapa]);

  const getFaixaPrecoEtapa = (etapa) => {
    console.log("getFaixaPrecoEtapa", etapa?.setores[0]);
    if (!etapa) return null;
    //if (setor.lotes.length == 0) return null

    let menor = etapa?.setores[0]?.lotes[0]?.valor;
    let maior = etapa?.setores[0]?.lotes[0]?.valor;

    //console.log("getFaixaPrecoSetor", setor)

    for (let setor of etapa.setores) {
      setor.lotes.forEach((lote) => {
        if (lote.valor < menor) menor = lote.valor;
        if (lote.valor > maior) maior = lote.valor;
      });
    }

    if (menor == maior && menor == 0) return `GRÁTIS`;

    menor = formatReal(menor);
    maior = formatReal(maior);

    return menor == maior ? menor : `${menor} - ${maior}`;
  };

  return (
    <>
      {!etapa && (
        <>
          {etapas?.map((etapa) => (
            <TouchableOpacity
              key={etapa._id}
              onPress={() => {
                if (getFaixaPrecoEtapa(etapa)) setEtapa(etapa);
              }}
              style={{ marginTop: 20 }}
            >
              <View
                style={{
                  marginTop: 15,
                  borderWidth: 1,
                  borderColor: "#ffffff",
                  padding: 10,
                  borderRadius: 5,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <View style={{}}>
                  <Text
                    style={{ ...styles.ticketType, textTransform: "uppercase" }}
                  >
                    {etapa.nome}
                  </Text>
                  <Text style={styles.ticketPrice}>
                    {getFaixaPrecoEtapa(etapa) || "Nenhuma opção de compra"}
                  </Text>
                </View>
                {getFaixaPrecoEtapa(etapa) && (
                  <Icon name="chevron-right" size={36} />
                )}
              </View>
            </TouchableOpacity>
          ))}
        </>
      )}
    </>
  );
}
