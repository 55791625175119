import React, { useState, useEffect } from "react"

import { View, Text, TouchableOpacity } from "react-native"
import CartaoCreditoLink from "./CartaoCreditoLink"
import CartaoCreditoWebView from "./CartaoCreditoWebView"

import styles from "./styles"

export default function CartaoCredito({ evento, modo = null }) {
    const [mode, setMode] = useState(null)

    useEffect(() => {
        if (modo)
            setMode(modo)
    }, [modo])

    const voltar = () => {
        setMode(null)
    }

    return (
        <View>
            {mode == null && <>
                <Text style={{ fontSize: 24, color: "#02AB89", fontWeight: "500" }}>Pagamento por Cartão</Text>
                {/* <Text style={{ marginTop: 10, color: "#02AB89", fontSize: 14 }}>Você pode pagar agora pelo app ou enviar um link de pagamento.</Text> */}
                <Text style={{ marginTop: 10, color: "#02AB89", fontSize: 14 }}>Você pode enviar um link de pagamento.</Text>

                <View style={styles.buttons}>
                    {/* <TouchableOpacity style={styles.button} onPress={() => setMode("pagamento")}>
                        <Text style={styles.buttonText}>Pagar pelo App</Text>
                    </TouchableOpacity>
                    <View style={{ width: 5 }}></View> */}
                    <TouchableOpacity style={styles.button} onPress={() => setMode("link")}>
                        <Text style={styles.buttonText}>Enviar link</Text>
                    </TouchableOpacity>
                </View>
            </>}

            {mode == "link" && <CartaoCreditoLink evento={evento} voltar={voltar} />}
            {/* {mode == "pagamento" && <CartaoCreditoWebView evento={evento} voltar={voltar} />} */}
        </View>
    )
}