import React, {useState} from 'react';
import {View, Image, Text, SafeAreaView, ScrollView } from 'react-native';
import Input from '../../components/Input';
import Button from '../../components/Button';
import MainlyModal from '../../components/MainlyModal';
import StatusPayment from '../../components/StatusPayment';
import styles from './styles';

export default function CardPayment(){
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentWarn, setPaymentWarn] = useState(false);
    const [paymentError, setPaymentError] = useState(false);

    const paymentConfirm = () =>{
        setPaymentStatus(true)
        //console.log(paymentStatus)
    }
    return(
        <SafeAreaView style={styles.safe}>
            {paymentStatus===false?
                <ScrollView>
                    <MainlyModal>
                        <Image style={styles.imgCard} source={require('../../../assets/CreditCard.png')}/>
                        <Input style={styles.input} icon="lock" placeholder="Número do Cartão"/>
                        <View style={styles.viewRow}>
                            <Input style={styles.inputRow} placeholder="Data de Validade"/>
                            <Input style={styles.inputRow} placeholder="Código de Segurança"/>
                        </View>
                        <Input style={styles.input} placeholder="Nome Completo"/>
                        <Input style={styles.input} placeholder="CPF"/>
                        <Input style={styles.input} placeholder="Nome Impresso no Cartão"/>
                        <View style={styles.viewRow}>
                            <Input style={styles.inputRow} placeholder="Data de Nascimento"/>
                            <Input style={styles.inputRow} placeholder="Celular(WhatsApp)"/>
                        </View>
                        <View style={styles.viewRow}>
                            <Text style={styles.txtTotalValue}>TOTAL: R$  150,00</Text>
                            <Text style={styles.txtTotalValue}>PICKER</Text>
                        </View>
                        <Button onPress={() => {paymentConfirm()}} title="CONFIRMAR PAGAMENTO" backgroundColor='#53D769' style={styles.buttonConfirm}/>
                    </MainlyModal>
                </ScrollView>
                :
                <StatusPayment status="warn"/>
                
                }
        </SafeAreaView>
    )
}