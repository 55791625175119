import { StyleSheet } from "react-native";

export default StyleSheet.create({
    safe: {
        flex: 1,
    },
    titleSales: {
        fontSize: 36,
        lineHeight: 43,
        fontWeight: "700",
        color: '#02AB89',
        marginTop: 17,
        marginLeft: 20,
    },
    titleInformSales: {
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "500",
        color: '#02AB89',
        marginLeft: 20,
        marginBottom: 10,
    },
    viewRow: {
        marginTop: 50,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    view: {
        width: '90%',
        alignSelf: "center",
        flex: 1
    },
    viewTickets: {
        width: '100%',
        backgroundColor: '#fff',
        margin: 1,
        borderRadius: 10,
    },
    viewDiv: {
        marginTop: 20,
        alignSelf: "center",
        width: '90%',
        borderTopWidth: 1,
        borderTopColor: "#02AB89B2",
    },
    titleMain: {
        fontSize: 18,
        color: '#02AB89',
        fontWeight: '300',
        alignSelf: "flex-end",
        margin: 12,
    },
    txtMain: {
        fontSize: 24,
        color: '#53D769',
        fontWeight: '600',
        alignSelf: "flex-end",
        marginRight: 12,
    },
    title: {
        fontSize: 12,
        color: '#02AB89',
        fontWeight: '300',
        margin: 4,
    },
    txt: {
        fontSize: 14,
        color: '#53D769',
        fontWeight: '300',
        margin: 4,
    },
    commisionView: {
        width: '100%',
        backgroundColor: '#017781',
        height: 60,
        marginTop: 20,
        alignItems: "center",
    },
    commisionTrueView: {
        width: '100%',
        backgroundColor: '#53D769',
        height: 60,
        marginTop: 20,
        alignItems: "center",
    },
    titleCommission: {
        fontSize: 18,
        fontWeight: '700',
        color: '#FFF',
        marginTop: 8,
    },
    txtCommission: {
        fontSize: 14,
        fontWeight: '300',
        color: '#FFF',
    }
})