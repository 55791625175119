import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import Loading from '../../components/Loading';

import NotificationMessage from './NotificationMessage';

import { getNotifications } from "../../services/notifications.service"

export default function NotificationsPage({ navigation }) {
    const [notificacoes, setNotificacoes] = useState([])

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        buscarNotificacoes()
    }, [])

    const buscarNotificacoes = async () => {
        try {
            setLoading(() => true)

            let resultado = await getNotifications()
            setNotificacoes(resultado)

            setLoading(() => false)
        } catch (error) {
            setError(() => error?.message || "Ocorreu um erro")
            setLoading(() => false)
        }
    }

    return (
        <ScrollView>
            {loading ?
                <Loading />
                :
                <>
                    {error ?
                        <Text style={{ textAlign: "center", marginTop: 30 }}>{error}</Text>
                        :
                        <>
                            {notificacoes.length == 0 ?
                                <Text style={{ textAlign: "center", marginTop: 30 }}>Nenhuma notificação</Text>
                                :
                                <View style={{ padding: 20 }}>
                                    {[...notificacoes].reverse().map(notificacao => <NotificationMessage key={notificacao._id} notificacao={notificacao} setNotificacoes={setNotificacoes} />)}
                                </View>
                            }
                        </>
                    }
                </>
            }
        </ScrollView>
    )
}