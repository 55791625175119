import { StyleSheet } from "react-native";
import * as Device from 'expo-device';

let styles = {
    container: {
        backgroundColor: '#02AB89',
        flex: 1,
    },
    centerView: {
        alignItems: 'center'
    },
    logo: {
        height: 45,
        width: 93,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 210,
        marginBottom: 50,
    },
    viewRow: {
        flexDirection: "row",
    },
    buttonSignIn: {
        marginTop: 30,
    },
    buttonSignUp: {
        marginRight: 25,
        marginLeft: 25,
        width: 350,
    },
    txt: {
        alignSelf: "center",
        color: '#FFF',
        fontSize: 12,
        fontWeight: '600',
        marginTop: 20,
    }
}

if (Device.deviceName == null) { // null
    styles.logo = {
        height: 45 * 2,
        width: 93 * 2,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 210,
        marginBottom: 50,
    }
}

export default StyleSheet.create(styles)