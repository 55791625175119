import api from './api.service';

export async function getAutocompleteCPF(cpf) {
    try {
        let resultado = await api.get(`/pessoas/autocomplete/cpf/${cpf}`)

        //console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}