import api from './api.service';

export async function getRelatorioFinanceiro(evento_id) {
    try {
        //console.log(`/compras/relatorios/comissario/financeiro/${evento_id}`)
        let url = `/compras/relatorios/comissario/financeiro/${evento_id}`
        console.log(url)
        let resultado = await api.get(url)

        //console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        console.error("ERRO getRelatorioFinanceiro", error)
        throw error?.data || error
    }
}