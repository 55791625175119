import { StyleSheet } from "react-native";

export default StyleSheet.create({
    buttons: {
        flexDirection: "row",
        marginTop: 20
    },
    button: {
        marginTop: 10,
        borderRadius: 11,
        padding: 8,
        backgroundColor: '#53D769',
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"

    },
    buttonText: {
        fontSize: 16,
        color: "#ffffff"
    }
})