import api from './api.service';

export async function postPushAcesso(token) {
    try {
        console.log("postPushAcesso", token)
        let resultado = await api.post(`/pessoas/push/acesso`, { token })

        //console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}

export async function getNotifications() {
    try {
        let resultado = await api.get(`/pessoas/notificacoes`)

        //console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}

export async function putNotification(id, data) {
    try {
        let resultado = await api.put(`/pessoas/notificacoes/${id}`, data)

        //console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}
