import React, { useEffect, useContext } from "react"
import { View, BackHandler, Text, Image } from "react-native"
import Loading from "../../components/Loading"
import Page from "../../components/Page"
import { AuthContext } from "../../contexts/auth.context"
import { AppContext } from "../../contexts/app.context"

import { useIsFocused } from '@react-navigation/native';

import Constants from 'expo-constants';

export default function BlockApp({ navigation }) {
    const isFocused = useIsFocused();

    useEffect(() => {
        const backHandler = BackHandler.addEventListener('hardwareBackPress', () => isFocused);
        return () => backHandler.remove();
    }, [isFocused])

    return (
        <Page style={{ backgroundColor: "#02AB89" }}>
            <View style={{ marginTop: 50, justifyContent: "center", alignItems: "center" }}>
                <Image
                    style={{ height: 45, width: 93, justifyContent: 'center', alignItems: 'center', marginTop: 210, marginBottom: 50 }}
                    source={require('../../../assets/logoipassneg.png')}
                />

                <Text style={{ color: "#ffffff", fontSize: 18, fontWeight: "600", marginBottom: 20 }}>Atualize o aplicativo!</Text>

                <Text style={{ color: "#ffffff", textAlign: "center" }}>Seu aplicativo está na versão {Constants.manifest.version}, anterior à versão mínima permitida.</Text>
            </View>
        </Page >
    )
}