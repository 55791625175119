import api from './api.service';

export async function verificarTitular(dados, modo = "simples") {
    try {
        console.log("verificarTitular", dados, modo)
        let resultado = await api.post(`/ingressos/titulares/verificar/${modo}`, dados)

        console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        console.error(error)
        throw error?.data || error
    }
}

export async function getIngressos(evento_id = null) {
    try {
        console.log(`/ingressos/comissarios/${evento_id}`)
        let resultado = await api.get(`/ingressos/comissarios/${evento_id}`)

        //console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}

export async function postEnvioIngresso(ingresso_id, tipo = null) {
    try {
        let resultado = await api.post(`/ingressos/envio`, { ingresso_id, tipo })

        console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}