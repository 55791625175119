import { StyleSheet } from "react-native";

export default StyleSheet.create({
    container: {
        zIndex: 100,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        flexDirection: "column",
        backgroundColor: "rgba(2, 171, 137, 0.7)"
    },
    exit: {
        flex: 100000000
    },
    modal: {
        width: "85%",
        maxHeight: "80%",
        padding: 5,
        minHeight: 100,
        backgroundColor: "#F4F4F4",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        padding: 20,
        paddingBottom: 50,
        marginLeft: "7.5%",
    }
})