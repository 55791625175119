import React, { useState, useContext, useCallback } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { EventosContext } from '../../contexts/eventos.context';
import { formatReal } from '../../helpers/format';
import Button from '../Button';
import Table, { THead, TBody, Tr, Td } from '../Table';
import styles from './styles';

export default function OpenCommisionerCard({ etapas, ...rest }) {
    const [botoes, setBotoes] = useState([
        { id: "dinheiro", nome: "Dinheiro" },
        { id: "cartao-credito", nome: "Cartão" },
        { id: "pix", nome: "PIX" },
        { id: "total", nome: "Total" },
    ])

    const [botaoSelecionado, setBotaoSelecionado] = useState("total")

    const calcTotal = useCallback(() => {
        let total = 0

        Object.keys(etapas).map(etapa => {
            Object.keys(etapas[etapa].setores).map(setor => {
                Object.keys(etapas[etapa].setores[setor].lotes).map(lote => {
                    Object.keys(etapas[etapa].setores[setor].lotes[lote].modalidades).map(modalidade => {
                        total += botaoSelecionado == "total" ?
                            etapas[etapa].setores[setor].lotes[lote].modalidades[modalidade].total.total :
                            etapas[etapa].setores[setor].lotes[lote].modalidades[modalidade].meios_pagamento[botaoSelecionado].total
                    })
                })
            })
        })

        return total
    }, [etapas, botaoSelecionado])

    return (
        <View style={styles.cardComissario}>
            <View style={{ ...styles.rowView, justifyContent: "space-between", paddingHorizontal: 10, marginBottom: 10 }}>
                {botoes.map(botao => <Button key={botao.id} onPress={(() => setBotaoSelecionado(botao.id))} style={styles.btns} styleText={{ fontSize: 14 }} title={botao.nome} backgroundColor={botao.id == botaoSelecionado ? "#017781" : "#53D769"} />)}
            </View>


            <Table>
                <THead>
                    <Tr style={{ paddingBottom: 5 }}>
                        <Td textStyle={{ ...styles.titleTable }} style={{ flex: 2.3 }}>Lote</Td>
                        <Td textStyle={{ ...styles.titleTable }} style={{ ...styles.separador, flex: 0.8 }}>Qtde</Td>
                        {/*<Td textStyle={{ ...styles.titleTable }} style={{ ...styles.separador }}>Valor</Td>*/}
                        <Td textStyle={{ ...styles.titleTable }} style={{ ...styles.separador }}>TOTAL</Td>
                    </Tr>
                </THead>
                <TBody>
                    {Object.keys(etapas)
                        .filter(e =>
                            Object.keys(etapas[e].setores).filter(s =>
                                Object.keys(etapas[e].setores[s].lotes).filter(l =>
                                    Object.keys(etapas[e].setores[s].lotes[l].modalidades).filter(m =>
                                        etapas[e].setores[s].lotes[l].modalidades[m].total.quantidade > 0
                                    ).length
                                ).length
                            ).length
                        )
                        .map(etapaKey => {
                            let etapa = etapas[etapaKey]
                            console.log("etapa", etapa)

                            return (
                                <View key={etapaKey}>
                                    {Object.keys(etapa?.setores).length > 1 && (
                                        <Tr>
                                            <Td textStyle={{ ...styles.text }}>{etapa.nome}</Td>
                                        </Tr>
                                    )}
                                    {/*Object.keys(etapa.setores)
                                    .filter(lote => (
                                        botaoSelecionado == "total" ?
                                            lote.meios_pagamento.dinheiro.quantidade + lote.meios_pagamento.pix.quantidade + lote.meios_pagamento["cartao-credito"].quantidade :
                                            lote.meios_pagamento[botaoSelecionado].quantidade) > 0).length
                                    ).map((setor, index) => (*/}
                                    {Object.keys(etapa.setores)
                                        .filter(s => Object.keys(etapa.setores[s].lotes)
                                            .filter(l => Object.keys(etapa.setores[s].lotes[l].modalidades)
                                                .filter(m => Object.keys(etapa.setores[s].lotes[l].modalidades[m]).length > 0)
                                            ).length > 0
                                        )
                                        .map((setorKey, index) => {
                                            let setor = etapa.setores[setorKey]

                                            return (
                                                <>
                                                    <View key={setorKey}>
                                                        <Tr>
                                                            <Tr key={setor._id} style={{ marginTop: index > 0 ? 20 : 0 }}>
                                                                <Td textStyle={{ ...styles.text }} style={{ flex: 1 }}>{setor.nome}</Td>
                                                            </Tr>
                                                        </Tr>
                                                        {/*setor.lotes.filter(lote => (botaoSelecionado == "total" ? lote.meios_pagamento.dinheiro.quantidade + lote.meios_pagamento.pix.quantidade + lote.meios_pagamento["cartao-credito"].quantidade : lote.meios_pagamento[botaoSelecionado].quantidade) > 0).map((lote, i) => (*/}
                                                        {Object.keys(setor.lotes)
                                                            .filter(l => Object.keys(setor.lotes[l].modalidades)
                                                                .filter(m => setor.lotes[l].modalidades[m]?.total.quantidade > 0).length > 0
                                                            )
                                                            .map((loteKey, i) => {
                                                                let lote = setor.lotes[loteKey]
                                                                // console.log("LOTEEE", lote)

                                                                return Object.keys(lote.modalidades)
                                                                    .filter(m => lote.modalidades[m]?.total.quantidade > 0)
                                                                    .map(modalidadeKey => {
                                                                        let modalidade = lote.modalidades[modalidadeKey]
                                                                        console.log("MODADLIDADEEE", modalidade)
                                                                        return (
                                                                            <Tr key={modalidadeKey}>
                                                                                <Tr key={setor._id} style={{ borderTopWidth: i == 0 ? 0.5 : 0, borderTopColor: "#ffffff", paddingVertical: 3 }}>
                                                                                    <Td textStyle={{ ...styles.text }} style={{ flex: 2.3 }}>{`${lote.nome} - ${modalidade.nome}`}</Td>
                                                                                    <Td textStyle={{ ...styles.text }} style={{ flex: 0.8 }}>
                                                                                        {botaoSelecionado == "total" ?
                                                                                            modalidade.meios_pagamento.dinheiro.quantidade + modalidade.meios_pagamento.pix.quantidade + modalidade.meios_pagamento["cartao-credito"].quantidade :
                                                                                            modalidade.meios_pagamento[botaoSelecionado].quantidade
                                                                                        }
                                                                                    </Td>
                                                                                    <Td textStyle={{ ...styles.text }}>
                                                                                        {formatReal(botaoSelecionado == "total" ?
                                                                                            modalidade.meios_pagamento.dinheiro.total + modalidade.meios_pagamento.pix.total + modalidade.meios_pagamento["cartao-credito"].total :
                                                                                            modalidade.meios_pagamento[botaoSelecionado].total)
                                                                                        }
                                                                                    </Td>
                                                                                </Tr>
                                                                            </Tr>
                                                                        )
                                                                    })
                                                            })}
                                                    </View>
                                                </>
                                            )
                                        })}
                                </View>
                            )
                        })}
                </TBody>
            </Table>

            <Text style={{ textAlign: "center", marginTop: 20, color: "#ffffff", fontWeight: "600", fontSize: 14 }}>TOTAL: {formatReal(calcTotal())}</Text>
        </View>
    )
}