import { StyleSheet } from "react-native";

export default StyleSheet.create({
    container: {
        backgroundColor: '#02AB89',
        flex: 1,
    },
    main: {
        paddingHorizontal: 30
    },
    centerView: {
        alignItems: 'center'
    },
    logo: {
        height: 45 * 1.2,
        width: 93 * 1.2,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 210,
        marginBottom: 50,
    },
    viewRow: {
        flexDirection: "row",
    },
    buttonSignIn: {
        marginVertical: 25,
        width: "100%",
    },
    buttonSignUp: {
        width: "100%",
    },


    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 20,
    },
    checkbox: {
        alignSelf: "center",
        width: 20,
        height: 20
    },
})