import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  buttonsPayment: {
    marginTop: 10,
    height: 61,
    borderRadius: 11,
    padding: 8,
    backgroundColor: '#53D769',
  },

  viewRow: {
    flexDirection: 'row',
  },
  imgPayment: {
    marginTop: 5,
  },
  textButtonsPayment: {
    marginTop: 2,
    marginLeft: 18,
    color: '#FFF',
    fontSize: 20,
    //fontFamily: 'Arial',
  },
  textTax: {
    marginTop: 1,
    marginLeft: 18,
    color: '#FFF',
    textAlign: 'center',
    fontSize: 10,
    //fontFamily: 'Arial',
  },
});
