import React, { useState, useEffect, useContext } from 'react';
import {
  ScrollView,
  View,
  Image,
  Text,
  TouchableOpacity,
  BackHandler,
  Alert,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Loading from '../../components/Loading';
import Page from '../../components/Page';
import styles from './styles';
import Icon from 'react-native-vector-icons/FontAwesome';
import RenderHtml from 'react-native-render-html';
import { Dimensions } from 'react-native';

import Avatar from '../../components/Avatar';

import { getEvento, getEventos } from '../../services/eventos.service';
import { getResponsaveis } from '../../services/produtor.service';
import {
  formatDataHoraEtapa,
  formatCelular,
  formatEndereco,
} from '../../helpers/format';

import { formatReal } from '../../helpers/format';
import LightboxCheckout from '../../components/LightboxCheckout';
import Button from '../../components/Button';

import { AppContext } from '../../contexts/app.context';
import { EventosContext } from '../../contexts/eventos.context';
import { ModalBody, ModalHeader } from '../../components/Modal';
import TicketSales from '../../components/TicketSales';
import { adjustImage } from '../../utils/formatImg';

import * as Device from 'expo-device';
import { CompraContext } from '../../contexts/compra.context';

export default function Event({ navigation, route }) {
  const { setModal, setModalBottom } = useContext(AppContext);
  const { eventoAberto, setEventoAberto } = useContext(EventosContext);
  const { carrinho, resetCompra } = useContext(CompraContext);

  const [evento, setEvento] = useState(null);
  const [etapaSelecionada, setEtapaSelecionada] = useState(null);

  const [openedLightboxCheckout, setOpenedLightboxCheckout] = useState(false);

  useEffect(() => {
    if (eventoAberto?.evento?.etapas.length)
      setEtapaSelecionada(eventoAberto.evento.etapas[0]);
  }, []);

  const ContentModalProdutor = () => {
    return (
      <>
        <ModalHeader title="Fale com o Produtor" />
        <ModalBody>
          {evento?.produtor?.responsaveis.map((responsavel, i) => (
            <View
              style={{ marginTop: i > 0 ? 15 : 0 }}
              key={responsavel.responsabilidade._id}
            >
              <Text style={{ fontSize: 15, fontWeight: '500' }}>
                {responsavel.responsabilidade.nome}
              </Text>

              {responsavel?.nome && (
                <Text style={{ marginTop: 5, fontWeight: '200' }}>
                  <Text style={{ fontWeight: '500' }}>Nome: </Text>
                  {responsavel?.nome}
                </Text>
              )}
              {responsavel?.telefone && (
                <Text style={{ marginTop: 5, fontWeight: '200' }}>
                  <Text style={{ fontWeight: '500' }}>Telefone: </Text>
                  {formatCelular(responsavel?.telefone)}
                </Text>
              )}
              {responsavel?.email && (
                <Text style={{ marginTop: 5, fontWeight: '200' }}>
                  <Text style={{ fontWeight: '500' }}>E-mail: </Text>
                  {responsavel?.email}
                </Text>
              )}
            </View>
          ))}
        </ModalBody>
      </>
    );
  };

  const buscarEventos = async () => {
    try {
      let resultado = await getEventos();
      return resultado;
    } catch (error) {
      console.warn('error', error);
      return [];
    }
  };

  const AtualizarEventoAberto = async () => {
    const response = await buscarEventos();

    if (response.length > 0) {
      const evento_atual = response.find(
        (evento) => evento._id == eventoAberto._id
      );

      if (evento_atual.status === 0) {
        setModalBottom(null);
      }

      setEventoAberto(evento_atual);
    } else {
      navigation.navigate('Home');
    }
  };

  useEffect(() => {
    // atualizar a cada 3 minutos
    const interval = setInterval(() => {
      AtualizarEventoAberto();
    }, 1000 * 60 * 3);

    return () => clearInterval(interval);
  }, []);

  const AbrirBotaoDeVendas = async () => {
    await AtualizarEventoAberto();

    setModalBottom({
      beforeClose: async () => {
        return new Promise((resolve, reject) => {
          Alert.alert(
            //title
            `Atenção`,
            //body
            'Tem certeza que deseja abandonar o carrinho de vendas?',
            [
              { text: 'Sim', onPress: () => resolve(true) },
              {
                text: 'Não',
                onPress: () => reject(false),
                style: 'cancel',
              },
            ],
            { cancelable: false }
            //clicking out side of alert will not cancel
          );
        });
      },
      onClose: async () => {
        resetCompra();
      },
      component: <TicketSales />,
    });
  };

  return (
    <LinearGradient
      colors={
        Device.deviceName == null
          ? ['#FFFFFF']
          : ['#02AB89', 'rgba(255, 255, 255, 0)']
      }
      start={{ x: 0, y: 0 }}
      end={{ x: 0, y: 0.6 }}
      style={{ minHeight: '100%', width: '100%' }}
    >
      <ScrollView style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              width: 800,
              maxWidth: Dimensions.get('window').width,
            }}
          >
            <View style={styles.main}>
              <Image
                style={styles.eventImage}
                source={{ uri: adjustImage(eventoAberto?.evento?.path_imagem) }}
              />

              <Text style={styles.eventTitle}>
                {eventoAberto?.evento?.nome}
              </Text>

              <View style={{ alignItems: 'center' }}>
                <View style={{ flexDirection: 'row' }}>
                  <Icon name="calendar" color={'#02AB89'} size={16} />
                  <Text style={{ marginLeft: 5, fontWeight: '300' }}>
                    {eventoAberto?.evento?.datahora_primeiro
                      ? formatDataHoraEtapa(
                          eventoAberto?.evento?.datahora_primeiro,
                          eventoAberto?.evento?.datahora_ultimo
                        )
                      : 'Indefinido'}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', marginTop: 10 }}>
                  <Icon name="map-marker" color={'#02AB89'} size={16} />
                  <Text style={{ marginLeft: 5, fontWeight: '300' }}>
                    {eventoAberto?.evento?.etapas.length > 0 &&
                    eventoAberto?.evento?.etapas[0]?.endereco?.cidade &&
                    eventoAberto?.evento?.etapas[0]?.endereco?.cidade?.estado
                      ?.sigla
                      ? `${eventoAberto.evento?.etapas?.[0]?.endereco?.cidade?.nome} - ${eventoAberto.evento?.etapas?.[0]?.endereco?.cidade?.estado?.sigla}`
                      : 'Indefinido'}
                  </Text>
                </View>
              </View>
            </View>

            {eventoAberto?.evento.etapas.length > 1 && (
              <ScrollView
                horizontal={true}
                style={{ marginVertical: 20, paddingLeft: 30 }}
              >
                {eventoAberto.evento.etapas.map((etapa) => (
                  <TouchableOpacity
                    key={etapa._id}
                    onPress={() => setEtapaSelecionada(etapa)}
                  >
                    <View
                      style={{
                        ...styles.sombra,
                        backgroundColor:
                          etapa._id == etapaSelecionada?._id
                            ? '#04AD8A'
                            : '#ffffff',
                        padding: 20,
                        borderRadius: 10,
                        minWidth: 220,
                        marginRight: 20,
                        minHeight: 100,
                        justifyContent: 'space-between',
                        marginVertical: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          color:
                            etapa._id == etapaSelecionada?._id
                              ? '#ffffff'
                              : '#152536',
                          fontWeight: 'bold',
                        }}
                      >
                        {etapa.nome}
                      </Text>

                      <View style={{ flexDirection: 'row' }}>
                        <Icon
                          name="calendar"
                          color={
                            etapa._id == etapaSelecionada?._id
                              ? '#ffffff'
                              : '#02AB89'
                          }
                          size={16}
                        />
                        <Text
                          style={{
                            marginLeft: 5,
                            color:
                              etapa._id == etapaSelecionada?._id
                                ? '#ffffff'
                                : '#152536',
                          }}
                        >
                          {etapa.datahora_inicio
                            ? formatDataHoraEtapa(
                                etapa.datahora_inicio,
                                etapa.datahora_fim
                              )
                            : 'Indefinido'}
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                ))}
              </ScrollView>
            )}

            {Device.deviceName != null && (
              <View style={styles.main}>
                <View style={styles.partText}>
                  <Text style={{ ...styles.title, marginTop: 20 }}>
                    Sobre o evento
                  </Text>
                  {/* <Text style={styles.text}>{etapaSelecionada?.descricao}</Text> */}
                  <RenderHtml
                    contentWidth={Dimensions.get('window').width}
                    source={{ html: etapaSelecionada?.descricao }}
                  />
                </View>

                <View style={styles.partText}>
                  <Text style={styles.title}>Atrações</Text>
                  <Text style={styles.text}>{etapaSelecionada?.atracoes}</Text>
                </View>

                <View style={styles.partText}>
                  <Text style={styles.title}>Informações</Text>
                  {etapaSelecionada?.informacoes_complementares.map(
                    (informacao, i) => (
                      <View key={i}>
                        <Text style={{ ...styles.text, fontWeight: '400' }}>
                          {informacao.nome}
                        </Text>
                        <Text style={styles.text}>{informacao.resposta}</Text>
                      </View>
                    )
                  )}
                </View>

                <View style={styles.partText}>
                  <Text style={styles.title}>Local do evento</Text>
                  <Text style={styles.text}>
                    {etapaSelecionada?.endereco
                      ? formatEndereco(etapaSelecionada?.endereco)
                      : 'Não definido até o momento'}
                  </Text>
                </View>

                <View style={{ marginBottom: 50 }}></View>
              </View>
            )}

            <View
              style={{
                backgroundColor: '#f0f0f0',
                padding: 30,
                flexDirection: 'row',
              }}
            >
              <Avatar
                size={100}
                uri={eventoAberto?.evento?.produtor.path_logo}
              />

              <View style={{ paddingLeft: 20, flex: 1 }}>
                <Text
                  style={{ fontWeight: '500', fontSize: 16, marginBottom: 10 }}
                >
                  {eventoAberto?.evento?.produtor.nome_fantasia}
                </Text>
                <Text style={{ fontSize: 14, fontWeight: '200' }}>
                  {eventoAberto?.evento?.produtor.mensagem_apresentacao}
                </Text>

                <View
                  style={{
                    marginTop: 20,
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Button
                    title="Falar com o produtor"
                    style={{
                      borderWidth: 1,
                      borderColor: '#02AB89',
                      borderRadius: 10,
                    }}
                    styleText={{ color: '#02AB89', fontSize: 14 }}
                    onPress={() => setModal(<ContentModalProdutor />)}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>

      <TouchableOpacity
        onPress={() =>
          !eventoAberto?.evento?.finalizado && eventoAberto?.status == 1
            ? AbrirBotaoDeVendas()
            : null
        }
      >
        <View
          style={{
            backgroundColor:
              !eventoAberto?.evento?.finalizado && eventoAberto?.status == 1
                ? '#53D769'
                : 'tomato',
            width: '100%',
            height: 50,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 18, color: '#ffffff', fontWeight: '500' }}>
            {eventoAberto?.status == 1
              ? !eventoAberto?.evento?.finalizado
                ? 'Vender Ingressos'
                : 'Evento encerrado'
              : 'Bloqueado'}
          </Text>
          {!eventoAberto?.evento?.finalizado && (
            <Icon
              name="shopping-cart"
              size={18}
              color="#ffffff"
              style={{ marginLeft: 10 }}
            />
          )}
        </View>
      </TouchableOpacity>
    </LinearGradient>
  );
}
