import React, { useEffect } from "react"

import * as Device from 'expo-device'

import ModalBottom from "../ModalBottom"
import ModalBottomSmall from "../ModalBottomSmall"
import Modal from "../Modal"
import Status from "../Status"

export default function Structure() {
    useEffect(() => {
        if (Device.deviceName == null) {
            setTimeout(() => {
                const element = document.getElementsByTagName("iframe")
                if (element.length > 0)
                    element[0].remove()
            }, 3000)
        }
    }, [])

    return (
        <>
            <ModalBottom />
            <ModalBottomSmall />
            <Modal />
            <Status />
        </>
    )
}