import React from 'react';
import { RootSiblingParent } from 'react-native-root-siblings';

import { NavigationContainer } from '@react-navigation/native';
import Routes from './src/routes';
import { WebSocketProvider } from './src/contexts/websocket.context';
import { AppProvider } from './src/contexts/app.context';
import { AuthProvider } from './src/contexts/auth.context';
import { CompraProvider } from './src/contexts/compra.context';
import Structure from './src/components/Structure';
import { EventosProvider } from './src/contexts/eventos.context';
import { NotificationsProvider } from './src/contexts/notifications.context';
import { NativeBaseProvider } from 'native-base';

export default function App() {
  return (
    <NativeBaseProvider>
      <RootSiblingParent>
        <NavigationContainer>
          <WebSocketProvider>
            <AppProvider>
              <NotificationsProvider>
                <AuthProvider>
                  <EventosProvider>
                    <CompraProvider>
                      <Routes />
                      <Structure />
                    </CompraProvider>
                  </EventosProvider>
                </AuthProvider>
              </NotificationsProvider>
            </AppProvider>
          </WebSocketProvider>
        </NavigationContainer>
      </RootSiblingParent>
    </NativeBaseProvider>
  );
}
