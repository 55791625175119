import React, { useState, useContext, useEffect } from 'react';
import { View, Text } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { CompraContext } from '../../contexts/compra.context';
import Button from '../Button';
import styles from './styles'

export default function NumberOfTicketsResgate({ lote, carrinho, setCarrinho }) {
    const [number, setNumber] = useState(0)

    useEffect(() => {
        update()
    }, [])

    useEffect(() => {
        update()
    }, [carrinho])

    const update = () => {
        let busca = carrinho.filter(i => i._id == lote?._id)
        if (busca.length > 0) {
            busca = busca[0]
            setNumber(() => busca.quantidade)
        } else
            setNumber(0)
    }

    const add = () => {
        setCarrinho(carrinho => {
            let busca = carrinho.filter(i => i._id == lote._id)
            if (busca.length > 0) {
                busca = busca[0]
                busca.quantidade++
                return [
                    ...carrinho.filter(i => i._id != lote._id),
                    busca
                ]
            } else {
                return [...carrinho, {
                    ...lote,
                    quantidade: 1
                }]
            }
        })
    }

    const sub = () => {
        setCarrinho(carrinho => {
            let busca = carrinho.filter(i => i._id == lote._id)
            if (busca.length > 0) {
                busca = busca[0]

                if (busca.quantidade <= 0)
                    return carrinho

                busca.quantidade--

                if (busca.quantidade <= 0)
                    return carrinho.filter(i => i._id != lote._id)

                return [
                    ...carrinho.filter(i => i._id != lote._id),
                    busca
                ]
            } else {
                return [...carrinho, {
                    ...lote,
                    quantidade: 0
                }]
            }
        })
    }

    return (
        <View style={styles.rowView}>
            <Button onPress={() => sub()} style={{ ...styles.btns, marginRight: 5 }} title={<Icon name="minus" color="#ffffff" size={12} />} backgroundColor='#00535A' disabled={number == 0} />

            <View style={styles.viewNumber}><Text style={styles.txtNumber}>{number}</Text></View>

            <Button onPress={() => add()} style={{ ...styles.btns, marginLeft: 5 }} title={<Icon name="plus" color="#ffffff" size={12} />} backgroundColor='#00535A' />
        </View>
    )
}