import React from 'react';
import { Text, TouchableOpacity, Image, View } from 'react-native';
import styles from './styles';

export default function ButtonsPayment({ paymentType, tax, imgLoc, style, ...rest }) {
    return (
        <TouchableOpacity style={{ ...styles.buttonsPayment, ...style }} {...rest}>
            <View style={styles.viewRow}>
                {imgLoc && <Image source={imgLoc} style={styles.imgPayment} />}
                <View>
                    <Text style={styles.textButtonsPayment}>{paymentType}</Text>
                    <Text style={styles.textTax}>{tax}</Text>
                </View>
            </View>
        </TouchableOpacity>
    )
}