import { StyleSheet, Dimensions } from "react-native";

export default StyleSheet.create({
    container: {
        zIndex: 100,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        // backgroundColor: "blue"
    },
    exit: {
        width: "100%",
        flex: 100000000,
    },
    modal: {
        width: "100%",
        maxWidth: 800,
        maxHeight: "80%",
        padding: 5,
        minHeight: 100,
        backgroundColor: "#4BC1A9",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        padding: 20,
        paddingBottom: 50,
    }
})