import React, { useRef } from 'react';
import { View, Text, SafeAreaView, ScrollView } from 'react-native';
import Input from '../../components/Input';
import Button from '../../components/Button';
import MainlyModal from '../../components/MainlyModal';
import { Form } from '@unform/mobile'
import InputMask from '../../components/InputMask';
import styles from './styles';

export default function TicketData({ navigation, avancar, setNovoTitular }) {
    const formRef = useRef();

    const back = () => {
        navigation.navigate("Venda de ingressos")
    }
    const go = () => {
        navigation.navigate("Pagamento")
    }
    return (
        <SafeAreaView>
            <Text style={styles.titleData}>Dados do ingresso</Text>
            <Text style={styles.titleInform}>Informe os dados do titular do ingresso.</Text>
            <Text style={styles.textInform}>O ingresso é pessoal é intransferível, é possível transferir a titularidade
                apenas pela da ferramenta de transferência iPass.</Text>
            <Text style={styles.titleTicketType}>Pista - Inteira - Lote 2</Text>
            <Form ref={formRef}>
                <InputMask type="cpf" name="cpf" placeholder="CPF" />
                <Input name="nome" placeholder="Nome Completo" />
                <InputMask type="cel-phone" name="celular" placeholder="WhatsApp (receberá o ingresso nele)" />
                <Input name="email" placeholder="E-mail" />
            </Form>
            <View style={styles.viewRow}>
                <Button onPress={back} title="Voltar" backgroundColor='#017781' style={styles.buttonForms} />
                <Button onPress={go} title="Avançar" backgroundColor='#53D769' style={styles.buttonForms} />
            </View>
        </SafeAreaView>
    )
}

/*<SafeAreaView>
            <ScrollView>
                <MainlyModal>
                    <Text style={styles.titleData}>Dados do ingresso</Text>
                    <Text style={styles.titleInform}>Informe os dados do titular do ingresso.</Text>
                    <Text style={styles.textInform}>O ingresso é pessoal é intransferível, é possível transferir a titularidade 
                                                    apenas pela da ferramenta de transferência iPass.</Text>
                    <Text style={styles.titleTicketType}>Pista - Inteira - Lote 2</Text>
                    <Input placeholder="CPF"/>
                    <Input placeholder="Nome Completo"/>
                    <Input placeholder="WhatsApp (receberá o ingresso nele)"/>
                    <Input placeholder="E-mail"/>
                    <View style={styles.viewRow}>
                        <Button onPress={() => back()} title="Voltar" backgroundColor='#017781' style={styles.buttonForms}/>
                        <Button  title="Avançar" backgroundColor='#53D769' style={styles.buttonForms}/>
                    </View>
                </MainlyModal>
            </ScrollView>
        </SafeAreaView> */