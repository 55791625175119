import { StyleSheet } from "react-native";

export default StyleSheet.create({
    separador: {
        borderLeftWidth: 1.5,
        borderColor: "#ffffff",
        paddingHorizontal: 5,
    },
    cardComissario: {
        backgroundColor: "rgba(42, 108, 52, 0.65)",
        borderRadius: 6,
        shadowColor: '#000',
        marginBottom: 10,
        borderRadius: 10,
        paddingVertical: 10
    },
    rowView: {
        flexDirection: "row",
    },
    imgView: {
        margin: 11,
        marginLeft: 14,
        borderRadius: 40,
    },
    titleName: {
        fontSize: 14,
        fontWeight: '500',
        color: '#FFF'
    },
    titleTable: {
        alignSelf: "center",
        color: '#ffffff',
        fontSize: 14,
        fontWeight: '500',
    },
    text: {
        color: "#ffffff",
        fontWeight: "300",
        fontSize: 12,
        paddingHorizontal: 5
    },
    btns: {
        width: "23%",
        height: 40,
    },
})