import { StyleSheet } from "react-native";

export default StyleSheet.create({
    container: {
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        zIndex: 200,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row"
    },
    exit: {
        zIndex: 201,
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    modal: {
        zIndex: 202,
        margin: "5%",
        maxHeight: "80%",
        borderRadius: 10,
        backgroundColor: "#ffffff"
    }
})