import { StyleSheet } from "react-native";

export default StyleSheet.create({
    title: {
        fontSize: 32,
        lineHeight: 42,
        fontWeight: "700",
        color: '#fff',
    },
    titleInform: {
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "200",
        color: '#fff',
        marginTop: 10,
    },
    ticketType: {
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "700",
        color: '#fff',
        marginTop: 24,
    },
    ticketPrice: {
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "500",
        color: '#00535A',
        marginTop: 2,
    },
    totalValue: {
        alignSelf: "center",
        fontSize: 32,
        lineHeight: 43,
        fontWeight: "bold",
        color: '#00535A',
        marginTop: 10,
    },
    viewRow: {
        flexDirection: "row",
    },
    buttonWithdrawal: {
        height: 45,
        marginTop: 20,
    },
    txtCommission: {
        alignSelf: "center",
        fontSize: 18,
        lineHeight: 20,
        fontWeight: "700",
        color: '#FFF',
        marginTop: 40,
    }
})