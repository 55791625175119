import React, { useContext } from "react"
import { ScrollView, View, TouchableOpacity, Text } from "react-native"

import Icon from 'react-native-vector-icons/FontAwesome';

import { AppContext } from "../../contexts/app.context"

import styles from "./styles"

export function ModalHeader({ title, style }) {
    const { modal, setModal } = useContext(AppContext)

    return (
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 10, borderBottomWidth: 1, borderBottomColor: "#ccc", ...style }}>
            <Text style={{ fontWeight: "500" }}>{title}</Text>

            <TouchableOpacity onPress={() => setModal(null)}>
                <Icon name="times" color="#000" size={18} />
            </TouchableOpacity>
        </View>
    )
}

export function ModalBody({ children, style }) {
    return (
        <View style={{ padding: 10, ...style }}>
            {children}
        </View>
    )
}

export function ModalFooter({ children, style }) {
    return (
        <View style={{ padding: 10, borderTopWidth: 1, borderTopColor: "#ccc", ...style }}>

        </View>
    )
}

export default function ModalBottom({ style }) {
    const { modal, setModal } = useContext(AppContext)

    return (
        <>
            {modal && <View style={{ ...styles.container }}>
                <TouchableOpacity style={styles.exit} onPress={() => setModal(null)}></TouchableOpacity>
                <ScrollView style={{ ...styles.modal, ...style }}>
                    {modal}
                </ScrollView>
            </ View>}
        </>
    )
}