import React, { useContext, useState, useEffect } from "react";
import { SafeAreaView, KeyboardAvoidingView, Alert } from "react-native";

import { ScrollView, View, TouchableOpacity } from "react-native";
import { AppContext } from "../../contexts/app.context";

import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import styles from "./styles";
import { CompraContext } from "../../contexts/compra.context";

export default function ModalBottomSmall({ style }) {
  const { modalBottomSmall, setModalBottomSmall } = useContext(AppContext);
  const { loadingCompra } = useContext(CompraContext);

  const [keyboardOpened, setKeyboardOpened] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(null);

  const keyboardEvent = (event, frame) => {
    let {
      startCoordinates: { height },
    } = frame;

    console.log(event, height);

    if (!keyboardHeight) setKeyboardHeight(height);

    setKeyboardOpened(() => (event == "show" ? true : false));
  };

  const exit = () => {
    if (loadingCompra)
      return Alert.alert(
        //title
        `Atenção`,
        //body
        "Aguarde o processamento da compra.",
        [
          {
            text: "Ok",
            onPress: () => {},
          },
        ],
        { cancelable: false }
        //clicking out side of alert will not cancel
      );

    setModalBottomSmall(null);
    setKeyboardOpened(() => false);
  };

  return (
    <>
      {modalBottomSmall && (
        <View style={{ ...styles.container }}>
          <TouchableOpacity
            style={styles.exit}
            onPress={exit}
          ></TouchableOpacity>
          <ScrollView
            style={{
              ...styles.modal,
              ...style,
              marginBottom: keyboardOpened ? keyboardHeight + 20 : 0,
            }}
          >
            {modalBottomSmall}
          </ScrollView>
          <KeyboardAwareScrollView
            // style={{ ...styles.modal, ...style, marginBottom: keyboardOpened ? keyboardHeight : 0 }}
            onKeyboardWillShow={(frame) => keyboardEvent("show", frame)}
            onKeyboardWillHide={(frame) => keyboardEvent("hide", frame)}
          >
            {/* {modalBottomSmall} */}
          </KeyboardAwareScrollView>
        </View>
      )}
    </>
  );
}
