import React from "react"
import { View, Text, TouchableOpacity } from "react-native"

import Icon from 'react-native-vector-icons/FontAwesome';

import NumberOfTicketsResgate from "../../../components/NumberOfTicketsResgate";
import Button from '../../../components/Button';

import { formatReal } from "../../../helpers/format";

import styles from './styles';

export default function RCConfirmacao({ carrinho, valorTotal, voltar, confirmar, comissaoDisponivel }) {
    return (
        <View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity onPress={voltar}>
                    <Icon name="chevron-left" size={32} color="#ffffff" style={{ marginRight: 10 }} />
                </TouchableOpacity>
                <Text style={styles.title}>Confirme o Resgate</Text>
            </View>

            {carrinho.sort((a, b) => a.setor_nome < b.setor_nome ? -1 : (a.setor_nome > b.setor_nome ? 1 : 0)).map((lote, i) => (
                <View key={i}>
                    {carrinho.findIndex(l => l.setor_nome == lote.setor_nome) == i && <Text style={{ ...styles.ticketType, textTransform: "uppercase" }}>{lote.setor_nome}</Text>}
                    <View style={{ marginTop: 0, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Text style={{ ...styles.ticketPrice, textTransform: "uppercase", marginTop: 5, fontSize: 13 }}>{lote.modalidade_string} - {lote.nome}</Text>
                        <Text style={{ ...styles.ticketPrice, textTransform: "uppercase", color: "#fff", marginTop: 5 }}>{`${lote.quantidade} x ${formatReal(lote.valor)}`}</Text>
                    </View>
                </View>
            ))}

            <Text style={styles.txtCommission}>Comissão: {formatReal(comissaoDisponivel)}</Text>
            <Text style={styles.totalValue}>TOTAL: {formatReal(valorTotal)}</Text>

            <Button title="CONFIRMAR RESGATE" backgroundColor='#017781' style={styles.buttonWithdrawal} onPress={confirmar} />

            <View style={{ marginTop: 80 }}></View>
        </View>
    )
}