import React from "react"
import { View } from "react-native"

import RNPicker from 'react-native-picker-select';

import styles from "./styles"

export default function Picker({ style, ...rest }) {
    return (
        <View>
            <RNPicker
                placeholder={{
                    label: 'Escolha...',
                    value: null,
                }}
                style={{
                    ...styles,
                    iconContainer: {
                        top: 10,
                        right: 10,
                    },
                    placeholder: {
                        color: '#ffffff'
                    },
                    ...style
                }}
                Icon={() => {
                    return (
                        <View
                            style={{
                                backgroundColor: 'transparent',
                                borderTopWidth: 10,
                                borderTopColor: '#ffffff',
                                borderRightWidth: 10,
                                borderRightColor: 'transparent',
                                borderLeftWidth: 10,
                                borderLeftColor: 'transparent',
                                width: 0,
                                height: 0,
                            }}
                        />
                    );
                }}
                {...rest}
            />
        </View>
    )
}
