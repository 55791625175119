import React, { useState, useEffect, useContext } from 'react';
import { View, Text, SafeAreaView, ScrollView } from 'react-native';

import Button from '../../components/Button';
import NumberOfTicketsResgate from '../../components/NumberOfTicketsResgate';
import Loading from '../../components/Loading';

import { EventosContext } from "../../contexts/eventos.context"

import { formatReal } from "../../helpers/format"
import { getLotesComissarioEvento } from "../../services/lotes.service"

import styles from './styles';
import RCIngressos from './RCIngressos';
import RCConfirmacao from './RCConfirmacao';
import { postResgate } from '../../services/resgates.service';
import { AppContext } from '../../contexts/app.context';

export default function ResgateComissao({ comissario, refresh }) {
    const { setModalBottom } = useContext(AppContext)
    const { eventoAberto } = useContext(EventosContext)

    const [carrinho, setCarrinho] = useState([])

    const [lotes, setLotes] = useState([])

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [valorTotal, setValorTotal] = useState(0)

    const [etapa, setEtapa] = useState("ingressos")

    const [comissaoDisponivel, setComissaoDisponivel] = useState(0)

    useEffect(() => {
        buscarLotes()
    }, [])

    useEffect(() => {
        calcValorTotal()
    }, [carrinho])

    useEffect(() => {
        if (comissario)
            setComissaoDisponivel(+comissario?.valor_comissao_disponivel)
    }, [comissario])

    const buscarLotes = async () => {
        try {
            setLoading(() => true)

            let resultado = await getLotesComissarioEvento(eventoAberto._id)
            //console.log("Lotes", resultado)
            setLotes(resultado)

            setLoading(() => false)
        } catch (error) {
            console.error(error)
            setLoading(() => false)
        }
    }

    const calcValorTotal = () => {
        console.log("carrinhoooo", carrinho)
        let total = 0

        for (let ingresso of carrinho) {
            total += ingresso.valor * ingresso.quantidade
        }

        setValorTotal(() => total)
    }

    const avancar = () => {
        try {
            setEtapa("confirmacao")
        } catch (error) {

        }
    }

    const confirmar = async () => {
        try {
            setLoading(() => true)

            let dados = {
                evento_id: eventoAberto.evento._id,
                ingressos: []
            }

            for (let ingresso of carrinho) {
                dados.ingressos.push({
                    id: ingresso._id,
                    quantidade: ingresso.quantidade
                })
            }

            //console.log("confirmar", dados)

            let resultado = await postResgate(dados)

            if (refresh)
                refresh()

            setModalBottom(null)

            setLoading(() => false)
        } catch (error) {
            console.error(error)
            setError(() => error?.message || "Ocorreu um erro")
            setLoading(() => false)
        }
    }

    return (
        <View>
            {loading ?
                <Loading color="#ffffff" />
                : <>
                    {etapa == "ingressos" && <RCIngressos lotes={lotes} carrinho={carrinho} setCarrinho={setCarrinho} valorTotal={valorTotal} avancar={avancar} comissaoDisponivel={comissaoDisponivel} comissario={comissario} />}
                    {etapa == "confirmacao" && <RCConfirmacao carrinho={carrinho} voltar={() => setEtapa("ingressos")} confirmar={confirmar} valorTotal={valorTotal} comissaoDisponivel={comissaoDisponivel} comissario={comissario} />}

                    {error && <Text style={{ textAlign: "center", marginTop: 20, fontSize: 14, color: "tomato" }}>{error}</Text>}
                </>
            }
        </View>
    )
}