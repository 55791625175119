export const validVersion = (versaoMenor, versaoMaior) => {
    try {
        let arrayMenor = versaoMenor.split(".")
        let arrayMaior = versaoMaior.split(".")

        // console.log(arrayMenor, arrayMaior)
        let maxLength = arrayMenor.length > arrayMaior.length ? arrayMenor.length : arrayMaior.length
        // console.log(maxLength)

        for (let i = 0; i < maxLength; i++) {
            let menor = arrayMenor.length > i ? +arrayMenor[i] : 0
            let maior = arrayMaior.length > i ? +arrayMaior[i] : 0

            // console.log([menor, maior])

            if (menor > maior)
                return false
        }

        return true
    } catch (error) {
        return true
    }
}