import React, { useRef, useState, useEffect, useContext } from "react"
import { View, Text, ScrollView, TouchableOpacity, BackHandler } from "react-native"
import { CompraContext } from "../../../contexts/compra.context"
import WizardStep from "../../WizardStep"

import styles from "./styles"

import Button from '../../Button'

import FormTitular from "./FormTitular"

import * as Yup from 'yup'

import Icon from 'react-native-vector-icons/FontAwesome';

import { verificarTitular } from "../../../services/ingressos.service"

import { formatReal } from "../../../helpers/format"


import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export default function TSTitulares() {
    const formRef = useRef()

    const { carrinho, setStep, titulares, setTitulares, ingressosIndividuais, setIngressosIndividuais } = useContext(CompraContext)

    const [ingressosIndividuaiselecionada, setIngressosIndividuaiselecionada] = useState(null)

    const [loading, setLoading] = useState(false)
    const [loadingChanged, setLoadingChanged] = useState(false)
    const [erro, setErro] = useState(null)

    useEffect(() => {
        updateingressosIndividuais()
    }, [])

    useEffect(() => {
        const backAction = () => {
            voltar()
            return true;
        };

        const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

        return () => backHandler.remove();
    }, [])

    useEffect(() => {
        updateingressosIndividuais()
    }, [carrinho])

    useEffect(() => {
        //console.log("TITULARES", titulares)
    }, [titulares])

    useEffect(() => {
        console.log("ingressosIndividuaiselecionada", ingressosIndividuaiselecionada)
        // formRef?.current?.setDataField("nome", "Testando")
    }, [ingressosIndividuaiselecionada])

    useEffect(() => {
        if (ingressosIndividuais?.length) {
            setIngressosIndividuaiselecionada(0)
        }

        setTitulares(() => {
            let titulares = []

            ingressosIndividuais.map(s => {
                titulares.push({
                    cpf: "",
                    nome: "",
                    celular: "",
                    email: ""
                })
            })

            return titulares
        })
    }, [ingressosIndividuais])

    useEffect(() => {
        if (loadingChanged) {
            setLoadingChanged(() => false)
        } else {
        }
    }, [loadingChanged])

    const updateingressosIndividuais = () => {
        console.log("{ updateingressosIndividuais }")
        try {
            setIngressosIndividuais(() => {
                let ingressosIndividuais = []

                for (let ingresso of carrinho) {
                    [...Array(ingresso.quantidade).keys()].map(i => {
                        let { quantidade, ...ing } = ingresso
                        ing.titular = {}
                        ingressosIndividuais.push(ing)
                    })
                }

                console.log("ingressosIndividuais", ingressosIndividuais)

                return ingressosIndividuais
            })
        } catch (error) {

        }
    }

    const voltar = () => {
        console.log("voltar", ingressosIndividuaiselecionada)
        if (ingressosIndividuaiselecionada <= 0)
            setStep("escolha")
        else {
            formRef.current.setErrors({})
            setIngressosIndividuaiselecionada(ingressosIndividuaiselecionada => ingressosIndividuaiselecionada - 1)
        }
    }

    const avancar = async (data) => {
        try {
            console.log(data)
            //setLoading(() => true)
            setErro(() => null)

            if (data?.valor_doacao == "")
                data.valor_doacao = 0

            if (data.email && typeof data.email == "string")
                data.email = data.email.trim()

            formRef.current.setErrors({})

            let shape = {
                cpf: Yup.string().required("Campo obrigatório"),
                nome: Yup.string().required("Campo obrigatório"),
                celular: Yup.string().required("Campo obrigatório"),
                data_nascimento: Yup.string().required("Campo obrigatório"),
                email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
            }
            //valor_minimo_doacao
            if (ingressosIndividuais[ingressosIndividuaiselecionada].modalidade?.solidario == true) {
                let { valor_doacao } = data
                valor_doacao = +valor_doacao
                shape = {
                    ...shape,
                    valor_doacao: Yup.number("Campo obrigatório").required("Campo obrigatório").test("verificacao-doacao", `Doação mínima: ${formatReal(ingressosIndividuais[ingressosIndividuaiselecionada]?.modalidade?.valor_minimo_doacao)}`, () => ingressosIndividuais[ingressosIndividuaiselecionada].modalidade.valor_minimo_doacao <= valor_doacao).nullable("Campo obrigatório")
                }
            }

            const schema = Yup.object().shape(shape)

            await schema.validate(data, {
                abortEarly: false
            })

            let busca = titulares.filter((t, i) => i != ingressosIndividuaiselecionada && t.cpf == data.cpf)
            if (busca.length > 0)
                throw { status: 401, message: "CPF já preenchido em outro ingreso" }

            console.log("ingressosIndividuais", ingressosIndividuais)
            data.evento_etapa_id = ingressosIndividuais[ingressosIndividuaiselecionada]?.evento_etapa_id
            data.lote_id = ingressosIndividuais[ingressosIndividuaiselecionada]?._id
            data.ingresso_id = ingressosIndividuais[ingressosIndividuaiselecionada]?._id
            data.modalidade = ingressosIndividuais[ingressosIndividuaiselecionada]?.modalidade

            await verificarTitular(data)

            setTitulares(titulares => {
                titulares[ingressosIndividuaiselecionada] = { ...data, ok: true }
                return [...titulares]
            })

            formRef.current.reset()

            setLoadingChanged(() => true)

            //console.log("ingressosIndividuais length", ingressosIndividuais.length)
            if (ingressosIndividuaiselecionada == (ingressosIndividuais.length - 1)) {
                // Verificar se tem ingresso solidário, para ir para comissões
                // if (ingressosIndividuais.filter(i => i?.modalidade?.solidario == true).length > 0)
                //     setStep("doacoes")
                // else
                setStep("confirmacao")
            } else
                setIngressosIndividuaiselecionada(ingressosIndividuaiselecionada + 1)

            setLoading(() => false)
        } catch (error) {
            console.error(error)
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {}

                error.inner.forEach(err => {
                    errorMessages[err.path] = err.message
                })

                formRef.current.setErrors(errorMessages)
            } else {
                setErro(() => error?.message || error || "Ocorreu um erro")
            }

            setLoading(() => false)
        }
    }

    return (
        <KeyboardAwareScrollView>
            <View>
                <Text style={{ ...styles.title, fontSize: 32 }}>Dados do Ingresso</Text>

                <Text style={{ fontSize: 15, color: "#ffffff", fontWeight: "700", marginTop: 5 }}>Informe os dados do titular do ingresso.</Text>

                <Text style={{ fontSize: 15, color: "#ffffff", fontWeight: "300", marginTop: 10 }}>O ingresso é pessoal e intransferível, é possível transferir a titularidade apenas pela da ferramenta de transferência iPass.</Text>

                <View>
                    {titulares?.length > 1 &&
                        <ScrollView style={{ width: "100%", textAlign: "center", flex: 1, paddingTop: 30 }}>
                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", flex: 1 }}>
                                {ingressosIndividuais.map((ingresso, i) => (
                                    <View key={i} style={{ flexDirection: "row", alignItems: "center" }}>
                                        <TouchableOpacity
                                            style={{
                                                width: 30,
                                                height: 30,
                                                backgroundColor: ingressosIndividuaiselecionada == i ? "#53D769" : "#017781",
                                                borderRadius: 100,
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                            onPress={() => { }}//setIngressosIndividuaiselecionada(i)}
                                        >
                                            {(titulares[i].ok || titulares[i].error) && (
                                                <View style={{ position: "absolute", top: -5, right: -5, padding: 2, backgroundColor: "#ffffff", borderRadius: 100 }}>
                                                    <Icon name={titulares[i].error ? "times" : "check"} color={titulares[i].error ? "red" : "#53D769"} size={12} />
                                                </View>
                                            )}
                                            <Text style={{ fontSize: 16, fontWeight: "500", color: "#ffffff" }}>{i + 1}</Text>
                                        </TouchableOpacity>

                                        {i < (ingressosIndividuais.length - 1) && <View style={{ width: 20, height: 1, backgroundColor: "#017781" }}></View>}
                                    </View>
                                ))}
                            </View>
                        </ScrollView>
                    }

                    <Text style={{ fontSize: 16, color: "#032124", fontWeight: "800", marginTop: 20 }}>{ingressosIndividuais[ingressosIndividuaiselecionada]?.setor_nome} - {ingressosIndividuais[ingressosIndividuaiselecionada]?.modalidade.nome} - {ingressosIndividuais[ingressosIndividuaiselecionada]?.nome}</Text>

                    <View>
                        {!loadingChanged && ingressosIndividuais?.[ingressosIndividuaiselecionada]?.evento_etapa_id && (
                            <FormTitular
                                formRef={formRef}
                                titular={titulares[ingressosIndividuaiselecionada]}
                                ingressosIndividuaiselecionada={ingressosIndividuaiselecionada}
                                onSubmit={avancar}
                                disabled={loading}
                                doacao={ingressosIndividuais?.[ingressosIndividuaiselecionada]?.modalidade?.solidario == true}
                                evento_etapa_id={ingressosIndividuais?.[ingressosIndividuaiselecionada]?.evento_etapa_id}
                            />
                        )}
                    </View>

                    {erro && (
                        <Text style={{ fontSize: 16, color: "red", textAlign: "center", marginVertical: 20 }}>{erro}</Text>
                    )}

                    <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 20, marginBottom: 40, width: "100%" }}>
                        <View style={{ width: "47.5%" }}>
                            <Button onPress={voltar} title="Voltar" backgroundColor='#017781' />
                        </View>
                        <View style={{ width: "47.5%" }}>
                            <Button onPress={() => formRef.current.submitForm()} title="Avançar" backgroundColor='#53D769' />
                        </View>
                    </View>
                </View>
            </View>
        </KeyboardAwareScrollView>
    )
}