import React, { useContext, useEffect, useState } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import Button from "../../Button";
import styles from "./styles";
import NumberOfTickets from "../../NumberOfTickets";
import Icon from "react-native-vector-icons/FontAwesome";

import { formatReal } from "../../../helpers/format";
import { CompraContext } from "../../../contexts/compra.context";
import { EventosContext } from "../../../contexts/eventos.context";

export default function TSSetores({ back }) {
  const {
    valorTotal,
    etapa,
    setEtapa,
    setorSelecionado,
    setSetorSelecionado,
    setStep,
    carrinho,
  } = useContext(CompraContext);
  const { eventoAberto } = useContext(EventosContext);

  const [loading, setLoading] = useState(false);

  const sellTickets = () => {
    try {
      setLoading(() => true);

      if (carrinho.length == 0) {
        alert("Nenhum ingresso foi selecionado");
        throw false;
      }

      for (let ingresso of carrinho) {
        if (
          (ingresso.compra_minima &&
            ingresso.compra_minima > ingresso.quantidade) ||
          (ingresso.compra_maxima &&
            ingresso.compra_maxima < ingresso.quantidade)
        ) {
          alert("Verifique as quantidades dos ingressos");
          throw false;
        }
      }

      setStep("titulares");

      setLoading(() => false);
    } catch (error) {
      console.log(error);
      setLoading(() => false);
    }
  };

  const getFaixaPrecoSetor = (setor) => {
    if (!setor) return null;
    if (setor.lotes.length == 0) return null;

    let menor = setor.lotes[0].valor;
    let maior = setor.lotes[0].valor;

    //console.log("getFaixaPrecoSetor", setor)

    setor.lotes.forEach((ingresso) => {
      if (ingresso.valor < menor) menor = ingresso.valor;
      if (ingresso.valor > maior) maior = ingresso.valor;
    });

    if (menor == maior && menor == 0) return `GRÁTIS`;

    // console.log(menor, maior);
    menor = formatReal(menor);
    maior = formatReal(maior);
    // console.log(menor, maior);

    return menor == maior ? menor : `${menor} - ${maior}`;
  };

  const AtualizarEtapa = () => {
    if (!eventoAberto.evento.etapas) return;

    let etapaAtual = eventoAberto.evento.etapas.find(
      (e) => e?._id == etapa?._id
    );

    if (!etapaAtual) return;

    setEtapa(etapaAtual);
  };

  useEffect(() => {
    AtualizarEtapa();
  }, [eventoAberto]);

  return (
    <>
      {etapa && (
        <>
          <ScrollView>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              {back && (
                <TouchableOpacity onPress={() => setEtapa(null)}>
                  <Icon name="chevron-left" size={24} />
                </TouchableOpacity>
              )}
              <Text style={{ ...styles.title, marginTop: 0 }}>Setores</Text>
            </View>

            {etapa.setores.map((setor) => (
              <View key={setor._id}>
                <TouchableOpacity
                  onPress={() => {
                    if (getFaixaPrecoSetor(setor))
                      setSetorSelecionado((setorSelecionado) =>
                        setorSelecionado == null ||
                        setorSelecionado._id != setor._id
                          ? setor
                          : null
                      );
                  }}
                >
                  <View
                    style={{
                      marginTop: 15,
                      borderWidth: 1,
                      borderColor: "#ffffff",
                      padding: 10,
                      borderRadius: 5,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor:
                        setorSelecionado?._id == setor._id
                          ? "#fff"
                          : "transparent",
                    }}
                  >
                    <View style={{}}>
                      <Text
                        style={{
                          ...styles.ticketType,
                          textTransform: "uppercase",
                          color:
                            setorSelecionado?._id == setor._id
                              ? "#00535A"
                              : "#fff",
                        }}
                      >
                        {setor.nome}
                      </Text>
                      <Text style={styles.ticketPrice}>
                        {getFaixaPrecoSetor(setor) || "Nenhuma opção de compra"}
                      </Text>
                    </View>
                    {getFaixaPrecoSetor(setor) && (
                      <Icon
                        name={
                          setor._id == setorSelecionado?._id
                            ? "chevron-up"
                            : "chevron-down"
                        }
                        size={24}
                      />
                    )}
                  </View>
                </TouchableOpacity>

                {setorSelecionado?._id == setor._id && (
                  <View
                    style={{
                      padding: 10,
                      paddingTop: 0,
                      borderWidth: 1,
                      borderTopWidth: 0,
                      borderColor: "#ffffff",
                      width: "95%",
                      alignSelf: "center",
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                    }}
                  >
                    {setorSelecionado?.lotes.length == 0 && (
                      <Text style={{ textAlign: "center", marginTop: 10 }}>
                        Nenhum ingresso encontrado
                      </Text>
                    )}

                    {setorSelecionado?.lotes.map((lote, i) => (
                      <View
                        key={i}
                        style={{
                          marginTop: 15,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              ...styles.ticketType,
                              textTransform: "uppercase",
                            }}
                          >
                            {lote?.modalidade?.nome}
                          </Text>
                          <Text
                            style={{
                              ...styles.ticketPrice,
                              textTransform: "uppercase",
                              marginTop: 5,
                              fontSize: 13,
                            }}
                          >
                            {lote.modalidade.nome} - {lote.nome}
                          </Text>
                          <Text
                            style={{
                              ...styles.ticketPrice,
                              textTransform: lote.valor ? "none" : "uppercase",
                              color: "#fff",
                              marginTop: 5,
                            }}
                          >
                            {+lote.valor
                              ? `${formatReal(lote.valor)}${
                                  !lote.absorver_taxas ? ` + taxas` : ""
                                }`
                              : "GRÁTIS"}
                          </Text>
                        </View>
                        <View style={{ width: 110 }}>
                          <NumberOfTickets lote={lote} />
                        </View>
                      </View>
                    ))}
                  </View>
                )}
              </View>
            ))}
          </ScrollView>
          <View style={styles.totalArea}>
            <Text style={styles.totalValue}>
              TOTAL: {formatReal(valorTotal?.pix.total)}
            </Text>
            <Text
              style={{
                textAlign: "center",
                fontSize: 18,
                color: "#fff",
                fontWeight: "500",
              }}
            >
              ou {formatReal(valorTotal?.["cartao-credito"].total)} no Cartão de
              Crédito
            </Text>

            <View style={{ marginBottom: 40 }}>
              <Button
                onPress={sellTickets}
                title={loading ? "Carregando..." : "VENDER AGORA"}
                backgroundColor="#53D769"
                style={styles.buttonSell}
                disabled={loading}
              />
            </View>
          </View>
        </>
      )}
    </>
  );
}
