import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { View, Text, SafeAreaView, ScrollView, Image, TouchableOpacity } from 'react-native';
import { useIsFocused } from "@react-navigation/native";

import Icon from 'react-native-vector-icons/FontAwesome';
import TicketCard from '../../components/TicketCard';
import Input from '../../components/Input';
import styles from './styles';

import { Form } from '@unform/mobile'
import Page from '../../components/Page';
import Loading from '../../components/Loading';

import { getIngressos } from "../../services/ingressos.service"

import { EventosContext } from "../../contexts/eventos.context"
import TicketsCard from '../../components/TicketCard';
import { removerAcento } from '../../helpers/format';

export default function SalesAndCapture({ navigation }) {
    const formRef = useRef()

    const { eventoAberto } = useContext(EventosContext)

    const [pesquisa, setPesquisa] = useState("")

    const [ingressos, setIngressos] = useState([])

    const [loading, setLoading] = useState(true)

    const [ticketOpened, setTicketOpened] = useState(null)

    useEffect(() => {
        navigation.addListener('focus', () => {
            buscarIngressos()
        });
    }, [])

    const buscarIngressos = async () => {
        try {
            setLoading(() => true)

            let resultado = await getIngressos(eventoAberto?.evento?._id)
            setIngressos(resultado)

            setLoading(() => false)
        } catch (error) {
            setLoading(() => false)
        }
    }

    const onSubmitSearch = async (data) => {
        //console.log("onSubmitSearch", data)
    }

    const onOpenIngresso = async (ingresso_id) => {
        setTicketOpened(ingresso_id)
    }

    return (
        <Page styleScroll={{ padding: 20 }}>
            <View style={styles.centerView}>
                <Text style={styles.titleSales}>Vendas e captações</Text>
                <Text style={styles.titleInformSales}>Reenvie ingressos e comprovantes clicando em cima.</Text>
                <View style={styles.inputView}>
                    <Form ref={formRef} onSubmit={onSubmitSearch}>
                        <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                            <Input name="pesquisa" style={styles.input} placeholder="Digite o nome, cpf ou códoigo" color="#02AB89" onKeyPress={val => {
                                console.log(val.target.value)
                                setPesquisa(val?.target?.value?.length > 1 ? val.target.value : "")
                            }} />
                            <TouchableOpacity style={{ paddingBottom: 10, paddingLeft: 10, borderBottomWidth: 1, borderBottomColor: "#02AB89" }}>
                                <Icon name="search" color="#02AB89" size={20} />
                            </TouchableOpacity>
                        </View>
                    </Form>
                </View>
                <View style={{ marginTop: 20 }}>
                    {loading && (
                        <View style={{ marginVertical: 20 }}>
                            <Loading text={ingressos?.length > 0 ? "Atualizando" : "Carregando"} />
                        </View>
                    )}

                    <View style={{ paddingBottom: 50 }}>
                        {ingressos?.length == 0 && <Text style={{ textAlign: "center", color: "#000", marginTop: 20 }}>Nenhum ingresso vendido</Text>}

                        {ingressos?.length > 0 && (
                            <View>
                                {ingressos.filter(i => i?.codigo?.indexOf(pesquisa) >= 0 || removerAcento(i?.titular?.nome).indexOf(pesquisa) >= 0 || i?.titular?.cpf.indexOf(pesquisa) >= 0 || pesquisa.trim() == "").map(ingresso => (
                                    <View key={ingresso._id}>
                                        <TicketsCard ingresso={ingresso} setIngressos={setIngressos} titular={ingresso.titular} pressable={true} onOpen={onOpenIngresso} defaultOpened={ingresso._id == ticketOpened} />
                                    </View>
                                ))}
                            </View>
                        )}
                    </View>
                </View>
            </View>
        </Page>
    )
}