import React, { createContext, useEffect, useState } from 'react'
import socketIOClient from "socket.io-client";
import * as Device from 'expo-device';

export const WebSocketContext = createContext({})

export const WebSocketProvider = ({ children }) => {
    var [socket, setSocket] = useState(null)

    useEffect(() => {
        if (Device.deviceName != null)
            connect()
    }, [])

    const connect = () => {
        const sckt = socketIOClient("https://api.ipass.com.br", { path: "/config/socket.io" })
        //socket = sckt
        setSocket(() => sckt)
    }

    const onSocket = (evento, callback) => {
        //console.log("onSocket", evento)
        if (Device.deviceName != null) {
            socket.on(evento, data => {
                callback(data)
            })
        }
    }

    return (
        <WebSocketContext.Provider value={{
            onSocket
        }}>
            {children}
        </WebSocketContext.Provider>
    )
}

export default WebSocketContext