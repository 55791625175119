export const adjustImage = (url) => {
    if (!url)
        return "https://res.cloudinary.com/dazkdemvu/image/upload/v1660326243/imagens/inhihm3ppulkgbattafw.jpg"

    if (url.indexOf("http") < 0) {
        let split = url.split("\\")
        let image = split[split.length - 1]
        return `https://ipass.com.br/Image/Evento_Miniatura/${image}`
    }

    return url
}