import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const api = axios.create({
  baseURL: "https://homologacao.api.ipassingressos.com.br/", // Homolog
  params: {
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJpcGFzcyIsImludGVncmFjYW9faWQiOiI2ZjQ5MjJmNDU1NjgxNjFhOGNkZjRhZDIyOTlmNmQyMyIsImlhdCI6MTYxNDcwNjAxNX0.RAr8JAUV4lAR0HuZLK6aS6Ysz1eIOdq_yvEV-1e-8I4",
  },
});

api.interceptors.request.use(async (req) => {
  const token = await AsyncStorage.getItem(`x-access-token`);
  req.headers["x-access-token"] = token;

  return req;
});

api.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (error) => {
    throw error.response;
  }
);

export default api;
