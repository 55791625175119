import React, { useContext, useState } from "react";

import { View, Text, TouchableOpacity, SafeAreaView } from "react-native";

import Icon from "react-native-vector-icons/FontAwesome";

import { AppContext } from "../../contexts/app.context";

export default function Status() {
  const { status, setStatus } = useContext(AppContext);

  const [configs] = useState({
    success: { backgroundColor: "#53D769", color: "#000000", icon: "check" },
    error: {
      backgroundColor: "#C14545",
      color: "#ffffff",
      icon: "times",
      text: "Pagamento não aprovado, tente novamente.",
    },
    warning: {
      backgroundColor: "#F6D654",
      color: "#000000",
      icon: "check",
      text: "Aguardando o pagamento do cliente. Te avisaremos assim que ocorrer.",
    },
  });

  return (
    <>
      {status && status.type && (
        <SafeAreaView
          style={{
            flexDirection: "column",
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: configs[status.type].backgroundColor,
            zIndex: 2000,
          }}
        >
          <TouchableOpacity
            style={{
              flexDirection: "column",
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: configs[status.type].backgroundColor,
              zIndex: 2000,
            }}
            onPress={() => setStatus(() => null)}
          >
            {status.close != false && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  paddingHorizontal: 10,
                }}
              >
                <TouchableOpacity
                  style={{ padding: 20 }}
                  onPress={() => setStatus(() => null)}
                >
                  <Icon
                    name="times"
                    color={configs[status.type].color}
                    size={30}
                  />
                </TouchableOpacity>
              </View>
            )}

            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                marginTop: -40,
              }}
            >
              {(status.icon || configs[status.type].icon) && (
                <View
                  style={{
                    backgroundColor: configs[status.type].color,
                    borderRadius: 1000,
                    width: 200,
                    height: 200,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    name={status.icon || configs[status.type].icon}
                    color={configs[status.type].backgroundColor}
                    size={150}
                  />
                </View>
              )}
              <Text
                style={{
                  marginTop: 30,
                  color: configs[status.type].color,
                  fontSize: 24,
                  textAlign: "center",
                  fontWeight: "500",
                  maxWidth: "80%",
                }}
              >
                {status.text || configs[status.type].text}
              </Text>
            </View>
          </TouchableOpacity>
        </SafeAreaView>
      )}
    </>
  );
}
