import { StyleSheet } from "react-native";

export default StyleSheet.create({
    tr: {
        flexDirection: "row",
        width: "100%"
    },
    td: {
        flex: 1
    }
})