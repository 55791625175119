import { StyleSheet } from "react-native";

export default StyleSheet.create({
    title: {
        fontSize: 24,
        lineHeight: 29,
        fontWeight: "bold",
        color: '#fff',
        marginTop: 17,
        marginLeft: 20,
    },
    ticketType: {
        fontSize: 16,
        fontWeight: "700",
        color: '#fff',
    },
    ticketPrice: {
        fontSize: 14,
        fontWeight: "500",
        color: '#00535A',
    },
    totalValue: {
        alignSelf: "center",
        fontSize: 32,
        lineHeight: 43,
        fontWeight: "bold",
        color: '#00535A',
        marginTop: 30,
    },
    viewRow: {
        flexDirection: "row",
    },
    buttonSell: {
        marginTop: 30
    }
})