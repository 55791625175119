import React, { useEffect, useContext, useState } from "react"
import { View, BackHandler } from "react-native"
import Loading from "../../components/Loading"
import Page from "../../components/Page"
import { AuthContext } from "../../contexts/auth.context"
import { AppContext } from "../../contexts/app.context"

import { useIsFocused } from '@react-navigation/native';

import Constants from 'expo-constants';
import { getConteudo } from "../../services/configuracoes.service"
import { validVersion } from "../../helpers/validate"

export default function Load({ navigation }) {
    const isFocused = useIsFocused();

    const { setStatus } = useContext(AppContext)
    const { start, usuario, pessoa, loadingPessoa, logout } = useContext(AuthContext)

    const [verificado, setVerificado] = useState(null)

    useEffect(() => {
        start()

        verificarVersao()
    }, [])

    useEffect(() => {
        const backHandler = BackHandler.addEventListener('hardwareBackPress', () => isFocused);
        return () => backHandler.remove();
    }, [isFocused])

    useEffect(() => {
        //console.log("loadingPessoa", loadingPessoa)
        if (loadingPessoa == false && verificado != null) {
            if (verificado == true) {
                if (usuario) {
                    if (pessoa && pessoa.endereco && pessoa.telefone) {
                        navigation.navigate("Home")
                    } else {
                        setStatus({
                            type: "error",
                            text: "Seu cadastro não está completo. Complete-o pelo aplicativo iPass."
                        })
                        logout()
                        navigation.navigate("Login")
                    }
                } else {
                    navigation.navigate("Login")
                }
            } else {
                navigation.navigate("BlockApp")
            }
        }
    }, [loadingPessoa, verificado])

    const verificarVersao = async () => {
        try {
            let { conteudo } = await getConteudo("app-vendas-versao-minima")

            if (conteudo && typeof conteudo == "string") { // Formato certo
                if (!validVersion(conteudo, Constants.manifest.version)) {
                    navigation.navigate("BlockApp")
                } else {
                    setVerificado(() => true)
                }
            } else { // Desformatado, não podemos bloquear o aplicativo
                setVerificado(() => true)
            }
        } catch (error) {
            setVerificado(() => true)
        }
    }

    return (
        <Page style={{ backgroundColor: "#02AB89" }}>
            <View style={{ marginTop: 50 }}>
                <Loading color="#ffffff" />
            </View>
        </Page>
    )
}