import React, {useState} from 'react';
import {View, Text, SafeAreaView, ScrollView, Image } from 'react-native';
import TicketCard from '../../components/TicketCard';
import Input from '../../components/Input';
import styles from './styles';

export default function TicketsSold(){
    const [resend, setResend] = useState(false);
    
    return(
        <SafeAreaView>
            <ScrollView>
                <View style={styles.centerView}>
                    <Text style={styles.titleSales}>Ingressos vendidos</Text>
                    <Text style={styles.titleInformSales}>Confira atentamente os dados de todos os ingressos.</Text>
                    <View style={styles.inputView}>
                        <Input style={styles.input} icon="search" placeholder="Digite o nome, cpf, instituição, vaquina ou evento"/>
                    </View>
                    <View style={styles.ticketCard}>
                        {resend === false?
                            <TicketCard imgLocCard={require('../../../assets/Card_Ticket.png')}
                                       imgLocEvent={require('../../../assets/Filtro_Event_STL.png')}
                                       onPress={() => setResend(true)}
                            />
                        :
                        <Image source={require('../../../assets/IngressoReenviado.png')}/>
                        }
                        <TicketCard imgLocCard={require('../../../assets/Card_Ticket.png')}
                                    imgLocEvent={require('../../../assets/Filtro_Event_STL.png')}
                        />
                        <TicketCard imgLocCard={require('../../../assets/Card_Ticket.png')}
                                    imgLocEvent={require('../../../assets/Filtro_Event_STL.png')}
                        />
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}