import React, { useContext, useState } from "react";

import { View, Text } from "react-native";

import Button from "../Button";

import { CompraContext } from "../../contexts/compra.context";
import { AppContext } from "../../contexts/app.context";
import { formatReal } from "../../helpers/format";

export default function PagamentoDinheiro({ evento }) {
  const {
    reservarIngressos,
    efetuarCompra,
    resetCompra,
    valorTotal,
    ingressosIndividuais,
    loadingCompra,
  } = useContext(CompraContext);
  const { setModalBottomSmall, setStatus, setModalBottom } =
    useContext(AppContext);

  const [erro, setErro] = useState(null);
  const [loading, setLoading] = useState(false);

  const confirmar = async () => {
    try {
      setLoading(() => true);
      setErro(() => null);

      const { token } = await reservarIngressos(evento._id);
      await efetuarCompra(
        {
          meio_pagamento: "dinheiro",
          parcelas: 1,
        },
        token
      );

      setModalBottomSmall(null);
      setModalBottom(null);
      resetCompra();

      setStatus("success");

      setLoading(() => false);
    } catch (error) {
      console.error(error);
      setErro(() => error?.message || "Ocorreu um erro");
      setLoading(() => false);
    }
  };

  return (
    <View>
      <Text style={{ fontSize: 24, color: "#02AB89", fontWeight: "600" }}>
        Pagamento Dinheiro
      </Text>
      <Text
        style={{
          fontSize: 14,
          color: "#02AB89",
          fontWeight: "300",
          marginTop: 10,
        }}
      >
        Receba no dinheiro e repasse para o produtor no momento da sangria.
      </Text>

      {erro && (
        <Text
          style={{
            marginTop: 20,
            textAlign: "center",
            fontSize: 14,
            color: "tomato",
          }}
        >
          {erro}
        </Text>
      )}

      <View style={{ marginTop: 20 }}>
        <Button
          title={
            loading
              ? "Carregando..."
              : `Confirmar - ${formatReal(valorTotal.dinheiro.total)}`
          }
          backgroundColor="#53D769"
          styleText={{ fontSize: 16, paddingVertical: 5 }}
          onPress={confirmar}
          disabled={loading || loadingCompra}
        />
      </View>
    </View>
  );
}
