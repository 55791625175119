import { StyleSheet } from "react-native";

export default StyleSheet.create({
    
    titleSales:{
        fontSize: 36,
        lineHeight: 43,
        fontWeight: "700",
        color: '#02AB89',
        marginTop: 17,
    },
    titleInformSales:{
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "500",
        color: '#02AB89',
        marginBottom: 10,
    },
    viewRow:{
        marginTop: 50,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
})