import React, { useState, useContext, useEffect, useRef } from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Form } from '@unform/mobile'

import Page from "../../components/Page"

import Input from '../../components/Input';
import Button from '../../components/Button';
import { AuthContext } from '../../contexts/auth.context';

import styles from './styles';

import * as Yup from 'yup'

import { solicitarRecuperacaoSenha } from "../../services/auth.service"

export default function RecoveryPassword({ navigation }) {
    const formRef = useRef()

    const { pessoa, login } = useContext(AuthContext);

    const [loading, setLoading] = useState(false)

    const [erro, setErro] = useState(null)

    const [dataSaved, setDataSaved] = useState(null)

    const [etapa, setEtapa] = useState("dados")

    const [email, setEmail] = useState(null)

    const handleSubmit = async (data) => {
        try {
            //console.log("handleSubmit", data)
            //data = { login: "renegustavocegoncalves@gmail.com", senha: "rene010396" }

            setLoading(() => true)
            setErro(() => null)

            formRef.current.setErrors({})

            const schema = Yup.object().shape({
                login: Yup.string().required("Campo obrigatório"),
            })

            await schema.validate(data, {
                abortEarly: false
            })

            setEmail(data.email)

            const resultado = await solicitarRecuperacaoSenha(data.login, "EMAIL")
            //console.log(resultado)

            setEtapa("informacao")

            setLoading(() => false)
        } catch (error) {
            //console.error(error)
            //console.log("kkk")
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {}

                error.inner.forEach(err => {
                    errorMessages[err.path] = err.message
                })

                //console.log("errorMessages", errorMessages)

                formRef.current.setErrors(errorMessages)
            } else {
                setErro(() => error?.message || error || "Ocorreu um erro")
            }

            setLoading(() => false)
        }
    }

    return (
        <Page style={{ backgroundColor: '#02AB89' }}>
            {etapa == "dados" && <>
                <Text style={{ fontSize: 24, color: "#ffffff", textAlign: "center", marginTop: 50 }}>Recuperar Senha</Text>
                <Text style={{ fontSize: 14, textAlign: "center", marginTop: 10, color: "#ffffff", fontWeight: "300" }}>Informe seu e-mail para recuperar sua senha</Text>

                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Input name="login" icon="user" placeholder="digite aqui seu e-mail" autoCapitalize="none" defaultValue={dataSaved?.login} />
                </Form>

                {
                    erro && (
                        <View style={{ marginTop: 20, padding: 10, borderRadius: 10, borderWidth: 2, borderColor: "#FF555A" }}>
                            <Text style={{ fontSize: 15, color: "#ffffff", textAlign: "center", color: "#FF555A" }}>{erro}</Text>
                        </View>
                    )
                }

                <Button onPress={() => formRef.current.submitForm()} title={loading ? "Carregando..." : "Solicitar recuperação"} backgroundColor='#53D769' style={styles.buttonSignIn} disabled={loading} />
            </>}

            {etapa == "informacao" && <>
                <Text style={{ fontSize: 24, color: "#ffffff", textAlign: "center", marginTop: 50 }}>Estamos quase lá</Text>
                <Text style={{ fontSize: 14, textAlign: "center", marginTop: 10, color: "#ffffff", fontWeight: "300" }}>Te enviamos um e-mail para {email}. Para continuar, acesse o e-mail.</Text>
            </>}
        </Page >
    )
}