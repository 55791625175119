import { StyleSheet } from "react-native"

const comumIOSAndroid = {
    borderRadius: 10,
    backgroundColor: "#017781",
    paddingVertical: 5,
    color: '#ffffff',
    fontSize: 16,
    paddingHorizontal: 10,
    paddingRight: 30,
}

const styles = StyleSheet.create({
    inputIOS: {
        ...comumIOSAndroid,
    },
    inputAndroid: {
        ...comumIOSAndroid,
    },
});

export default styles