import React, { useState, useContext, useEffect } from 'react';
import { View, Text, SafeAreaView, ScrollView, TouchableOpacity, Button } from 'react-native';
import OpenCommisionerCard from '../../components/OpenCommisionerCard';
import Loading from '../../components/Loading';
import styles from './styles';

import Icon from 'react-native-vector-icons/FontAwesome';

import { formatReal } from "../../helpers/format"
import { EventosContext } from '../../contexts/eventos.context';
import { getRelatorioFinanceiro } from '../../services/relatorios.service';
import ResumoComissao from '../../components/ResumoComissao';

export default function Reports({ navigation }) {
    const [open, setOpen] = useState(false);

    const { eventoAberto } = useContext(EventosContext)

    const [relatorio, setRelatorio] = useState(null)

    const [loading, setLoading] = useState(true)
    const [erro, setErro] = useState(null)

    const [showEmDinheiro, setShowEmDinheiro] = useState(false)

    useEffect(() => {
        if (eventoAberto)
            buscarRelatorio()
        console.log(eventoAberto)
    }, [eventoAberto])


    const openCard = () => {
        if (open === false) {
            //console.log('falso')
            setOpen(true)
        } else {
            //console.log('true')
            setOpen(false)
        }
    }

    const buscarRelatorio = async () => {
        try {
            console.log("{ buscarRelatorio }", eventoAberto.evento._id)
            setErro(() => null)
            setLoading(() => true)

            let resultado = await getRelatorioFinanceiro(eventoAberto.evento._id)
            console.log("relatorio", resultado)
            setRelatorio(resultado)

            if (eventoAberto?.vendas_dinheiro || resultado?.meios_pagamento?.dinheiro?.valor > 0)
                setShowEmDinheiro(() => true)

            setLoading(() => false)
        } catch (error) {
            console.error(error)
            setErro(() => error?.message || "Ocorreu um erro")
            setLoading(() => false)
        }
    }

    return (
        <SafeAreaView style={styles.safe}>
            {loading && (
                <View style={{ marginTop: 30 }}>
                    <Loading />
                </View>
            )}

            {erro && <Text style={{ textAlign: "center", marginTop: 30, fontSize: 16 }}>{erro}</Text>}

            {(!loading && !erro) && <>
                <View style={styles.view}>
                    <ScrollView>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <Text style={styles.titleSales}>Relatórios</Text>

                            <TouchableOpacity onPress={buscarRelatorio}>
                                <Icon name="refresh" color="#02AB89" size={24} />
                            </TouchableOpacity>
                        </View>

                        <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 40, marginBottom: 40 }}>
                            <View style={{ ...styles.viewTickets, width: showEmDinheiro ? "49%" : "100%" }}>
                                <Text style={styles.titleMain}>Ingressos</Text>
                                <Text style={styles.txtMain}>{relatorio.quantidade_ingressos}</Text>
                                <View style={styles.viewDiv}>
                                    <Text style={styles.title}>Faturamento</Text>
                                    <Text style={styles.txt}>{formatReal(relatorio.valor_ingressos)}</Text>
                                </View>
                            </View>
                            {showEmDinheiro == true && (
                                <View style={{ ...styles.viewTickets, width: "49%" }}>
                                    <Text style={styles.titleMain}>em Dinheiro</Text>
                                    <Text style={styles.txtMain}>{formatReal(relatorio.dinheiro.valor)}</Text>
                                    <View style={styles.viewDiv}>
                                        <Text style={styles.title}>em Caixa</Text>
                                        <Text style={styles.txt}>{formatReal(relatorio.dinheiro.valor - relatorio.dinheiro.sangria)}</Text>
                                    </View>
                                </View>
                            )}
                        </View>

                        <OpenCommisionerCard etapas={relatorio.etapas} />
                    </ScrollView>
                </View>

                {/*<ResumoComissao valor_comissao={relatorio.valor_comissao} />*/}
            </>}
        </SafeAreaView>
    )
}