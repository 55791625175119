import React, { useState, useEffect, useContext } from "react"
import { View, Text, Image, TouchableOpacity, Share } from 'react-native'

import Icon from 'react-native-vector-icons/FontAwesome'

import styles from './styles'

import { formatReal, formatCPF, substituirVariaveisText } from "../../helpers/format"

import { postEnvioIngresso } from "../../services/ingressos.service"
import { AppContext } from "../../contexts/app.context"

export default function TicketLayout({ ingresso, setIngressos, titular: handleTitular, style, evento, pressable = false, onOpen, defaultOpened }) {
    const { mensagemEnvioIngressoComissario } = useContext(AppContext)

    const [titular, setTitular] = useState(null)

    const [opened, setOpened] = useState(false)

    useEffect(() => {
        if (opened && onOpen)
            onOpen(ingresso._id)
        console.log(ingresso)
    }, [opened])

    useEffect(() => {
        setOpened(() => defaultOpened)
    }, [defaultOpened])

    useEffect(() => {
        if (handleTitular) setTitular(handleTitular)
        else setTitular(ingresso.titular)
    }, [ingresso, handleTitular])

    const reenviar = async ({ _id }) => {
        try {
            const result = await Share.share({
                message: substituirVariaveisText(mensagemEnvioIngressoComissario, ingresso),
            });

            console.log(result, Share.sharedAction)
            if (result.action === Share.sharedAction) {
                try {
                    await postEnvioIngresso(ingresso._id, result?.activityType || null)

                    setIngressos(ingressos => {
                        for (let ing of ingressos) {
                            if (ing._id == ingresso._id) {
                                ing.enviado = 1
                                break
                            }
                        }
                        return [...ingressos]
                    })
                } catch (error) {
                    console.error(error)
                }
            }
        } catch (error) {
            alert(error.message);
        }
    }

    return (
        <>
            <TouchableOpacity onPress={() => pressable ? setOpened(!opened) : null}>
                <View style={{ ...styles.centerView, ...style }}>
                    <View style={{ width: 350, height: 110, position: "relative" }}>
                        <Image
                            style={{ position: "absolute", left: 0 }}
                            source={require(`../../../assets/Card_Ticket.png`)}
                        />
                        <Image
                            style={{ position: "absolute", right: 0, height: 105, aspectRatio: 1.91, borderTopRightRadius: 15, borderBottomRightRadius: 15, zIndex: -1 }}
                            source={{ uri: evento ? evento?.path_imagem : ingresso?.evento?.path_imagem }}
                        />
                    </View>

                    <View style={styles.content}>
                        {ingresso?.codigo && (
                            <View style={{ position: "absolute", left: -50, bottom: 4, alignItems: "center" }}>
                                <Text style={{ lineHeight: 12, fontSize: 12, color: "#ffffff", fontWeight: "400" }}>{ingresso.codigo.substring(0, 3)}</Text>
                                <Text style={{ lineHeight: 12, fontSize: 12, color: "#ffffff", fontWeight: "400" }}>{ingresso.codigo.substring(3, 6)}</Text>
                            </View>
                        )}
                        <View>
                            {titular ? <>
                                <Text style={styles.ticketName}>{titular.nome}</Text>
                                <Text style={styles.ticketCPF}>CPF: {formatCPF(titular.cpf)}</Text>
                            </> : (
                                <Text style={styles.semTitular}>Sem titular</Text>
                            )}
                        </View>

                        <Text style={styles.ticketEventName}>{evento ? evento?.nome : ingresso?.evento?.nome}</Text>
                        <Text style={styles.ticketType}>{ingresso?.lote?.modalidade?.nome} - {ingresso?.lote?.nome || ingresso?.nome}</Text>

                        <Text style={styles.ticketPrice}>{ingresso?.valor ? formatReal(ingresso.valor) : "GRÁTIS"}</Text>
                        {(+ingresso?.valor_doacao_doe || titular?.valor_doacao) && <Text style={styles.donatePrice}>{formatReal(+ingresso?.valor_doacao_doe || titular?.valor_doacao)}</Text>}

                        {/*<View>
                    <Text style={styles.ticketPurchaser}>Comprador: {ingresso?.comprador.nome}</Text>
                    <Text style={styles.ticketPurchaserCPF}>CPF: {formatCPF(ingresso?.comprador.cpf)}</Text>
                </View>*/}
                    </View>

                    {(!ingresso?.envios && ingresso?.envios?.length == 0) && (
                        <View style={{ position: "absolute", top: 0, right: 10, backgroundColor: "yellow", paddingVertical: 3, paddingHorizontal: 10, borderRadius: 5 }}>
                            <Text style={{ fontSize: 11, fontWeight: "400" }}>Não enviado</Text>
                        </View>
                    )}
                </View>
            </TouchableOpacity>
            {opened && (
                <View style={{ backgroundColor: "#e0e0e0", width: "80%", alignSelf: "center", marginTop: -40, paddingTop: 40, marginBottom: 10, borderRadius: 10, zIndex: -1 }}>
                    <TouchableOpacity onPress={() => reenviar(ingresso)}>
                        <View style={{ padding: 10, borderWidth: 1, borderColor: "#c0c0c0", borderRadius: 5, margin: 10, justifyContent: "center", alignItems: "center" }}>
                            <Icon name="share" color="#02AB89" size={20} />
                            <Text style={{ fontSize: 13, fontWeight: "500", marginTop: 10 }}>{ingresso?.enviado || ingresso?.envios?.length > 0 ? "Reenviar" : "Enviar"}</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            )}
        </>
    )
}