import React from "react"
import { View, Text, TouchableOpacity, ScrollView } from "react-native"
import { Dimensions } from "react-native"

import * as Clipboard from 'expo-clipboard'

import Icon from 'react-native-vector-icons/FontAwesome';

import Toast from 'react-native-root-toast'

export default function InputClipboard({ text }) {

    const copyToClipboard = () => {
        Clipboard.setString(text);

        Toast.show('Código copiado', {
            duration: 5000,
            hideOnPress: true,
            textStyle: { fontSize: 13, width: Dimensions.get('window').width * 0.8 },
            containerStyle: { marginBottom: 20 }
        });
    }

    return (
        <TouchableOpacity onPress={copyToClipboard}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ flex: 1, paddingHorizontal: 5, height: 30, backgroundColor: "#ffffff", borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}>
                    <ScrollView horizontal={true}>
                        <Text style={{ alignSelf: "center", fontSize: 11 }}>{text}</Text>
                    </ScrollView>
                </View>
                <View style={{ paddingHorizontal: 8, backgroundColor: "#02AB89", height: 30, alignItems: "center", justifyContent: "center", borderTopRightRadius: 5, borderBottomRightRadius: 5 }}>
                    <Icon name="clipboard" color="#ffffff" size={14} />
                </View>
            </View>
        </TouchableOpacity >
    )
}