import React from "react"
import { View } from "react-native"

import styles from './styles'

export function CardHeader({ children, style }) {
    return (
        <View style={{ paddingVertical: 10, ...style }}>
            {children}
        </View>
    )
}

export function CardBody({ children, style }) {
    return (
        <View style={{ paddingVertical: 10, ...style }}>
            {children}
        </View>
    )
}

export function CardFooter({ children, style }) {
    return (
        <View style={{ paddingVertical: 10, ...style }}>
            {children}
        </View>
    )
}

export default function Card({ children, style }) {
    return (
        <View style={{ ...styles.container, ...style }}>
            {children}
        </View>
    )
}