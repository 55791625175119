const moment = require("moment")

export const formatData = (dt, extenso = false) => {
    let data = new Date(dt)

    let dia = data.getDay()
    let mes = data.getMonth()
    let ano = data.getFullYear()

    if (dia < 10) dia = `0${dia}`

    let meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

    if (!extenso) return `${dia}/${mes < 10 ? `0${mes}` : mes}/${ano}`
    return `${dia} de ${meses[mes - 1]} de ${ano}`
}

export const formatDataHora = (datahora, format) => {
    return moment(datahora).format(format)
}

export const formatReal = (valor) => {
    if (typeof valor == "string") valor = +valor
    if (valor == 0) return "R$ 0,00"
    else if (!valor) return
    valor = valor + 0.0
    //return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    if (typeof valor === 'number') {
        const [currency, cents] = valor.toFixed(2).toString().split('.');

        return `R$ ${currency.replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${cents}`;
    }

    return 'R$ 0,00';
}

export const formatCPF = (cpf) => {
    if (!cpf || cpf.length != 11) return cpf
    return `${cpf[0]}${cpf[1]}${cpf[2]}.${cpf[3]}${cpf[4]}${cpf[5]}.${cpf[6]}${cpf[7]}${cpf[8]}-${cpf[9]}${cpf[10]}`
}

export const formatCelular = (numero) => {
    if (!numero) return numero
    let ddi = null
    if (numero.length > 11) {
        ddi = numero.substring(0, 2)
        numero = numero.replace(ddi, "")
    }
    let ddd = numero.substring(0, 2)
    let nove = numero.length == 11 ? numero.substring(2, 3) : ""
    let primeira = numero.length == 11 ? numero.substring(3, 7) : numero.substring(2, 6)
    let segunda = numero.length == 11 ? numero.substring(7, 11) : numero.substring(6, 10)

    return `${ddi ? `+${ddi} ` : ""}(${ddd}) ${nove}${primeira}-${segunda}`
}

export const formatCPFCNPJ = (doc) => {
    if (![11, 14].includes(doc.length))
        return doc

    if (doc.length == 11)
        return doc.substr(0, 3) + "." + doc.substr(3, 3) + "." + doc.substr(6, 3) + "-" + doc.substr(9, 2)
    return doc.substr(0, 2) + "." + doc.substr(2, 3) + "." + doc.substr(5, 3) + "/" + doc.substr(8, 4) + "-" + doc.substr(12, 2)
}

export const formatSexo = (sexo) => {
    if (sexo == "M") return "Masculino"
    if (sexo == "F") return "Feminino"
    if (sexo == "O") return "Outro"
    return "Não informado"
}

export const formatEndereco = ({ logradouro, numero, complemento, bairro, referencia, cidade, cep }) => {
    let endereco = `${logradouro}${numero ? `, ${numero}` : ""}${complemento ? `, ${complemento}` : ""}${bairro ? ` - ${bairro}` : ""}${cidade ? ` - ${cidade.nome} / ${cidade?.estado?.sigla}` : ""}${cep ? `, ${cep}` : ""}${referencia ? ` (Ref.: ${referencia})` : ""}`

    return endereco
}

export const formatDataHoraEtapa = (datahora_inicio, datahora_fim) => {
    let meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

    let diaInicio = moment(datahora_inicio).format("DD")
    let mesInicio = moment(datahora_inicio).format("MM")
    let anoInicio = moment(datahora_inicio).format("YYYY")

    let diaFim = moment(datahora_fim).format("DD")
    let mesFim = moment(datahora_fim).format("MM")
    let anoFim = moment(datahora_fim).format("YYYY")

    if (moment(datahora_inicio).format("DD/MM/YYYY") == moment(datahora_fim).format("DD/MM/YYYY"))
        return `${diaInicio} de ${meses[+mesInicio - 1]} de ${anoInicio}`

    if (moment(datahora_inicio).format("MM/YYYY") == moment(datahora_fim).format("MM/YYYY"))
        return `${diaInicio} a ${diaFim} de ${meses[+mesInicio - 1]} de ${anoInicio}`

    if (moment(datahora_inicio).format("YYYY") == moment(datahora_fim).format("YYYY"))
        return `${diaInicio} de ${meses[+mesFim - 1]} a ${diaFim} de ${meses[+mesInicio - 1]} de ${anoInicio}`

    return `${moment(datahora_inicio).format("DD/MM/YYYY")} a ${moment(datahora_fim).format("DD/MM/YYYY")}`
}

export const removerAcento = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

export const substituirVariaveisText = (text, obj) => {
    return text.split("${").map(part => {
        let findVariable = part.split("}")
        if (findVariable.length > 1) {
            findVariable[0] = obj?.[findVariable[0]] || findVariable[0]
        }
        return findVariable.join("")
    }).join("")
}