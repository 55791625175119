import React from "react"
import { View, Image, Animated, Easing } from "react-native"
import Icon from 'react-native-vector-icons/FontAwesome';

export default function Avatar({ uri, size = 32, loading = false, style, borderRadius = 100 }) {
    let spinValue = new Animated.Value(0)

    const spin = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
    })

    Animated.loop(
        Animated.timing(
            spinValue,
            {
                toValue: 1,
                duration: 3000,
                easing: Easing.linear, // Easing is an additional import from react-native
                useNativeDriver: true  // To make use of native driver for performance
            }
        )
    ).start()

    return (
        <View style={{ ...style }}>

            {loading ? <>
                <View style={{ width: size, height: size, borderRadius, backgroundColor: "#ffffff", justifyContent: "center", alignItems: "center" }}>
                    <Animated.View style={{ width: size, justifyContent: "center", alignItems: "center", alignSelf: "center", transform: [{ rotate: spin }] }} >
                        <Icon name="spinner" color="#c0c0c0" size={size * 0.7} />
                    </Animated.View>
                </View>
            </> :
                <>
                    {uri && <Image
                        style={{ width: size, height: size, borderRadius }}
                        source={{ uri }}
                    />}

                    {!uri && (
                        <View style={{ width: size, height: size, borderRadius, backgroundColor: "#ffffff", justifyContent: "center", alignItems: "center" }}>
                            <Icon name="user" color="#c0c0c0" size={size * 0.7} />
                        </View>
                    )}
                </>
            }
        </View>
    )
}