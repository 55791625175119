import React from "react"
import { View, Text } from "react-native"

import styles from "./styles"

export const THead = ({ children, style }) => {
    return (
        <View style={{ ...styles.thead, ...style }}>
            {typeof children == "string" ? <Text>{children}</Text> : children}
        </View>
    )
}

export const TBody = ({ children, style }) => {
    return (
        <View style={{ ...styles.tbody, ...style }}>
            {typeof children == "string" ? <Text>{children}</Text> : children}
        </View>
    )
}

export const TFooter = ({ children, style }) => {
    return (
        <View style={{ ...styles.tfooter, ...style }}>
            {typeof children == "string" ? <Text>{children}</Text> : children}
        </View>
    )
}

export const Tr = ({ children, style }) => {
    return (
        <View style={{ ...styles.tr, ...style }}>
            {typeof children == "string" ? <Text>{children}</Text> : children}
        </View>
    )
}

export const Td = ({ children, style, textStyle }) => {
    return (
        <View style={{ ...styles.td, ...style }}>
            {["string", "number"].includes(typeof children) ? <Text style={{ ...textStyle }}>{children}</Text > : children}
        </View >
    )
}

export default function Table({ children, style }) {
    return (
        <View styles={{ ...styles.table, ...style }}>
            {children}
        </View>
    )
}