import api from './api.service';

export async function getLotesComissarioEvento(evento_id = null) {
    try {
        let resultado = await api.get(`/eventos/lotes/comissarios/${evento_id}`)

        //console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}