import { StyleSheet } from "react-native";

export default StyleSheet.create({
    modal: {
        alignSelf: "center",
        position: "absolute",
        height: 430,
        width: '80%',
        backgroundColor: '#FFF',
        borderTopRightRadius: 13,
        borderTopLeftRadius: 13,
    },
    titleCardPayment:{
        fontSize: 24,
        lineHeight: 28,
        fontWeight: "bold",
        color: '#02AB89',
        marginTop: 17,
        marginLeft: 20,
    },
    textCardPayment:{
        fontSize: 14,
        lineHeight: 18,
        fontWeight: '300',
        color: '#02AB89',
        marginLeft: 20,
        marginRight: 8,
        marginTop: 10,
    },
    btns:{
        width: 155,
        height: 48,
        padding: 9,
        margin: 4,
        marginTop: 25,
    },
    input:{
        fontSize: 14,
        lineHeight: 20,
        borderBottomColor: '#02AB89',
        fontWeight: '200',
        borderRadius: 4,
        marginTop: 24,
        marginRight: 25,
        marginLeft: 25,
        height: 40,
        width: 160,
    },
    inputView:{
        alignSelf: "center",
        width: 160,
        borderBottomWidth: 2,
        borderBottomColor: '#02AB89',
        marginRight: 25,
        marginLeft: 25,
        flexDirection: "row",
    },
})