import React from "react"
import { View, Text } from "react-native"

import NumberOfTicketsResgate from "../../../components/NumberOfTicketsResgate";
import Button from '../../../components/Button';

import { formatReal } from "../../../helpers/format";

import styles from './styles';

export default function RCIngressos({ lotes, carrinho, setCarrinho, valorTotal, avancar, comissaoDisponivel }) {
    return (
        <View>
            <Text style={styles.title}>Resgate de Comissão</Text>
            <Text style={styles.titleInform}>Sua comissão será paga em ingressos conforme acertado antes, esolha suas opções.
                O saldo restante não poderá ser usado e nem completado para adquirir um ingresso.
                Após o resgate os ingressos estarão disponíveis em sua area no app iPass Cliente,
                e de lá você podera transferi-los.</Text>

            {lotes.sort((a, b) => a.setor_nome < b.setor_nome ? -1 : (a.setor_nome > b.setor_nome ? 1 : 0)).map((lote, i) => (
                <View key={i}>
                    {lotes.findIndex(l => l.setor_nome == lote.setor_nome) == i && <Text style={{ ...styles.ticketType, textTransform: "uppercase" }}>{lote.setor_nome}</Text>}
                    <View style={{ marginTop: 0, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <View>
                            <Text style={{ ...styles.ticketPrice, textTransform: "uppercase", marginTop: 5, fontSize: 13 }}>{lote.modalidade_string} - {lote.nome}</Text>
                            <Text style={{ ...styles.ticketPrice, textTransform: "uppercase", color: "#fff", marginTop: 5 }}>{`${formatReal(lote.valor)}`}</Text>
                        </View>
                        <NumberOfTicketsResgate lote={lote} carrinho={carrinho} setCarrinho={setCarrinho} />
                    </View>
                </View>
            ))}

            <Text style={styles.txtCommission}>Comissão: {formatReal(comissaoDisponivel)}</Text>
            <Text style={styles.totalValue}>TOTAL: {formatReal(valorTotal)}</Text>

            {(comissaoDisponivel - valorTotal) < 0 && <Text style={{ textAlign: "center", color: "tomato", fontWeight: "500", fontSize: 16 }}>Ultrapassou {formatReal(comissaoDisponivel - valorTotal).replace("-", "")}</Text>}

            <Button title="CONFIRMAR RESGATE" backgroundColor='#017781' style={styles.buttonWithdrawal} onPress={avancar} disabled={(comissaoDisponivel - valorTotal) < 0 || carrinho.length == 0} />

            <View style={{ marginTop: 80 }}></View>
        </View>
    )
}