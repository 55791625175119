import React, { useEffect, useContext, useState } from "react";
import { View, Text, TouchableOpacity, BackHandler } from "react-native";
import { CompraContext } from "../../../contexts/compra.context";
import { AppContext } from "../../../contexts/app.context";
import Icon from "react-native-vector-icons/FontAwesome";

import styles from "./styles";

import TicketCard from "../../TicketCard";
import ButtonsPayment from "../../ButtonsPayment";
import Button from "../../Button";
import { formatReal } from "../../../helpers/format";
import PIX from "../../PIX";
import PagamentoDinheiro from "../../PagamentoDinheiro";
import CartaoCredito from "../../CartaoCredito";
import { EventosContext } from "../../../contexts/eventos.context";

export default function TSConfirmacao({ evento }) {
  const {
    ingressosIndividuais,
    titulares,
    valorTotal,
    reservarIngressos,
    efetuarCompra,
    resetCompra,
    setStep,
    loadingCompra,
  } = useContext(CompraContext);

  const { eventoAberto } = useContext(EventosContext);

  const { modalBottomSmall, setModalBottomSmall, setModalBottom, setStatus } =
    useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState(null);

  useEffect(() => {
    const backAction = () => {
      if (modalBottomSmall) setModalBottomSmall(null);
      else setStep("titulares");
      return true;
    };

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, [modalBottomSmall]);

  useEffect(() => {
    console.log("MODIFICAÇÃO ERRO", erro);
  }, [erro]);

  const confirmar = async () => {
    try {
      setLoading(() => true);

      const { token } = await reservarIngressos(evento._id);
      await efetuarCompra(
        {
          meio_pagamento: "pix",
          parcelas: 1,
        },
        token
      );

      setStatus("success");

      setModalBottom(null);
      resetCompra();

      setLoading(() => false);
    } catch (error) {
      console.error(error);
      setErro(() => error?.message || "Ocorre um erro");
      setLoading(() => false);
    }
  };

  const concluir = () => {
    setModalBottom(null);
    setModalBottomSmall(null);
    resetCompra();
  };

  return (
    <View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TouchableOpacity
          onPress={() => setStep("titulares")}
          style={{ marginRight: 10 }}
        >
          <Icon name="chevron-left" size={24} />
        </TouchableOpacity>
        <Text style={{ ...styles.title, fontSize: 32 }}>Pagamento</Text>
      </View>

      <Text
        style={{
          fontSize: 15,
          color: "#ffffff",
          fontWeight: "700",
          marginTop: 5,
        }}
      >
        Confira atentamente os dados de todos os ingressos.
      </Text>

      <View>
        {ingressosIndividuais.map((ingresso, i) => (
          <View key={i} style={{ marginTop: 20 }}>
            <TicketCard
              ingresso={ingresso}
              titular={titulares[i]}
              evento={evento}
            />
          </View>
        ))}
      </View>

      {erro && (
        <Text
          style={{
            textAlign: "center",
            marginTop: 20,
            fontSize: 14,
            color: "tomato",
          }}
        >
          {erro}
        </Text>
      )}

      {valorTotal.pix.total > 0 && (
        <>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <ButtonsPayment
              style={{ width: "47.5%" }}
              onPress={() => {
                setModalBottomSmall(
                  <PIX
                    evento={evento}
                    eventoAberto={eventoAberto}
                    concluir={concluir}
                  />
                );
              }}
              paymentType="PIX"
              tax={`${formatReal(valorTotal.pix.valor)} + ${formatReal(
                valorTotal.pix.taxa
              )}`}
              imgLoc={require("../../../../assets/pix.png")}
            />

            <ButtonsPayment
              style={{ width: "47.5%" }}
              onPress={() => {
                setModalBottomSmall(
                  <CartaoCredito
                    evento={evento}
                    modo={
                      /*eventoAberto?.tipo == "comissario" ? null : "pagamento"*/ null
                    }
                  />
                );
              }}
              paymentType="Crédito"
              tax={`${formatReal(
                valorTotal["cartao-credito"].valor
              )} + ${formatReal(valorTotal["cartao-credito"].taxa)}`}
              imgLoc={require("../../../../assets/mastercard.png")}
            />
          </View>

          {evento.vendas_dinheiro == 1 && (
            <View style={{ marginTop: 10 }}>
              <Button
                onPress={() => {
                  setModalBottomSmall(<PagamentoDinheiro evento={evento} />);
                }}
                title={
                  <View style={{ alignItems: "center" }}>
                    <Text
                      style={{
                        fontSize: 20,
                        //fontFamily: 'Arial',
                        color: "#ffffff",
                      }}
                    >
                      Dinheiro
                    </Text>
                    <Text
                      style={{ color: "#ffffff", fontSize: 10 }}
                    >{`${formatReal(valorTotal.dinheiro.valor)} + ${formatReal(
                      valorTotal.dinheiro.taxa
                    )}`}</Text>
                  </View>
                }
                backgroundColor="#53D769"
              />
            </View>
          )}
        </>
      )}

      {valorTotal.pix.total == 0 && (
        <View style={{ marginVertical: 20 }}>
          <Button
            title={loading ? "Carregando..." : "Confirmar"}
            backgroundColor="#53D769"
            styleText={{ fontSize: 14 }}
            onPress={confirmar}
            disabled={loading}
          />
        </View>
      )}
    </View>
  );
}
