import React, { useContext, useEffect, useState } from 'react';

import styles, {
  AvatarBox,
  Box,
  BoxDataEvento,
  Container,
  ContainerCardEvento,
  ContentCardEvento,
  Header,
  Subtitle,
  Title,
  TitleEvento,
  UserImg,
} from './styles';

import Notifications from '../../components/Notifications';

import {
  Button,
  Dimensions,
  Image,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { AuthContext } from '../../contexts/auth.context';
import { EventosContext } from '../../contexts/eventos.context';
import Icon from 'react-native-vector-icons/FontAwesome';
import Loading from '../../components/Loading';

import { Popover, Button as Botao, Box as Caixa } from 'native-base';
import { adjustImage } from '../../utils/formatImg';

const Tab = createMaterialTopTabNavigator();

export default function Home({ navigation }) {
  const { pessoa, logout } = useContext(AuthContext);

  const foto =
    pessoa?.sexo === 'M' ? (
      <UserImg
        source={pessoa?.path_foto || require('../../../assets/user-male.png')}
      />
    ) : (
      <UserImg
        source={pessoa?.path_foto || require('../../../assets/user-female.png')}
      />
    );

  const handleLogout = async () => {
    try {
      await logout(navigation);
    } catch (error) { }
  };

  const formatApelido = ({ apelido, nome }) => {
    return (apelido || nome || "").split(" ")[0].trim()
  }

  return (
    <NavigationContainer independent={true}>
      <Container>
        <Header>
          <Subtitle>Olá, {formatApelido(pessoa || {})}{/* 🖐*/}</Subtitle>
          <Box>
            <Notifications navigation={navigation} color="white" />
            {/* <AvatarBox>{foto}</AvatarBox> */}
            <Popover
              trigger={(triggerProps) => {
                return <AvatarBox {...triggerProps}>{foto}</AvatarBox>;
              }}
            >
              <Popover.Content accessibilityLabel="Opções de usuário" w="56">
                <Popover.Arrow />
                <Popover.Body>
                  <Botao colorScheme="danger" onPress={handleLogout}>
                    Sair
                  </Botao>
                </Popover.Body>
              </Popover.Content>
            </Popover>
          </Box>
        </Header>

        <Title>Eventos</Title>

        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <View
            style={{
              flex: 1,
              paddingBottom: 0,
              paddingHorizontal: 20,
              width: 800,
              maxWidth: Dimensions.get('window').width
            }}
          >
            <Tab.Navigator
              screenOptions={styles.tabBar}
              sceneContainerStyle={{
                backgroundColor: '#f6f6f7',
              }}
              barStyle={{ backgroundColor: 'blue' }}
            >
              <Tab.Screen name="Ativos">
                {() => <HomeScreen nav={navigation} tipo="Ativos" />}
              </Tab.Screen>
              <Tab.Screen name="Antigos">
                {() => <HomeScreen nav={navigation} tipo="Antigos" />}
              </Tab.Screen>
            </Tab.Navigator>
          </View>
        </View>
      </Container>
    </NavigationContainer>
  );
}

function HomeScreen({ nav, tipo }) {
  const { loadingEventos, eventos, setEventoAberto, buscarEventos } =
    useContext(EventosContext);

  const [pesquisa, setPesquisa] = useState('');

  useEffect(() => {
    buscarEventos();
  }, []);

  const handleClickEvento = (evento) => {
    setEventoAberto(evento);
    nav.navigate('Event', { evento });
  };

  const eventosAtivos = eventos.filter((evento) => !evento?.evento?.finalizado && evento?.status == 1);
  const eventosAntigos = eventos.filter((evento) => evento?.evento?.finalizado || evento?.status != 1);

  const handleClickRefresh = () => {
    setPesquisa('');
    buscarEventos();
  };

  return (
    <View style={{ backgroundColor: '#f6f6f7' }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            // border: '1px solid #000'
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              marginVertical: 16,
              alignItems: 'center',
              width: "100%"
            }}
          >
            <TextInput
              style={{
                height: 40,
                borderColor: 'gray',
                borderWidth: 1,
                borderRadius: 5,
                padding: 10,
                flex: 1,
                marginRight: 10,
              }}
              placeholder="Pesquisar"
              onChangeText={(text) => setPesquisa(text)}
              value={pesquisa}
            />
            <TouchableOpacity onPress={handleClickRefresh}>
              <Icon name="refresh" color="#02AB89" size={24} />
            </TouchableOpacity>
          </View>

          {loadingEventos && <Loading />}
          {!loadingEventos &&
            (tipo === 'Ativos' ? eventosAtivos : eventosAntigos)
              .filter((evento) =>
                evento.evento.nome
                  .toLowerCase()
                  .includes(pesquisa.toLowerCase())
              )
              .map((evento) => (
                <EventoCard
                  key={evento._id}
                  evento={evento}
                  handleClickEvento={handleClickEvento}
                />
              ))
          }
        </View>
      </ScrollView>
    </View>
  );
}

function EventoCard({ evento, handleClickEvento }) {
  const getInitialDate = (etapas) => {
    etapas.sort((a, b) => {
      return new Date(a.datahora_inicio) - new Date(b.datahora_inicio);
    });

    const data = new Date(etapas[0].datahora_inicio);

    const meses = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ];

    return (
      (data.getDate() < 10 ? '0' + data.getDate() : data.getDate()) +
      ' de ' +
      meses[data.getMonth()] +
      '. de ' +
      data.getFullYear()
    );
  };

  return (
    <ContainerCardEvento
      style={{
        elevation: 5,
      }}
      key={evento._id}
      onPress={() => handleClickEvento(evento)}
    >
      <Image
        source={{ uri: adjustImage(evento.evento.path_imagem) }}
        style={{ width: '100%', height: 150, borderRadius: 16 }}
      />
      <BoxDataEvento
        style={{
          position: 'absolute',
          left: 26,
          top: 120,
        }}
      >
        <Text>{getInitialDate(evento.evento.etapas)}</Text>
      </BoxDataEvento>
      <ContentCardEvento>
        <View>
          <TitleEvento>{evento.evento.nome}</TitleEvento>
          <Text>
            {evento.evento.urn ? evento.evento.urn : 'URN não informada'}
          </Text>
        </View>
        <Icon name="angle-right" color={'#000'} size={22} />
      </ContentCardEvento>
    </ContainerCardEvento>
  );
}
