import React, { useState, useEffect, useContext } from "react"
import { ScrollView, View, Text, TouchableOpacity } from "react-native"
import Card, { CardBody } from "../../components/Card"
import Loading from "../../components/Loading"

import ResumoComissao from "../../components/ResumoComissao"
import { formatReal } from "../../helpers/format"
import ResgateCard from "./ResgateCard"

import { getResgates } from "../../services/resgates.service"
import { EventosContext } from "../../contexts/eventos.context"
import EmBreve from "../EmBreve"

export default function Resgates({ navigation }) {
    const { eventoAberto } = useContext(EventosContext)

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [resgates, setResgates] = useState([])
    const [comissario, setComissario] = useState(null)

    const [emBreve, setEmBreve] = useState(true)

    useEffect(() => {
        navigation.addListener('focus', () => {
            buscarResgates()
        });
    }, [])

    const buscarResgates = async () => {
        try {
            setLoading(() => true)

            let resultado = await getResgates(eventoAberto.evento._id)

            setResgates(resultado.resgates)
            setComissario(resultado.comissario)

            setLoading(() => false)
        } catch (error) {
            console.error(error)
            setError(() => error?.message || "Ocorreu um erro")
            setLoading(() => false)
        }
    }

    if (emBreve)
        return <EmBreve />

    return (
        <View style={{ flex: 1, flexDirection: "column" }}>
            {loading ?
                <View style={{ marginTop: 40 }}>
                    <Loading />
                </View>
                :
                <>
                    {error ?
                        <Text style={{ textAlign: "center", marginTop: 20, fontSize: 14 }}>{error}</Text>
                        : <>
                            <ScrollView style={{ flex: 1, paddingHorizontal: 20 }}>
                                {resgates.length == 0 && <Text style={{ textAlign: "center", marginTop: 20, fontSize: 14 }}>Nenhum resgate até o momento</Text>}
                                {resgates.map((resgate, i) => (
                                    <ResgateCard key={i} resgate={resgate} />
                                ))}

                                <View style={{ marginTop: 20 }}></View>
                            </ScrollView>

                            <ResumoComissao
                                valor_comissao={+comissario?.credito}
                                disponivel={+comissario?.credito > 0}
                                comissario={comissario}
                                refresh={buscarResgates}
                            />
                        </>
                    }
                </>
            }
        </View>
    )
}