import React, { useContext } from 'react';

import Login from '../pages/Login';
import Home from '../pages/Home';
import TicketData from '../pages/TicketData';
import Payment from '../pages/Payment';
import CardPayment from '../pages/CardPayment';
import TicketsSold from '../pages/TicketsSold';
import SalesAndCapture from '../pages/SalesAndCapture';
import Reports from '../pages/Reports';
import Event from '../pages/Event';
import RecoveryPassword from '../pages/RecoveryPassword'
import Load from '../pages/Load'
import BlockApp from '../pages/BlockApp'

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Entypeicons from 'react-native-vector-icons/Entypo';

import { EventosContext } from '../contexts/eventos.context';
import Evento from '../pages/Evento';
import Resgates from '../pages/Resgates';
import NotificationsPage from '../pages/NotificationsPage';


export default function Routes() {

  const Stack = createNativeStackNavigator();
  function TabStack() {
    const { eventoAberto } = useContext(EventosContext)

    return (
      <Stack.Navigator initialRouteName="Load" navigation>
        <Stack.Screen name="Load"
          component={Load}
          options={{
            headerShown: false,
            gestureEnabled: false,
            headerTitle: ""
          }}
        />
        <Stack.Screen name="BlockApp"
          component={BlockApp}
          options={{
            headerShown: false,
            gestureEnabled: false,
            headerTitle: ""
          }}
        />
        <Stack.Screen name="Login"
          component={Login}
          options={{
            headerShown: false,
            gestureEnabled: false,
            headerTitle: ""
          }}
        />
        <Stack.Screen name="RecoveryPassword"
          component={RecoveryPassword}
          options={{
            headerTitleStyle: { color: "transparent" },
            headerStyle: { backgroundColor: "#02AB89" },
            headerTintColor: "#FFF",
            headerBackTitle: 'Voltar',
            headerTitle: ""
          }}
        />
        <Stack.Screen name="Home"
          component={Home}
          options={{
            headerShown: false,
            gestureEnabled: false,
            headerTitle: "",
            headerStyle: { backgroundColor: "#02AB89" },
          }}
        />
        <Stack.Screen name="Dados do ingresso"
          component={TicketData}
          options={{
            headerTitleStyle: { color: "transparent" },
            headerStyle: { backgroundColor: "#02AB89" },
            headerTintColor: "#FFF"
          }}
        />
        <Stack.Screen name="Pagamento"
          component={Payment}
          options={{
            headerTitleStyle: { color: "transparent" },
            headerStyle: { backgroundColor: "#02AB89" },
            headerTintColor: "#FFF"
          }}
        />
        <Stack.Screen name="Pagamento com cartão"
          component={CardPayment}
          options={{
            headerTitleStyle: { color: "transparent" },
            headerStyle: { backgroundColor: "#02AB89" },
            headerTintColor: "#FFF"
          }}
        />
        <Stack.Screen name="Event"
          component={MyTabs}
          options={{
            headerTitleStyle: { color: "#ffffff" },
            headerStyle: { backgroundColor: "#02AB89" },
            headerTintColor: "#FFF",
            headerBackTitle: 'Voltar',
            headerTitle: eventoAberto?.evento?.nome
          }}
        />
        <Stack.Screen name="Notifications"
          component={NotificationsPage}
          options={{
            headerTitleStyle: { color: "#ffffff" },
            headerStyle: { backgroundColor: "#02AB89" },
            headerTintColor: "#FFF",
            headerBackTitle: 'Voltar',
            headerTitle: ""
          }}
        />
        <Stack.Screen name="Ingressos vendidos"
          component={TicketsSold}
          options={{
            headerTitleStyle: { color: "transparent" },
            headerStyle: { backgroundColor: "#02AB89" },
            headerTintColor: "#FFF"
          }}
        />
        <Stack.Screen name="Vendas e captações"
          component={SalesAndCapture}
          options={{
            headerTitleStyle: { color: "transparent" },
            headerStyle: { backgroundColor: "#02AB89" },
            headerTintColor: "#FFF"
          }}
        />
        <Stack.Screen name="Relatórios"
          component={Reports}
          options={{
            headerTitleStyle: { color: "transparent" },
            headerStyle: { backgroundColor: "#02AB89" },
            headerTintColor: "#FFF"
          }}
        />
        <Stack.Screen name="Resgate"
          component={Resgates}
          options={{
            headerTitleStyle: { color: "transparent" },
            headerStyle: { backgroundColor: "#02AB89" },
            headerTintColor: "#FFF"
          }}
        />
      </Stack.Navigator>
    );
  }

  const Tab = createBottomTabNavigator();
  function MyTabs({ navigation }) {

    return (
      <Tab.Navigator screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;

          if (route.name === 'Evento') {
            iconName = focused
              ? 'tablet'
              : 'tablet';
          } else if (route.name === 'Resgate') {
            iconName = focused ? 'mail' : 'mail';
          } else if (route.name === 'Ingressos') {
            iconName = focused ? 'ticket' : 'ticket';
          } else if (route.name === 'Relatórios') {
            iconName = focused ? 'bar-graph' : 'bar-graph';
          }
          return <Entypeicons name={iconName} size={size} color={color} />;
        },
        tabBarActiveTintColor: '#02AB89',
        tabBarInactiveTintColor: 'gray'
      })}
      >
        <Tab.Screen name="Evento"
          component={Evento}
          options={{
            headerTitleStyle: { color: "transparent" },
            headerShown: false
          }}
        />
        <Tab.Screen name="Resgate"
          component={Resgates}
          options={{ headerShown: false }}
        />
        <Tab.Screen name="Ingressos"
          component={SalesAndCapture}
          options={{
            headerTitleStyle: { color: "transparent" },
            headerStyle: { backgroundColor: "#02AB89" },
            headerShown: false
          }}
        />
        <Tab.Screen name="Relatórios"
          component={Reports}
          options={{ headerShown: false }}
        />
      </Tab.Navigator>
    );
  }
  return (
    <TabStack />
  );
}