import React from 'react';
import { View, Text} from 'react-native';
import Button from '../Button';
import Input from '../Input';
import styles from './styles';

export default function ModalLinkPayment(){

    return(
        <View style={styles.modal}>
            <Text style={styles.titleCardPayment}>Link de pagamento</Text>
            <Text style={styles.textCardPayment}>O cliente receberá um link de pagamento via WhatsApp e terá 24h para realizar o pagamento. 
                                                Os ingressos serão enviados para os clientes também via WhatsApp e você poderá ver os 
                                                ingressos aqui no app em INGRESSOS VENDIDOS assim que o pagamento for realizado.</Text>
            
                <View style={styles.inputView}>
                    <Input style={styles.input} placeholder="WhatsApp"/>
                </View>
                <Button style={styles.btns} icon="whatsapp" title="Enviar Link" backgroundColor="#53D769"/>
        </View>
    )
}