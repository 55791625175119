import React, { useContext, useState, useRef } from "react"

import { View, Text, TouchableOpacity, Linking } from "react-native"
import { AppContext } from "../../../contexts/app.context"

import { CompraContext } from '../../../contexts/compra.context'
import { WebSocketContext } from "../../../contexts/websocket.context"

import { Form } from '@unform/mobile'

import Button from "../../Button"
import Loading from "../../Loading"
import Input from "../../Input"
import { formatReal } from "../../../helpers/format"

import Icon from 'react-native-vector-icons/FontAwesome';

import * as Yup from 'yup'

export default function CartaoCreditoLink({ evento, voltar }) {
    const formRef = useRef()

    const { onSocket } = useContext(WebSocketContext)
    const { titulares, valorTotal, resetCompra, reservarIngressos } = useContext(CompraContext)
    const { setModal, setModalBottom, setModalBottomSmall, setStatus } = useContext(AppContext)

    const [loading, setLoading] = useState(false)
    const [qrcode, setQrcode] = useState(null)
    const [erro, setErro] = useState(null)

    const [gerado, setGerado] = useState(false)

    const [zoom, setZoom] = useState(false)

    const [linkPagamento, setLinkPagamento] = useState(null)

    const onSubmit = async (data) => {
        try {
            setLoading(() => true)

            setErro(() => true)

            //console.log("onSubmit", data)

            formRef.current.setErrors({})

            const schema = Yup.object().shape({
                celular: Yup.string().required("Campo obrigatório"),
            })

            await schema.validate(data, {
                abortEarly: false
            })

            const { celular } = data

            let link = null
            if (linkPagamento) {
                link = linkPagamento
                //console.log(linkPagamento)
            } else {
                const { link_pagamento } = await reservarIngressos(evento._id)
                link = link_pagamento
                setLinkPagamento(link)
            }

            let mensagem = `Olá! O link de pagamento da sua compra de *${titulares.length} ${titulares.length > 1 ? `ingressos` : "ingresso"}* para o evento *${evento.nome?.toUpperCase()}* é ${link}.`

            Linking.openURL(`whatsapp://send?text=${mensagem}&phone=55${celular}`)
                .then(() => {
                    setStatus("warning")
                    setModal(() => null)
                    setModalBottom(() => null)
                    //setModalBottomSmall(() => null)
                    resetCompra()
                })
                .catch(error => {
                    console.error(error)
                    setErro(() => "Ocorreu um erro ao abrir o WhatsApp")
                })

            setLoading(() => false)
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {}

                error.inner.forEach(err => {
                    errorMessages[err.path] = err.message
                })

                formRef.current.setErrors(errorMessages)
            } else {
                setErro(() => error?.message || "Ocorreu um erro")
            }

            setLoading(() => false)
        }
    }

    return (
        <View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity onPress={voltar} style={{ marginRight: 10 }}>
                    <Icon name="chevron-left" size={24} />
                </TouchableOpacity>
                <Text style={{ fontSize: 24, color: "#02AB89", fontWeight: "500" }}>Link de pagamento</Text>
            </View>

            {loading && (
                <View style={{ marginTop: 30 }}>
                    <Loading text="Gerando link de pagamento" />
                </View>
            )}

            <View style={loading ? { height: 0, opacity: 0 } : {}}>
                <Text style={{ marginTop: 10, color: "#02AB89", fontSize: 14 }}>O cliente receberá um link de pagamento via WhatsApp e terá 15 minutos para realizar o pagamento. Você poderá ver os ingressos aqui no app em INGRESSOS VENDIDOS assim que o pagamento for realizado.</Text>

                <Form ref={formRef} onSubmit={onSubmit}>
                    <Input name="celular" mask="cel-phone" placeholder="WhatsApp" disabled={loading} color="#02AB89" icon="whatsapp" />
                </Form>

                {erro && <Text style={{ fontSize: 14, textAlign: "center", marginTop: 20, color: "tomato" }}>{erro}</Text>}

                <Button title={`Enviar Link - ${formatReal(valorTotal["cartao-credito"].total)}`} backgroundColor="#53D769" style={{ marginTop: 40 }} styleText={{ fontSize: 16 }} onPress={() => formRef.current.submitForm()} />
            </View>
        </View>
    )
}