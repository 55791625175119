import React from "react"
import { View } from "react-native"
import SvgQRCode from 'react-native-qrcode-svg';
import Loading from "../Loading";

export default function QRCode({ ...rest }) {
    return (
        <>
            {rest.loading ?
                <View style={{ width: rest.size, height: rest.size, justifyContent: "center", alignItems: "center" }}>
                    <Loading size={rest.size * 0.3} />
                </View>
                :
                <SvgQRCode {...rest} />
            }
        </>
    )
}