import React, { useState, useContext, useEffect } from 'react';
import { View, Text } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { CompraContext } from '../../contexts/compra.context';
import Button from '../Button';
import styles from './styles';

export default function NumberOfTickets({ lote }) {
  const { carrinho, setCarrinho, valorTotal, setorSelecionado } =
    useContext(CompraContext);

  const [number, setNumber] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    update();
  }, []);

  useEffect(() => {
    update();
  }, [carrinho]);

  const update = () => {
    let busca = carrinho.filter((i) => i._id == lote?._id);
    if (busca.length > 0) {
      busca = busca[0];
      setNumber(() => busca.quantidade);
    } else setNumber(0);
  };

  const add = () => {
    setError(() => null);

    if (lote?.compra_maxima || lote?.compra_minima) {
      let quantidade = 0;
      let busca = carrinho.filter((i) => i._id == lote._id);
      if (busca.length > 0) {
        busca = busca[0];
        quantidade = busca.quantidade;

        if (lote?.compra_maxima && lote.compra_maxima <= quantidade) {
          setError(() => `Compra máxima: ${lote.compra_maxima}`);
          return;
        }

        if (lote.compra_minima && busca.quantidade + 1 < lote.compra_minima) {
          setError(() => `Compra mínima: ${lote.compra_minima}`);
        }
      } else if (lote?.compra_minima > 1) {
        setError(() => `Compra mínima: ${lote.compra_minima}`);
      }
    }

    if (carrinho.filter((i) => i._id == lote._id).length > 0) {
      let newCarrinho = carrinho.map((i) => {
        if (i._id == lote._id) {
          i.quantidade++;
        }
        return i;
      });
      setCarrinho(() => newCarrinho);
    } else {
      let newCarrinho = [...carrinho];
      newCarrinho.push({
        ...lote,
        setor_nome: setorSelecionado.nome,
        evento_etapa_id: setorSelecionado.evento_etapa,
        quantidade: 1,
      });
      setCarrinho(() => newCarrinho);
    }
  };

  const sub = () => {
    setError(() => null);

    if (lote?.compra_minima > 1) {
      let busca = carrinho.filter((i) => i._id == lote._id);
      if (busca.length > 0) {
        busca = busca[0];

        if (busca.quantidade > 1 && busca.quantidade - 1 < lote.compra_minima) {
          setError(() => `Compra mínima: ${lote.compra_minima}`);
        }
      }
    }

    setCarrinho((carrinho) => {
      let busca = carrinho.filter((i) => i._id == lote._id);
      if (busca.length > 0) {
        busca = busca[0];

        if (busca.quantidade <= 0) return carrinho;

        busca.quantidade--;

        if (busca.quantidade <= 0)
          return carrinho.filter((i) => i._id != lote._id);

        return [...carrinho.filter((i) => i._id != lote._id), busca];
      } else {
        return [
          ...carrinho,
          {
            ...lote,
            quantidade: 0,
          },
        ];
      }
    });
  };

  return (
    <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
      <View style={styles.rowView}>
        <Button
          onPress={() => sub()}
          style={{ ...styles.btns, marginRight: 5 }}
          title={<Icon name="minus" color="#ffffff" size={12} />}
          backgroundColor="#00535A"
          disabled={number == 0}
        />

        <View style={styles.viewNumber}>
          <Text style={styles.txtNumber}>{number}</Text>
        </View>

        <Button
          onPress={() => add()}
          style={{ ...styles.btns, marginLeft: 5 }}
          title={<Icon name="plus" color="#ffffff" size={12} />}
          backgroundColor="#00535A"
        />
      </View>
      {error && (
        <Text style={{ fontSize: 11, color: 'tomato', textAlign: 'center' }}>
          {error}
        </Text>
      )}
    </View>
  );
}
