import { StyleSheet } from "react-native";

export default StyleSheet.create({
    view:{
        flex: 1,
        backgroundColor: '#53D769',
        alignItems: "center",
    },
    viewError:{
        flex: 1,
        backgroundColor: '#C14545',
        alignItems: "center",
    },
    viewWarn:{
        flex: 1,
        backgroundColor: '#F6D654',
        alignItems: "center",
    },
    icons:{
        marginTop: 150,
        marginBottom: 20,
    },
    txtError: {
        color: "#FFF",
        fontSize: 24,
        fontWeight: '700',
    },
    txtWarn: {
        margin: 8,
        textAlign: "center",
        color: "#FFF",
        fontSize: 24,
        fontWeight: '700',
    }
})