import React, { useContext } from "react"
import { ScrollView, View, TouchableOpacity } from "react-native"
import { AppContext } from "../../contexts/app.context"

import styles from "./styles"

export default function ModalBottom({ style }) {
    const { modalBottom, setModalBottom } = useContext(AppContext)

    const handleClose = async () => {
        if (modalBottom?.beforeClose)
            await modalBottom.beforeClose()

        if (modalBottom?.onClose)
            modalBottom.onClose()

        setModalBottom(null)
    }

    return (
        <>
            {modalBottom && (
                <View style={{ ...styles.container }}>
                    <TouchableOpacity style={styles.exit} onPress={handleClose}></TouchableOpacity>
                    <ScrollView style={{ ...styles.modal, ...style }}>
                        {modalBottom?.component || modalBottom}
                    </ScrollView>
                </ View>
            )}
        </>
    )
}