import api from './api.service';

export async function login(login, senha) {
    try {
        //console.log("login", login, senha)

        let resultado = await api.post('/auth/sessao/login', {
            login,
            senha,
            tipo: 'EMAIL',
        })

        //console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}

export async function load() {
    try {
        let resultado = await api.get('/pessoas/sessao')

        //console.log("RESULTADO LOAD", resultado)

        return resultado.data
    } catch (error) {
        //if (error.data) //console.error(error)
        throw error?.data || error
    }
}

export async function loadUsuario() {
    try {
        let resultado = await api.get('/auth/sessao')

        //console.log("RESULTADO LOAD", resultado)

        return resultado.data
    } catch (error) {
        //if (error.data) //console.error(error)
        throw error?.data || error
    }
}

export async function alterarSenha(senha_atual, senha) {
    try {
        let resultado = await api.post('/auth/usuario/alterar-senha', { senha_atual, senha })

        //console.log("RESULTADO LOAD", resultado)

        return resultado.data
    } catch (error) {
        //if (error.data) //console.error(error)
        throw error?.data || error
    }
}

export async function solicitarRecuperacaoSenha(login, tipo) {
    try {
        let resultado = await api.post('/auth/recuperacao', { login, tipo })

        //console.log("RESULTADO LOAD", resultado)

        return resultado.data
    } catch (error) {
        //if (error.data) //console.error(error)
        throw error?.data || error
    }
}

export async function cadastrar(login, senha, tipo) {
    try {
        let resultado = await api.post('/auth/usuario', { login, senha, tipo })

        //console.log("RESULTADO LOAD", resultado)

        return resultado.data
    } catch (error) {
        //if (error.data) //console.error(error)
        throw error?.data || error
    }
}