import React, { useState, createContext, useEffect, startTransition } from 'react';
import { getConteudo } from '../services/configuracoes.service';

export const AppContext = createContext({})

export const AppProvider = (props) => {
    const [modalBottom, setModalBottom] = useState(null)
    const [modal, setModal] = useState(null)
    const [modalBottomSmall, setModalBottomSmall] = useState(null)
    const [status, setStatus2] = useState(null)
    const [mensagemEnvioIngressoComissario, setMensagemEnvioIngressoComissario] = useState("")

    const setStatus = (prop) => {
        setStatus2(() => typeof prop == "string" ? { type: prop } : { ...prop })
    }

    useEffect(() => {
        start()
    }, [])

    const start = async () => {
        let mensagem = "Olá! Obrigado por comprar no iPass.\n\nO ingresso do seu pedido já está disponível no app ou no site do iPass, e também pode ser acessado pelo link: https://ipass.com.br/api/ticket/${_id}\n\nVocê deve apresentá-lo no momento do evento.\n\nObrigado!"
        try {
            let { conteudo } = await getConteudo("mensagem-envio-ingresso-comissario")

            mensagem = conteudo
        } catch (error) { }

        setMensagemEnvioIngressoComissario(mensagem)
    }

    return (
        <AppContext.Provider value={{
            modalBottom, setModalBottom,
            modalBottomSmall, setModalBottomSmall,
            modal, setModal,
            status, setStatus,
            mensagemEnvioIngressoComissario
        }}>
            {props.children}
        </AppContext.Provider>
    )
}