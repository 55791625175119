import React, { useEffect, useState, createContext, useRef } from 'react'
import { Dimensions } from "react-native"

import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications'

export const NotificationsContext = createContext({})

import Toast from 'react-native-root-toast'

export const NotificationsProvider = (props) => {
    const [expoPushToken, setExpoPushToken] = useState('');
    const [notification, setNotification] = useState(false);
    const [notificationMaisRecente, setNotificationMaisRecente] = useState(null);
    const notificationListener = useRef();
    const responseListener = useRef();

    useEffect(() => {
        if (notificationMaisRecente) {
            Toast.show('Chegou uma nova notificação', {
                duration: 5000,
                hideOnPress: true,
                textStyle: { fontSize: 13, width: Dimensions.get('window').width * 0.8 },
                containerStyle: { marginBottom: 20 }
            });
        }
    }, [notificationMaisRecente])

    useEffect(() => {
        registerForPushNotificationsAsync().then(token => {
            setExpoPushToken(token)
        });

        // This listener is fired whenever a notification is received while the app is foregrounded
        notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
            console.log(notification)
            setNotification(notification);
            setNotificationMaisRecente(notification)
        });

        // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
        responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
            //console.log("CHEGOU NOTIFICAÇÃO")
            //console.log(response);
            setNotificationMaisRecente(response)
        });

        return () => {
            Notifications.removeNotificationSubscription(notificationListener.current);
            Notifications.removeNotificationSubscription(responseListener.current);
        };
    }, []);

    async function registerForPushNotificationsAsync() {
        let token;
        if (Constants.isDevice) {
            const { status: existingStatus } = await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
                const { status } = await Notifications.requestPermissionsAsync();
                finalStatus = status;
            }
            /*if (finalStatus !== 'granted') {
                alert('Failed to get push token for push notification!');
                return;
            }*/
            token = (await Notifications.getExpoPushTokenAsync()).data;
            //console.log(token);
        }/* else {
            alert('Must use physical device for Push Notifications');
        }*/

        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            });
        }

        return token;
    }

    return (
        <NotificationsContext.Provider value={{
            expoPushToken,
            notificationMaisRecente
        }}>
            {props.children}
        </NotificationsContext.Provider>
    )
}