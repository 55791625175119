import api from './api.service';

export async function getEvento(evento_id) {
    try {
        console.log("{ getEvento }", evento_id)
        let resultado = await api.get(`/eventos/${evento_id}`)

        //console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}

export async function getEventos() {
    try {
        console.log("{ getEventos }")
        let resultado = await api.get('/eventos/comissarios/eventos')

        //console.log("RESULTADO", resultado)

        return resultado.data
    } catch (error) {
        throw error?.data || error
    }
}