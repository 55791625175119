import React, { useEffect, useState, createContext, useContext } from "react";

import { getEventos } from "../services/eventos.service";
import { AuthContext } from "./auth.context";

export const EventosContext = createContext({});

export const EventosProvider = (props) => {
  const { usuario } = useContext(AuthContext);

  const [eventoAberto, setEventoAberto] = useState(null);

  const [eventos, setEventos] = useState([]);
  const [loadingEventos, setLoadingEventos] = useState(false);

  useEffect(() => {
    setEventos(() => []);
    start();
  }, [usuario]);

  const start = async () => {
    buscarEventos();
  };

  const buscarEventos = async () => {
    try {
      setLoadingEventos(true);

      let resultado = await getEventos();
      console.log("resultado", resultado);
      setEventos(() => resultado);

      // console.log("== TAXAS", resultado.map(res => res?.evento?.taxas))

      setLoadingEventos(false);
    } catch (error) {
      setLoadingEventos(false);
      console.warn("error", error);
      throw error;
    }
  };

  return (
    <EventosContext.Provider
      value={{
        eventoAberto,
        setEventoAberto,
        eventos,
        setEventos,
        loadingEventos,
        start,
        buscarEventos,
      }}
    >
      {props.children}
    </EventosContext.Provider>
  );
};
