import { StyleSheet } from "react-native";

export default StyleSheet.create({
    rowView: {
        flexDirection: "row",
    },
    btns: {
        width: 30,
        height: 30,
        borderRadius: 20,
        borderWidth: 2,
        borderColor: "#53D769",
        justifyContent: "center",
        alignItems: "center"
    },
    viewNumber: {
        width: 30,
        height: 30,
        backgroundColor: '#FFF',
        borderRadius: 20,
        alignItems: "center",
    },
    txtNumber: {
        fontWeight: 'bold',
        fontSize: 18,
        padding: 3,
    }
})
