import { StyleSheet } from "react-native";

export default StyleSheet.create({
    titlePayment:{
        fontSize: 36,
        lineHeight: 43,
        fontWeight: "bold",
        color: '#fff',
        marginTop: 17,
        marginLeft: 20,
    },
    titleInformPayment:{
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "500",
        color: '#fff',
        marginLeft: 20,
        marginBottom: 10,
    },
    textInformPayment:{
        fontSize: 14,
        lineHeight: 20,
        fontWeight: '200',
        color: '#fff',
        marginLeft: 20,
        marginRight: 8,
    },
    textInformPayment2:{
        fontSize: 14,
        lineHeight: 20,
        fontWeight: '300',
        color: '#fff',
        marginLeft: 20,
        marginRight: 8,
    },
    titleDataNewOwner:{
        fontSize: 18,
        lineHeight: 21,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 14,
        marginLeft: 20,
        marginBottom: 13,
    },
    viewRow:{
        marginTop: 50,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    viewCenter:{
        alignItems: "center",
    },
    ticketCard:{
        marginLeft: 20,
    }
})