import React, { useState, useEffect, useRef, useCallback, memo } from 'react'

import { useField } from '@unform/core'

import { View, Text, TextInput, TouchableOpacity } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import Picker from "../Picker";
import CurrencyInput from 'react-native-currency-input';

import Icon from 'react-native-vector-icons/FontAwesome'

import styles from './styles'

const Input = ({ icon, name, color = "#ffffff", secondaryColor = "#02AB89", styleError, mask, label, type, defaultValue, customImput = false, style, rawText: rText, setRawText: setRText, disabled, ...rest }) => {
    const inputRef = useRef()
    const { fieldName, registerField, error } = useField(name)

    const [text, setText] = useState(type == "money" ? null : '');
    const [rawText, setRawText] = useState('');
    const handleChangeText = useCallback((maskedText, unmaskedText) => {
        //console.log("unmaskedText", unmaskedText)
        setText(maskedText);
        setRawText(unmaskedText);
        //inputRef.current.value = unmaskedText
        if (rest.onChange)
            rest.onChange(unmaskedText)
    }, []);

    const [checked, setChecked] = useState(() => (defaultValue && typeof defaultValue == "bool") ? defaultValue : false)

    const [selected, setSelected] = useState(() => defaultValue || null)

    useEffect(() => {
        //console.log("useEffect Input", name)
    }, [])

    useEffect(() => {
        //console.log("useEffect", defaultValue)
        if (defaultValue) {
            if (type == "checkbox") {
                setChecked(() => defaultValue)
            } else if (type == "select") {
                //console.log("useEffect", defaultValue)
                setSelected(() => defaultValue)
            } else {
                inputRef.current.value = defaultValue
                setText(defaultValue)
                setRawText(defaultValue)
            }
        }
    }, [defaultValue])

    useEffect(() => {
        if (type == "select") {
            //console.log("useEffect select", selected, defaultValue)
        }
    }, [selected])

    useEffect(() => {
        if (!(mask && !customImput)) {
            registerField({
                name: fieldName,
                ref: inputRef,
                getValue: ref => {
                    //console.log("text", text)
                    if (mask) return rText
                    if (type == "checkbox") return checked
                    if (type == "select") return selected
                    //if (type == "money") return text || 0
                    return ref.current.value || ""
                },
                setValue: (ref, value) => {
                    //console.log("setvalue", name)
                    ref.current.value = value
                    setRawText(value)
                    setText(value)
                },
                clearValue: ref => {
                    //console.log("CLEAR VALUE", name)
                    ref.current.value = ''
                    if (setRText)
                        setRText("")
                    setRawText("")
                    setText("")
                    defaultValue = ""
                },
            })
        }
    }, [fieldName, registerField, checked, selected])

    useEffect(() => {
        if (inputRef?.current && (mask || customImput))
            inputRef.current.value = rText
    }, [rText])

    const Error = ({ style }) => (
        <View style={{ paddingTop: 5, ...style }}>
            <Text style={[{ color: 'tomato', fontSize: 12 }, styleError]}>{error}</Text>
        </View>
    )

    return (
        <>
            {type == "checkbox" ?
                <TouchableOpacity style={{ flexDirection: "row", alignItems: "center", ...rest.style }} onPress={() => disabled ? null : setChecked(!checked)} {...rest}>
                    <View style={{ width: 20, height: 20, borderWidth: 1, borderColor: color, borderRadius: 3, backgroundColor: checked ? color : "transparent", justifyContent: "center", alignItems: "center" }}>
                        {checked && <Icon name={"check"} size={14} color={secondaryColor} />}
                    </View>
                    {label && <>
                        {typeof label == "string" ? <Text style={{ marginLeft: 5, fontSize: 14, fontWeight: "300", color: color }}>{label}</Text> : label}
                    </>}
                </TouchableOpacity>
                : type == "select" ?
                    <View>
                        <Picker
                            onValueChange={(value) => setSelected(value)}
                            value={selected}
                            items={rest.items}
                            doneText="Escolher"
                            disabled={disabled}
                            {...style}
                            {...rest}
                        />
                        <Error />
                    </View>
                    :
                    <>
                        {label && <Text>{label}</Text>}
                        <View style={{ ...styles.inputView, ...{ borderBottomColor: color, borderBottomWidth: customImput ? 0 : 1 } }}>
                            {(mask && !customImput) ?
                                <TextInputMask
                                    ref={inputRef}
                                    type={mask}
                                    name={name}
                                    includeRawValueInChangeText
                                    value={text}
                                    onChangeText={handleChangeText}
                                    customTextInput={Input}
                                    customTextInputProps={{
                                        ref: inputRef,
                                        rawText,
                                        setRawText,
                                        onInitialData: setText,
                                        customImput: true,
                                        placeholderTextColor: color,
                                        color: color
                                    }}
                                    style={{ ...styles.input, ...style }}
                                    disabled={disabled}
                                    {...rest}
                                />
                                : type == "money" ?
                                    <CurrencyInput
                                        value={text}
                                        name={name}
                                        onChangeValue={(val) => {
                                            setText(val)
                                            inputRef.current.value = val
                                            if (rest.onChange) rest.onChange(val)
                                        }}
                                        prefix="R$ "
                                        delimiter="."
                                        separator=","
                                        precision={2}
                                        ref={inputRef}
                                        defaultValue={defaultValue}
                                        disabled={disabled}
                                        style={{ ...styles.input, color, ...style }}
                                        placeholderTextColor={color}
                                        {...rest}
                                    />
                                    :
                                    <TextInput
                                        ref={inputRef}
                                        name={name}
                                        type={type}
                                        style={{ ...styles.input, ...style }}
                                        placeholderTextColor={color}
                                        onChangeText={value => {
                                            inputRef.current.value = value
                                        }}
                                        color={color}
                                        defaultValue={defaultValue}
                                        disabled={disabled}
                                        {...rest}
                                    />
                            }
                            {icon && <Icon style={styles.icons} name={icon} size={20} color={color} />}
                        </View>
                    </>
            }

            {(error && !customImput && type != "select") && <Error />}
        </>
    )
}

export default memo(Input)