import React, { memo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

import styles from './styles';

const Button = ({ title, backgroundColor = "#ffffff", children, styleText, style, disabled, ...rest }) => {
    return (
        <View style={{ ...styles.buttonView, ...style, ...{ opacity: disabled ? 0.8 : 1 } }}>
            <TouchableOpacity
                {...rest}
                onPress={(f) => rest.onPress && !disabled ? rest.onPress(f) : null}
                style={
                    {
                        backgroundColor,
                        ...styles.button,
                        ...rest.style
                    }}>
                {children}
                {typeof title == "string" ? <Text style={{ ...styles.textButtons, ...styleText }}>{title}</Text> : title}
            </TouchableOpacity>
        </View>
    )
}

export default memo(Button)