import { StyleSheet } from "react-native";

export default StyleSheet.create({
    input: {
        fontSize: 14,
        lineHeight: 20,
        fontWeight: '200',
        borderRadius: 4,
        marginTop: 24,
        paddingRight: 10,
        height: 40,
        flex: 1,
    },
    inputView: {
        borderBottomWidth: 1,
        flexDirection: "row",
        flex: 1
    },
    checkbox: {
        width: 50,
        height: 50,
    },
    icons: {
        marginTop: 35,
    }
})