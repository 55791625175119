import { StyleSheet } from "react-native";

export default StyleSheet.create({
    titleData:{
        fontSize: 36,
        lineHeight: 43,
        fontWeight: "700",
        color: '#fff',
        marginTop: 17,
        marginLeft: 20,
    },
    titleInform:{
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "700",
        color: '#fff',
        marginLeft: 20,
    },
    textInform:{
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "200",
        color: '#fff',
        marginLeft: 20,
        marginRight: 8,
    },
    titleTicketType:{
        fontSize: 18,
        lineHeight: 21,
        fontWeight: 'bold',
        color: '#000',
        marginTop: 14,
        marginLeft: 20,
    },
    viewRow:{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    buttonForms:{
        width: 161,
        margin: 15,
        marginTop: 25,
    }
})