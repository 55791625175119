import React, { useContext } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';

import { AuthContext } from '../../contexts/auth.context';

export default function Notifications({ navigation, color = '#fff' }) {
  const { pessoa } = useContext(AuthContext);

  return (
    <>
      <View>
        <TouchableOpacity onPress={() => navigation.navigate('Notifications')}>
          {pessoa?.notificacoes_nao_lidas > 0 && (
            <View
              style={{
                position: 'absolute',
                zIndex: 1,
                backgroundColor: 'tomato',
                borderRadius: 100,
                width: 18,
                height: 18,
                left: -7,
                top: -7,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={{ fontSize: 13, color: '#FFF', fontWeight: '600' }}>
                {pessoa?.notificacoes_nao_lidas}
              </Text>
            </View>
          )}
          <Icon name="bell" color={color} size={22} />
        </TouchableOpacity>
      </View>
    </>
  );
}
