import React, { useContext, useEffect } from "react";
import {
  SafeAreaView,
  ScrollView,
  BackHandler,
  Text,
  Alert,
} from "react-native";
import styles from "./styles";

import { CompraContext } from "../../contexts/compra.context";
import TSEtapas from "./TSEtapas";
import TSSetores from "./TSSetores";
import TSTitulares from "./TSTitulares";
import TSConfirmacao from "./TSConfirmacao";
import { AppContext } from "../../contexts/app.context";
import { EventosContext } from "../../contexts/eventos.context";

export default function TicketSales() {
  const { step, carrinho, setCarrinho } = useContext(CompraContext);
  const { setModalBottom } = useContext(AppContext);
  const { eventoAberto } = useContext(EventosContext);

  const evento = {
    ...eventoAberto?.evento,
    vendas_dinheiro: eventoAberto?.vendas_dinheiro,
  };

  useEffect(() => {
    // console.log("evento", evento);
    // console.log("carrinho", carrinho);

    if (carrinho.length > 0) {
      if (carrinho[0]?.evento_id != evento?._id) {
        // setCarrinho(() => [])
      }
    }
  }, []);

  return (
    <SafeAreaView>
      <ScrollView>
        {step == "escolha" && (
          <>
            <Text style={{ ...styles.title, textTransform: "uppercase" }}>
              {evento?.nome}
            </Text>

            <TSEtapas etapas={evento?.etapas} />
            <TSSetores back={evento?.etapas.length > 1} />
          </>
        )}

        {step == "titulares" && <TSTitulares />}

        {step == "confirmacao" && <TSConfirmacao evento={evento} />}
      </ScrollView>
    </SafeAreaView>
  );
}
