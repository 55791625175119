import React, { useEffect, useState, useContext } from "react"
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';

import Card, { CardHeader, CardBody, CardFooter } from '../../../components/Card';

import Icon from 'react-native-vector-icons/FontAwesome';
import { putNotification } from "../../../services/notifications.service";
import { formatDataHora } from "../../../helpers/format";
import { AuthContext } from "../../../contexts/auth.context";

export default function NotificationMessage({ notificacao, setNotificacoes }) {
    const { setPessoa } = useContext(AuthContext)

    const [opened, setOpened] = useState(false)

    useEffect(() => {
        if (opened && !notificacao.lido)
            marcarComoLido()
    }, [opened])

    const marcarComoLido = async () => {
        try {
            await putNotification(notificacao._id, { lido: 1 })

            setNotificacoes(notificacoes => {
                for (let notf of notificacoes) {
                    if (notf._id == notificacao._id) {
                        notf.lido = 1
                        break
                    }
                }
                return [...notificacoes]
            })

            setPessoa(pessoa => {
                if (!pessoa) return pessoa
                pessoa.notificacoes_nao_lidas = pessoa.notificacoes_nao_lidas > 1 ? pessoa.notificacoes_nao_lidas - 1 : 0
                return { ...pessoa }
            })
        } catch (error) { }
    }

    return (
        <View style={{ marginBottom: 20 }}>
            <Card>
                <TouchableOpacity onPress={() => setOpened(!opened)}>
                    <CardHeader style={{ paddingHorizontal: 10, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <View>
                            <Text style={{ fontSize: 14, fontWeight: "500" }}>{notificacao.title}</Text>
                            <Text style={{ fontSize: 10, marginTop: 5 }}>{formatDataHora(notificacao.datahora_cadastro, "DD/MM/YYYY - HH:mm")}</Text>
                        </View>

                        <Icon name={(opened || notificacao.lido) ? "envelope-open" : "envelope"} color={(opened || notificacao.lido) ? "#c0c0c0" : "#000000"} size={18} />
                    </CardHeader>
                    {opened && (
                        <CardBody style={{ borderTopWidth: 1, borderColor: "#eeeeee", padding: 10 }}>
                            <Text style={{ fontSize: 12 }}>{notificacao.body}</Text>
                        </CardBody>
                    )}
                </TouchableOpacity>
            </Card>
        </View>
    )
}