import { PixelRatio, StyleSheet } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled.SafeAreaView`
  flex: 1;
  background-color: #f6f6f7;
`;

export const Spacing = styled.View`
  width: 90%;
  margin: 10% auto;
`;

const imgSize = PixelRatio.getPixelSizeForLayoutSize(15);

export const UserImg = styled.Image`
  width: ${imgSize}px;
  height: ${imgSize}px;
`;

export const Header = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-color: #d0d0d0;
  border-bottom-width: 1px;
  padding: 10px;
  background: #00ad89;
`;

export const Box = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Subtitle = styled.Text`
  font-size: 18px;
  font-weight: 600;
  color: white;
`;

export const AvatarBox = styled.TouchableOpacity`
  width: ${imgSize + 16}px;
  height: ${imgSize + 16}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(imgSize + 16) / 2}px;
  background: rgba(2, 200, 137, 1);
  padding: 16px;
  margin-left: 16px;
`;

export const Title = styled.Text`
  font-size: 36px;
  color: #000;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  margin: 36px 0;
`;

export const ContainerCardEvento = styled.TouchableOpacity`
  background-color: #fff;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
  /*position: 'relative'; */
`;

export const BoxDataEvento = styled.View`
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
`;

export const TitleEvento = styled.Text`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-bottom: 8px;
`;

export const ContentCardEvento = styled.View`
  padding: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default StyleSheet.create({
  safe: {
    flex: 1,
  },
  centerView: {
    alignSelf: 'center',
    alignItems: 'center',
    width: '80%',
    backgroundColor: '#FFF',
  },
  textEvent: {
    color: '#00AD89',
    fontSize: 24,
    marginLeft: 10,
    fontWeight: 'bold',
  },
  scroll: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
  },

  tabBar: {
    tabBarActiveTintColor: '#fff',
    tabBarInactiveTintColor: '#3e3e3e',
    tabBarLabelStyle: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    tabBarItemStyle: {
      borderRadius: 10,
    },
    tabBarIndicatorStyle: {
      height: null,
      top: '10%',
      bottom: '10%',
      width: '45%',
      left: '2.5%',
      borderRadius: 10,
      backgroundColor: '#00AD89',
    },
    tabBarStyle: {
      borderRadius: 10,
      backgroundColor: 'white',
      elevation: 5,
      shadowOpacity: 0.1,
      shadowRadius: 4,
    },
  },
});
