import React, { useState, useContext, useEffect, useRef } from "react";
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  ScrollView,
  Dimensions,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

import Constants from "expo-constants";

import { Form } from "@unform/mobile";

import Page from "../../components/Page";

import Input from "../../components/Input";
import Button from "../../components/Button";
import { AuthContext } from "../../contexts/auth.context";

import styles from "./styles";

import * as Yup from "yup";

import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import * as Device from "expo-device";

export default function Login({ navigation }) {
  const formRef = useRef();

  const { pessoa, login } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const [erro, setErro] = useState(null);

  const [dataSaved, setDataSaved] = useState(null);

  useEffect(() => {
    getDadosSalvos();
  }, []);

  const handleSubmit = async (data) => {
    try {
      //console.log("handleSubmit", data)

      setLoading(() => true);
      setErro(() => null);

      ["login"].map((key) => {
        if (typeof data[key] == "string") data[key] = data[key].trim();
      });

      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        login: Yup.string().required("Campo obrigatório"),
        senha: Yup.string().required("Campo obrigatório"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const resultado = await login(data.login, data.senha);
      //console.log(resultado)

      if (data.salvar) {
        await AsyncStorage.setItem(
          "login-data",
          JSON.stringify({ login: data.login, senha: data.senha })
        );
      }

      setLoading(() => false);
    } catch (error) {
      console.error(error);
      //console.log("kkk")
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        setErro(() => error?.message || error || "Ocorreu um erro");
      }

      setLoading(() => false);
    }
  };

  const getDadosSalvos = async () => {
    try {
      let dados = await AsyncStorage.getItem("login-data");
      //console.log("getDadosSalvos", dados)

      if (dados) {
        setDataSaved(JSON.parse(dados));
      }
    } catch (error) {
      console.error("ERRO getDadosSalvos", error);
    }
  };

  // return <Button icon="whatsapp" title="Enviar Link" backgroundColor="#53D769" />

  return (
    <Page style={{ backgroundColor: "#02AB89" }}>
      <ScrollView>
        <KeyboardAwareScrollView style={{ paddingBottom: 60 }}>
          <View
            style={Device.deviceName == null ? { alignItems: "center" } : {}}
          >
            <View
              style={
                Device.deviceName == null
                  ? { width: 500, maxWidth: Dimensions.get("window").width }
                  : { maxWidth: Dimensions.get("window").width }
              }
            >
              <View style={styles.centerView}>
                <Image
                  style={styles.logo}
                  source={require("../../../assets/logoipassneg.png")}
                />
              </View>

              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input
                  name="login"
                  icon="user"
                  placeholder="digite aqui seu e-mail, cpf ou celular"
                  autoCapitalize="none"
                  defaultValue={dataSaved?.login}
                />
                <Input
                  name="senha"
                  secureTextEntry={true}
                  icon="lock"
                  placeholder="senha"
                  autoCapitalize="none"
                  defaultValue={dataSaved?.senha}
                />

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  <Input
                    type="checkbox"
                    name="salvar"
                    label="Lembrar de mim"
                    defaultValue={dataSaved ? true : false}
                  />

                  <TouchableOpacity
                    onPress={() => navigation.navigate("RecoveryPassword")}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "300",
                        color: "#ffffff",
                      }}
                    >
                      Esqueci minha senha
                    </Text>
                  </TouchableOpacity>
                </View>
              </Form>

              {erro && (
                <View
                  style={{
                    marginTop: 20,
                    padding: 10,
                    borderRadius: 10,
                    borderWidth: 2,
                    borderColor: "#FF555A",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      color: "#ffffff",
                      textAlign: "center",
                      color: "#FF555A",
                    }}
                  >
                    {erro}
                  </Text>
                </View>
              )}

              <Button
                onPress={() => formRef.current.submitForm()}
                title={loading ? "Carregando..." : "Entrar"}
                backgroundColor="#53D769"
                style={styles.buttonSignIn}
                disabled={loading}
              />

              <Text
                style={{
                  alignSelf: "center",
                  color: "#FFF",
                  fontSize: 12,
                  fontWeight: "600",
                  marginTop: 20,
                }}
              >
                Aplicativo para uso exclusivo de comissários e PDVs.
              </Text>
              <Text
                style={{
                  alignSelf: "center",
                  color: "#FFF",
                  fontSize: 10,
                  fontWeight: "600",
                  marginTop: 10,
                }}
              >
                {Constants.manifest?.version}
              </Text>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ScrollView>
    </Page>
  );
}
