import React from "react"
import { View, Text, Animated, Easing } from "react-native"
import Icon from 'react-native-vector-icons/FontAwesome';

export default function Loading({ text = "Carregando", description = null, size = 60, color = "#02AB89", style }) {
    let spinValue = new Animated.Value(0)

    const spin = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
    })

    Animated.loop(
        Animated.timing(
            spinValue,
            {
                toValue: 1,
                duration: 8000,
                easing: Easing.linear, // Easing is an additional import from react-native
                useNativeDriver: true  // To make use of native driver for performance
            }
        )
    ).start()

    return (
        <View style={{ width: "100%", ...style }}>
            <Animated.View style={{ width: size, alignSelf: "center", transform: [{ rotate: spin }] }} >
                <Icon name="ticket" color={color} size={size} />
            </Animated.View>

            {text && <Text style={{ fontSize: size / 2.728, color: color, fontWeight: "bold", marginTop: 10, alignSelf: "center", textAlign: "center" }}>{text}</Text>}

            {description && <Text style={{ fontSize: size / 4.286, color: color, fontWeight: "bold", marginTop: 10, alignSelf: "center", textAlign: "center" }}>{description}</Text>}
        </View>
    )
}