import React, { useState } from "react"
import { View, Text, TouchableOpacity } from "react-native"

import Card, { CardBody, CardHeader } from "../../../components/Card"
import Table, { THead, TBody, Tr, Td } from '../../../components/Table';

import { formatDataHora, formatReal } from "../../../helpers/format"

import styles from "./styles"

export default function ResgateCard({ resgate }) {
    const [opened, setOpened] = useState(false)

    return (
        <Card style={{ marginTop: 20 }}>
            <TouchableOpacity onPress={() => setOpened(!opened)}>
                <CardHeader style={{ padding: 20, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Text style={{ fontSize: 16, fontWeight: "500" }}>#{resgate.codigo}</Text>
                    <View>
                        <Text style={{ fontWeight: "300", textAlign: "right" }}>{formatDataHora(resgate.datahora_cadastro, "DD/MM/YYYY")}</Text>
                        <Text style={{ fontWeight: "300", textAlign: "right" }}>{formatDataHora(resgate.datahora_cadastro, "HH:mm")}</Text>
                        <Text style={{ fontWeight: "300", textAlign: "right" }}>{formatReal(+resgate.valor)}</Text>
                    </View>
                </CardHeader>
            </TouchableOpacity>
            {opened && (
                <CardBody style={{ borderTopWidth: 0.5, borderColor: "#e0e0e0" }}>
                    <Table>
                        <THead>
                            <Tr style={{ paddingBottom: 5 }}>
                                <Td textStyle={{ ...styles.titleTable }} style={{ flex: 1.5 }}>Setor</Td>
                                <Td textStyle={{ ...styles.titleTable }} style={{ ...styles.separador, flex: 0.8 }}>Lote</Td>
                                <Td textStyle={{ ...styles.titleTable }} style={{ ...styles.separador, flex: 0.8 }}>Qtde</Td>
                                <Td textStyle={{ ...styles.titleTable }} style={{ ...styles.separador }}>TOTAL</Td>
                            </Tr>
                        </THead>
                        <TBody>
                            {resgate?.itens?.ingressos?.map((lote, i) => (
                                <Tr key={i} style={{ borderTopWidth: i == 0 ? 0.5 : 0, borderTopColor: "#ffffff", paddingVertical: 3 }}>
                                    <Td textStyle={{ ...styles.text }} style={{ flex: 1.5 }}>{i == 0 ? lote.setor.nome : ""}</Td>
                                    <Td textStyle={{ ...styles.text }} style={{ flex: 0.8 }}>{`${lote?.lote?.numero || "PV"} ${lote?.lote?.modalidade_string}`}</Td>
                                    <Td textStyle={{ ...styles.text }} style={{ flex: 0.8 }}>{lote.quantidade}</Td>
                                    {/*<Td textStyle={{ ...styles.text }}>{formatReal(0)}</Td>*/}
                                    <Td textStyle={{ ...styles.text }}>{formatReal(lote.valor * lote.quantidade)}</Td>
                                </Tr>
                            ))}
                        </TBody>
                    </Table>
                </CardBody>
            )}
        </Card>
    )
}