import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  buttonView: {
    alignItems: 'center',
  },
  button: {
    borderRadius: 11,
    padding: 8,
    width: '100%',
  },
  textButtons: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: 20,
    //fontFamily: 'Arial',
  },
});
