import React from 'react';
import { View, Text} from 'react-native';
import Button from '../Button';
import styles from './styles'

export default function ModalCardPayment({onPress}){

    return(
        <View style={styles.modal}>
            <Text style={styles.titleCardPayment}>Pagamento Cartão</Text>
            <Text style={styles.textCardPayment}>Você pode pagar agora pelo app ou enviar um link de pagamento</Text>
            <View style={styles.rowView}>
                <Button style={styles.btns} onPress={onPress} title="Pagar pelo App" backgroundColor="#53D769"/>
                <Button style={styles.btns} title="Enviar Link" backgroundColor="#53D769"/>
            </View>
        </View>
    )
}