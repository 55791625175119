import React, { useState, createContext, useEffect } from "react";

import { comprar, reservar } from "../services/compras.service";

//import AsyncStorage from '@react-native-async-storage/async-storage';

export const CompraContext = createContext({});

export const CompraProvider = (props) => {
  const [carrinho, setCarrinho] = useState([]);
  const [valorTotal, setValorTotal] = useState({
    pix: 0,
    "cartao-credito": 0,
    dinheiro: 0,
  });

  const [step, setStep] = useState("escolha");

  const [etapa, setEtapa] = useState(null);
  const [setorSelecionado, setSetorSelecionado] = useState(null);

  const [ingressosIndividuais, setIngressosIndividuais] = useState([]);

  const [titulares, setTitulares] = useState([]);

  const [reserva, setReserva] = useState(null);
  const [tokenReserva, setTokenReserva] = useState(null);

  const [compra, setCompra] = useState(null);

  const [loadingCompra, setLoadingCompra] = useState(false);

  useEffect(() => {
    calcTotal();
  }, [carrinho, titulares]);

  const calcTotal = () => {
    console.log("carrinho111", carrinho);
    let total = {
      pix: { valor: 0, taxa: 0, total: 0 },
      "cartao-credito": { valor: 0, taxa: 0, total: 0 },
      dinheiro: { valor: 0, taxa: 0, total: 0 },
    };

    for (let ingresso of carrinho) {
      for (let meio_pagamento of ["pix", "cartao-credito", "dinheiro"]) {
        console.log("taxasss", ingresso.taxas);
        console.log("=======", meio_pagamento);
        console.log(ingresso.taxas[meio_pagamento]);
        console.log("=======");

        var taxa = 0;
        if (ingresso.taxas) {
          taxa = !ingresso.absorver_taxas
            ? +ingresso.taxas[meio_pagamento]?.valor_taxa
            : 0;
        }

        console.log("taxa", taxa);

        total[meio_pagamento].valor += ingresso.valor * ingresso.quantidade;
        total[meio_pagamento].taxa += taxa * ingresso.quantidade;
        total[meio_pagamento].total =
          total[meio_pagamento].valor + total[meio_pagamento].taxa;
      }
    }

    // Doações
    for (let titular of titulares) {
      for (let meio_pagamento of ["pix", "cartao-credito", "dinheiro"]) {
        total[meio_pagamento].valor += titular.valor_doacao || 0;
        total[meio_pagamento].total =
          total[meio_pagamento].valor + total[meio_pagamento].taxa;
      }
    }

    console.log("totallll", total);

    setValorTotal(() => total);
  };

  const reservarIngressos = async (evento_id) => {
    try {
      //console.log("carrinho", carrinho)
      setLoadingCompra(true);

      let dados = {
        evento_id,
        ingressos: [],
      };

      for (let titular of titulares) {
        //console.log(titular)
        if (
          typeof titular.data_nascimento == "string" &&
          titular?.data_nascimento.indexOf("/") >= 0
        )
          titular.data_nascimento = titular.data_nascimento
            .split("/")
            .reverse()
            .join("-");
      }

      //console.log("TITULARES ======")
      //console.log(titulares)

      carrinho.forEach((ingresso) => {
        dados.ingressos.push({
          lote_id: ingresso._id,
          quantidade: ingresso.quantidade,
          titulares: titulares.filter((t) => t.lote_id == ingresso._id),
        });
      });

      let resultado = await reservar(dados);
      //console.log("RESULTADO RESERVA", resultado)
      let { token, link_pagamento, reserva } = resultado;

      setReserva(() => reserva);
      setTokenReserva(() => token);

      //console.log("TOKEN RESERVA VINDO DA API", tokenReserva)

      //await AsyncStorage.setItem(`token-reserva`, token)
      return { token, link_pagamento };
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoadingCompra(false);
    }
  };

  const efetuarCompra = async (dados, token = null) => {
    try {
      setLoadingCompra(true);

      dados = { ...dados, reserva_token: token || tokenReserva };

      dados.doacoes = [];

      for (let titular of titulares) {
        if (titular?.valor_doacao) {
          dados.doacoes.push({
            lote_id: titular.ingresso_id,
            valor_doacao: titular.valor_doacao,
          });
        }
      }

      console.log("{ efetuarCompra }", dados);

      let compra = await comprar(dados);

      //console.log("COMPRA", compra)

      setCompra(() => compra);

      return compra;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoadingCompra(false);
    }
  };

  const resetCompra = () => {
    console.log("resetCompra");
    setCarrinho([]);
    setValorTotal({
      pix: 0,
      "cartao-credito": 0,
      dinheiro: 0,
    });

    setStep("escolha");

    setEtapa(null);
    setSetorSelecionado(null);

    setIngressosIndividuais([]);

    setTitulares([]);

    setReserva(null);
    setTokenReserva(null);

    setCompra(null);
  };

  return (
    <CompraContext.Provider
      value={{
        carrinho,
        setCarrinho,
        valorTotal,
        setValorTotal,
        etapa,
        setEtapa,
        setorSelecionado,
        setSetorSelecionado,
        step,
        setStep,
        titulares,
        setTitulares,
        ingressosIndividuais,
        setIngressosIndividuais,
        reservarIngressos,
        tokenReserva,
        setTokenReserva,
        efetuarCompra,
        compra,
        setCompra,
        resetCompra,
        loadingCompra,
        setLoadingCompra,
      }}
    >
      {props.children}
    </CompraContext.Provider>
  );
};
