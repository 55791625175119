import { StyleSheet } from "react-native";

export default StyleSheet.create({
    
    titleSales:{
        fontSize: 36,
        lineHeight: 43,
        fontWeight: "700",
        color: '#02AB89',
        marginTop: 17,
        marginLeft: 20,
    },
    titleInformSales:{
        fontSize: 14,
        lineHeight: 20,
        fontWeight: "500",
        color: '#02AB89',
        marginLeft: 20,
        marginBottom: 10,
    },
    viewRow:{
        marginTop: 50,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    viewCenter:{
        alignItems: "center",
    },
    ticketCard:{
        marginLeft: 20,
    },
    input:{
        fontSize: 14,
        lineHeight: 20,
        fontWeight: '200',
        borderRadius: 4,
        marginTop: 24,
        marginRight: 25,
        marginLeft: 25,
        height: 40,
        width: 220,
    },
    inputView:{
        borderBottomWidth: 2,
        borderBottomColor: '#02AB89',
        width:' 80%',
        marginRight: 25,
        marginLeft: 25,
        marginBottom: 50,
        flexDirection: "row",
       
    },
})