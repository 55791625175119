import { StyleSheet } from "react-native";

export default StyleSheet.create({
    mainlyModal: {
        height: 640,
        width: '100%',
        backgroundColor: '#4BC1A9',
        marginTop: 90,
        borderTopRightRadius: 13,
        borderTopLeftRadius: 13,
    }
})