import api from './api.service';

export async function getCompras(evento) {
  try {
    let resultado = await api.get('/compras/reservas', data);

    //console.log("RESULTADO", resultado)

    return resultado.data;
  } catch (error) {
    throw error?.data || error;
  }
}

export async function getCompra(compra_id) {
  try {
    let resultado = await api.get(`/compras/${compra_id}`);

    //console.log("RESULTADO", resultado)

    return resultado.data;
  } catch (error) {
    throw error?.data || error;
  }
}

export async function reservar(data) {
  try {
    let resultado = await api.post('/compras/reservas', data);

    //console.log("RESULTADO", resultado)

    return resultado.data;
  } catch (error) {
    throw error?.data || error;
  }
}

export async function comprar(data) {
  try {
    let resultado = await api.post('/compras/comissario', data);

    return resultado.data;
  } catch (error) {
    throw error?.data || error;
  }
}
