import React, { useContext } from "react"
import { View, Text, TouchableOpacity } from "react-native"
import { formatReal } from "../../helpers/format"

import { AppContext } from "../../contexts/app.context"

import styles from "./styles"
import ResgateComissao from "../../pages/ResgateComissao"

export default function ResumoComissao({ evento, valor_comissao, disponivel = false, style, comissario, refresh }) {
    const { setModalBottom } = useContext(AppContext)

    return (
        <>
            {disponivel ?
                <TouchableOpacity onPress={() => setModalBottom(<ResgateComissao evento={evento} comissario={comissario} refresh={refresh} />)}>
                    <View style={{ ...styles.commisionTrueView, ...style }}>
                        <Text style={styles.titleCommission}>Comissão: {formatReal(valor_comissao)}</Text>
                        <Text style={styles.txtCommission}>CLIQUE AQUI E RESGATE AGORA</Text>
                    </View>
                </TouchableOpacity>
                :
                <View style={{ ...styles.commisionView, ...style }}>
                    <Text style={styles.titleCommission}>Comissão: {formatReal(valor_comissao)}</Text>
                    <Text style={styles.txtCommission}>Resgate disponível em -</Text>
                </View>
            }
        </>
    )
}